import { useState } from "react";
import Calculator from "../../components/calculator";
import PageBuilder from "../../components/pageBuilder";
import Footer from "../../components/Footer";

function Home() {
   let initialState = {};

   if (window.initialState) {
      initialState = window.initialState;
      window.initialState = null;
   }

   const [content, setContent] = useState(initialState.content ? initialState.content : []);

   const getPageContent = async () => {
      const resp = await (await fetch(global.api + "pages?url")).json();
      setContent(resp.json_content);
      document.title = "Удобные займы - " + resp.title;
   };

   if (content.length === 0) {
      getPageContent();
   }

   return (
      <>
         <main>
            <Calculator />
            <PageBuilder content={content} />
         </main>
         <Footer />
      </>
   );
}

export default Home;
