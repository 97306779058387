import IconPage from "../Icons/Page";

import TextOverflow from "../../helpers/text-overflow";
import scss from "./index.module.scss";
import CheckBox from "../Checkbox";

const { list, list__item, document, document__icon, document__text, document__check } = scss;

function DocumentList({ data }) {
   return (
      <ul className={list}>
         {data.map(({ url, name }, key) => (
            <li className={list__item} key={key}>
               <a
                  className={document}
                  href={url}
                  target={"_blank"}
                  rel="noreferrer"
                  title={'Открыть в новой вкладке'}
               >
                  <IconPage className={document__icon} />
                  <p className={document__text}>{TextOverflow(name, 120)}</p>
                  <div className={document__check}>
                     <span>Я согласен(а)</span>
                     <CheckBox value={true} />
                  </div>
               </a>
            </li>
         ))}
      </ul>
   );
}

export default DocumentList;
