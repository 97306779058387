import { useRef } from "react";
import TextOverflow from "../../helpers/text-overflow";
import IconArrowLeft from "../../manage/Components/Icons/IconArrowLeft";
import IconArrowRight from "../../manage/Components/Icons/IconArrowRight";
import IconDelete from "../../manage/Components/Icons/IconDelete";
import Button from "../Button";
import IconPlus from "../Icons/Plus";
import PageEditorFloatingTools, { createFloatingTool } from "../PageEditorFloatingTools";
import classes from "./index.module.scss";

const {
   servicesTwoColumns,
   servicesTwoColumns__column,
   servicesTwoColumns__title,
   list,
   list__item,
   list__icon,
   list__text,
   list__tools
} = classes;

function ServicesTwoColumns({
   data,

   editable = false,
   blockIndex = -1,
   onChange = () => {},
   onChangeGlobal = () => {}
}) {

   const defaultTitle1 = useRef(data.column1.title).current;
   const defaultItems1 = [];
   const defaultTitle2 = useRef(data.column2.title).current;
   const defaultItems2 = [];

   data.column1.items.forEach(item => {
      defaultItems1.push(item + "");
   });
   
   data.column2.items.forEach(item => {
      defaultItems2.push(item + "");
   });

   const addItem = (column = "left") => {
      const tmpData = JSON.parse(JSON.stringify(data));

      const itemTemplate = "Новый пункт";

      if (column === "left") {
         tmpData.column1.items.push(itemTemplate);
         defaultItems1.push(itemTemplate);
      }
      else {
         tmpData.column2.items.push(itemTemplate);
         defaultItems2.push(itemTemplate);
      }

      onChangeGlobal(blockIndex, tmpData);
   }
   
   const moveItem = (column = "left", index, position) => {
      const columnLength = (column === "left" ? data.column1.items : data.column2.items).length;
      const newPosition = index + position;

      if (newPosition >= 0 && newPosition < columnLength) {
         const tmpData = JSON.parse(JSON.stringify(data));

         if (column === "left") {
            tmpData.column1.items.splice(index, 1);
            tmpData.column1.items.splice(newPosition, 0, data.column1.items[index]);
            defaultItems1.splice(index, 1);
            defaultItems1.splice(newPosition, 0, data.column1.items[index] + "");
         }
         else {
            tmpData.column2.items.splice(index, 1);
            tmpData.column2.items.splice(newPosition, 0, data.column2.items[index]);
            defaultItems2.splice(index, 1);
            defaultItems2.splice(newPosition, 0, data.column2.items[index] + "");
         }

         onChangeGlobal(blockIndex, tmpData);
      }
   }

   const removeItem = (column = "left", index) => {
      const columnLength = (column === "left" ? data.column1.items : data.column2.items).length;

      if (index >= 0 && index < columnLength) {
         const tmpData = JSON.parse(JSON.stringify(data));

         if (column === "left") {
            tmpData.column1.items.splice(index, 1);
            defaultItems1.splice(index, 1);
         }
         else {
            tmpData.column2.items.splice(index, 1);
            defaultItems2.splice(index, 1);
         }

         onChangeGlobal(blockIndex, tmpData);
      }
   }

   return (
      <section className={servicesTwoColumns}>
         <div className={servicesTwoColumns__column}>
            <h3
               className={servicesTwoColumns__title}

               contentEditable={editable} suppressContentEditableWarning={true}
               value={data.column1.title}
               onChange={e => onChange(blockIndex, "column1.title", e.target.textContent)}
               onBlur={e => onChange(blockIndex, "column1.title", e.target.textContent)}
            >{defaultTitle1}</h3>
            <ul className={list}>
               {data.column1.items.map((item, key) => (
                  <li className={list__item} key={key}>
                     <IconPlus className={list__icon} />
                     <p
                        className={list__text}

                        contentEditable={editable} suppressContentEditableWarning={true}
                        value={item}
                        onChange={e => onChange(blockIndex, "column1.items."+key, e.target.textContent)}
                        onBlur={e => onChange(blockIndex, "column1.items."+key, e.target.textContent)}
                     >{defaultItems1[key]}</p>

                     {
                        editable ? (
                           <PageEditorFloatingTools
                              className={list__tools}
                              tools={[
                                 createFloatingTool("Переместить назад", <IconArrowLeft />, () => moveItem("left", key, -1)),
                                 createFloatingTool("Переместить вперёд", <IconArrowRight />, () => moveItem("left", key, 1)),
                                 createFloatingTool("Удалить пункт", <IconDelete />, () => removeItem("left", key))
                              ]}
                           />
                        ) : ""
                     }
                  </li>
               ))}
            </ul>

            {
               editable ? (
                  <Button onClick={() => addItem("left")}>
                     Добавить пункт
                  </Button>
               ) : ""
            }
         </div>
         <div className={servicesTwoColumns__column}>
            <h3
               className={servicesTwoColumns__title}

               contentEditable={editable} suppressContentEditableWarning={true}
               value={data.column2.title}
               onChange={e => onChange(blockIndex, "column2.title", e.target.textContent)}
               onBlur={e => onChange(blockIndex, "column2.title", e.target.textContent)}
            >{defaultTitle2}</h3>
            <ul className={list}>
               {data.column2.items.map((item, key) => (
                  <li className={list__item} key={key}>
                     <IconPlus className={list__icon} />
                     <p
                        className={list__text}

                        contentEditable={editable} suppressContentEditableWarning={true}
                        value={item}
                        onChange={e => onChange(blockIndex, "column2.items."+key, e.target.textContent)}
                        onBlur={e => onChange(blockIndex, "column2.items."+key, e.target.textContent)}
                     >{defaultItems2[key]}</p>

                     {
                        editable ? (
                           <PageEditorFloatingTools
                              className={list__tools}
                              tools={[
                                 createFloatingTool("Переместить назад", <IconArrowLeft />, () => moveItem("right", key, -1)),
                                 createFloatingTool("Переместить вперёд", <IconArrowRight />, () => moveItem("right", key, 1)),
                                 createFloatingTool("Удалить пункт", <IconDelete />, () => removeItem("right", key))
                              ]}
                           />
                        ) : ""
                     }
                  </li>
               ))}
            </ul>

            {
               editable ? (
                  <Button onClick={() =>addItem("right")}>
                     Добавить пункт
                  </Button>
               ) : ""
            }
         </div>
      </section>
   );
}

export default ServicesTwoColumns;
