function IconEdit(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
         <path
            fill="#010101"
            d="M15.504 3.448a3.57 3.57 0 015.048 5.048l-.802.803L14.7 4.25l.804-.802h-.001zM13.853 5.1l-9.599 9.599c-.393.393-.665.89-.786 1.433l-1.054 4.74a.6.6 0 00.717.716l4.711-1.048a3.023 3.023 0 001.48-.813l9.58-9.579-5.05-5.048z"
         ></path>
      </svg>
   );
}

export default IconEdit;
