function IconPerson(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
         <path
            fill="#010101"
            d="M12 2a4 4 0 100 8 4 4 0 000-8zm5 10H7a3 3 0 00-3 3c0 2.232.918 4.02 2.424 5.23C7.906 21.42 9.894 22 12 22c2.106 0 4.094-.58 5.576-1.77C19.08 19.02 20 17.232 20 15a3 3 0 00-3-3z"
         ></path>
      </svg>
   );
}

export default IconPerson;
