import React from "react";

export default function IconBags(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="130" height="130" fill="none" viewBox="0 0 130 130" {...props}>
         <g filter="url(#filter0_i_910_4946)">
            <path
               fill="url(#paint0_linear_910_4946)"
               d="M59.987 60.048a4.063 4.063 0 014.062-4.063h52.935a4.062 4.062 0 014.062 4.063v57.383a4.062 4.062 0 01-4.062 4.062H64.049a4.062 4.062 0 01-4.062-4.062V60.048z"
            ></path>
            <path
               fill="url(#paint1_radial_910_4946)"
               d="M59.987 60.048a4.063 4.063 0 014.062-4.063h52.935a4.062 4.062 0 014.062 4.063v57.383a4.062 4.062 0 01-4.062 4.062H64.049a4.062 4.062 0 01-4.062-4.062V60.048z"
            ></path>
         </g>
         <g filter="url(#filter1_f_910_4946)">
            <path
               fill="#F19A39"
               d="M71.029 68.112a2.031 2.031 0 10-4.063 0h4.063zm37.96-2.856a2.031 2.031 0 00-4.063 0h4.063zm-2.031 11.55h-2.032 2.032zm-35.93 0v-8.694h-4.062v8.694h4.063zm33.898-11.554v11.554h4.063v-11.55h-4.063v-.004zM87.978 95.087c-9.141 0-16.95-8.06-16.95-18.281h-4.062c0 12.216 9.385 22.344 21.012 22.344v-4.063zm0 4.063c11.626 0 21.011-10.128 21.011-22.344h-4.063c0 10.221-7.808 18.281-16.948 18.281v4.063z"
            ></path>
         </g>
         <g filter="url(#filter2_i_910_4946)">
            <path
               fill="#E45A3B"
               d="M73.19 65.569a2.031 2.031 0 00-4.063 0h4.063zm37.956 0a2.031 2.031 0 00-4.063 0h4.063zm-2.031 8.698h-2.032 2.032zm-35.925 0v-8.694h-4.063v8.694h4.063zm33.893-8.694v8.694h4.063v-8.694h-4.063zM90.135 92.548c-9.141 0-16.945-8.06-16.945-18.281h-4.063c0 12.216 9.385 22.343 21.012 22.343v-4.062h-.004zm0 4.062c11.631 0 21.011-10.127 21.011-22.343h-4.063c0 10.22-7.804 18.28-16.948 18.28v4.063z"
            ></path>
         </g>
         <g filter="url(#filter3_f_910_4946)">
            <path
               fill="url(#paint2_linear_910_4946)"
               d="M72.361 65.569a.507.507 0 00-1.016 0h1.016zm37.96 0a.505.505 0 00-.508-.508.508.508 0 00-.508.508h1.016zm-.508 8.698h.508-.508zm-37.452 0v-8.694h-1.016v8.694h1.016zm36.944-8.694v8.694h1.016v-8.694h-1.016zM90.833 94.07c-10.075 0-18.472-8.836-18.472-19.804h-1.016c0 11.468 8.792 20.82 19.488 20.82v-1.016zm0 1.016c10.697 0 19.488-9.352 19.488-20.82h-1.016c0 10.968-8.397 19.804-18.472 19.804v1.016z"
            ></path>
            <path
               fill="url(#paint3_linear_910_4946)"
               d="M72.361 65.569a.507.507 0 00-1.016 0h1.016zm37.96 0a.505.505 0 00-.508-.508.508.508 0 00-.508.508h1.016zm-.508 8.698h.508-.508zm-37.452 0v-8.694h-1.016v8.694h1.016zm36.944-8.694v8.694h1.016v-8.694h-1.016zM90.833 94.07c-10.075 0-18.472-8.836-18.472-19.804h-1.016c0 11.468 8.792 20.82 19.488 20.82v-1.016zm0 1.016c10.697 0 19.488-9.352 19.488-20.82h-1.016c0 10.968-8.397 19.804-18.472 19.804v1.016z"
            ></path>
         </g>
         <path
            fill="url(#paint4_linear_910_4946)"
            d="M76.745 31.233H12.252a4.063 4.063 0 00-4.062 4.062v73.507a4.063 4.063 0 004.062 4.063h64.493a4.063 4.063 0 004.062-4.063V35.295a4.063 4.063 0 00-4.062-4.062z"
         ></path>
         <path
            fill="url(#paint5_linear_910_4946)"
            d="M76.745 31.233H12.252a4.063 4.063 0 00-4.062 4.062v73.507a4.063 4.063 0 004.062 4.063h64.493a4.063 4.063 0 004.062-4.063V35.295a4.063 4.063 0 00-4.062-4.062z"
         ></path>
         <path
            fill="url(#paint6_linear_910_4946)"
            d="M76.745 31.233H12.252a4.063 4.063 0 00-4.062 4.062v73.507a4.063 4.063 0 004.062 4.063h64.493a4.063 4.063 0 004.062-4.063V35.295a4.063 4.063 0 00-4.062-4.062z"
         ></path>
         <path
            fill="url(#paint7_linear_910_4946)"
            d="M76.745 31.233H12.252a4.063 4.063 0 00-4.062 4.062v73.507a4.063 4.063 0 004.062 4.063h64.493a4.063 4.063 0 004.062-4.063V35.295a4.063 4.063 0 00-4.062-4.062z"
         ></path>
         <g filter="url(#filter4_f_910_4946)">
            <path
               stroke="url(#paint8_linear_910_4946)"
               strokeLinecap="round"
               strokeWidth="3.047"
               d="M76.87 35.165v71.094"
            ></path>
         </g>
         <path fill="#3A92EA" d="M8.19 65.764v-9.019h72.613v9.019H8.19z"></path>
         <path fill="url(#paint9_linear_910_4946)" d="M8.19 65.764v-9.019h72.613v9.019H8.19z"></path>
         <path fill="url(#paint10_linear_910_4946)" d="M8.19 65.764v-9.019h72.613v9.019H8.19z"></path>
         <path fill="url(#paint11_linear_910_4946)" d="M8.19 65.764v-9.019h72.613v9.019H8.19z"></path>
         <path fill="#3A91ED" d="M8.19 84.679v-9.015h72.613v9.015H8.19z"></path>
         <path fill="url(#paint12_linear_910_4946)" d="M8.19 84.679v-9.015h72.613v9.015H8.19z"></path>
         <path fill="url(#paint13_linear_910_4946)" d="M8.19 84.679v-9.015h72.613v9.015H8.19z"></path>
         <path fill="url(#paint14_linear_910_4946)" d="M8.19 84.679v-9.015h72.613v9.015H8.19z"></path>
         <path fill="#3A8FF0" d="M8.19 103.594v-9.015h72.613v9.015H8.19z"></path>
         <path fill="url(#paint15_linear_910_4946)" d="M8.19 103.594v-9.015h72.613v9.015H8.19z"></path>
         <path fill="url(#paint16_linear_910_4946)" d="M8.19 103.594v-9.015h72.613v9.015H8.19z"></path>
         <path fill="url(#paint17_linear_910_4946)" d="M8.19 103.594v-9.015h72.613v9.015H8.19z"></path>
         <g filter="url(#filter5_f_910_4946)">
            <path
               stroke="url(#paint18_linear_910_4946)"
               strokeLinecap="round"
               strokeWidth="3.047"
               d="M117.853 59.706l-.004 58.033"
            ></path>
         </g>
         <g filter="url(#filter6_f_910_4946)">
            <path stroke="#4EB7F5" strokeLinecap="round" strokeWidth="3.047" d="M62.904 33.516V46.21"></path>
         </g>
         <g filter="url(#filter7_f_910_4946)">
            <path stroke="#4EB7F5" strokeLinecap="round" strokeWidth="3.047" d="M22.027 33.516V46.21"></path>
         </g>
         <g filter="url(#filter8_i_910_4946)">
            <path
               fill="url(#paint19_radial_910_4946)"
               d="M62.969 44.054a2.032 2.032 0 104.062 0H62.97zm-41.007 0a2.031 2.031 0 104.062 0h-4.062zm41.007-14.479V44.05h4.062V29.575H62.97zM26.024 44.05V29.575h-4.062V44.05h4.062zm18.473-32.756c10.123 0 18.472 8.21 18.472 18.281h4.062c0-12.362-10.217-22.344-22.534-22.344v4.063zm0-4.063c-12.314 0-22.535 9.982-22.535 22.344h4.062c0-10.07 8.353-18.281 18.473-18.281V7.23z"
            ></path>
         </g>
         <g filter="url(#filter9_f_910_4946)">
            <path
               fill="url(#paint20_linear_910_4946)"
               d="M65.19 44.054a.508.508 0 001.017 0H65.19zm-41.705 0a.508.508 0 001.016 0h-1.016zm41.706-14.479V44.05h1.016V29.575H65.19zM24.497 44.054V29.575H23.48V44.05h1.016v.004zm20.345-34.28c11.143 0 20.345 8.881 20.345 19.805h1.015c0-11.513-9.684-20.82-21.36-20.82v1.015zm0-1.015c-11.676 0-21.36 9.307-21.36 20.82h1.015c0-10.924 9.201-19.805 20.345-19.805V8.76z"
            ></path>
            <path
               fill="url(#paint21_linear_910_4946)"
               d="M65.19 44.054a.508.508 0 001.017 0H65.19zm-41.705 0a.508.508 0 001.016 0h-1.016zm41.706-14.479V44.05h1.016V29.575H65.19zM24.497 44.054V29.575H23.48V44.05h1.016v.004zm20.345-34.28c11.143 0 20.345 8.881 20.345 19.805h1.015c0-11.513-9.684-20.82-21.36-20.82v1.015zm0-1.015c-11.676 0-21.36 9.307-21.36 20.82h1.015c0-10.924 9.201-19.805 20.345-19.805V8.76z"
            ></path>
         </g>
         <defs>
            <filter
               id="filter0_i_910_4946"
               width="63.091"
               height="67.539"
               x="59.987"
               y="53.954"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="2.031" dy="-2.031"></feOffset>
               <feGaussianBlur stdDeviation="2.031"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.905882 0 0 0 0 0.556863 0 0 0 0 0.329412 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_910_4946"></feBlend>
            </filter>
            <filter
               id="filter1_f_910_4946"
               width="50.148"
               height="44.05"
               x="62.904"
               y="59.163"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_4946" stdDeviation="2.031"></feGaussianBlur>
            </filter>
            <filter
               id="filter2_i_910_4946"
               width="42.831"
               height="33.073"
               x="69.127"
               y="63.538"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="0.813"></feOffset>
               <feGaussianBlur stdDeviation="0.813"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.862745 0 0 0 0 0.321569 0 0 0 0 0.109804 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_910_4946"></feBlend>
            </filter>
            <filter
               id="filter3_f_910_4946"
               width="40.194"
               height="31.245"
               x="70.736"
               y="64.452"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_4946" stdDeviation="0.305"></feGaussianBlur>
            </filter>
            <filter
               id="filter4_f_910_4946"
               width="9.141"
               height="80.234"
               x="72.3"
               y="30.595"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_4946" stdDeviation="1.523"></feGaussianBlur>
            </filter>
            <filter
               id="filter5_f_910_4946"
               width="7.113"
               height="65.142"
               x="114.294"
               y="56.152"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_4946" stdDeviation="1.016"></feGaussianBlur>
            </filter>
            <filter
               id="filter6_f_910_4946"
               width="11.172"
               height="23.867"
               x="57.318"
               y="27.93"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_4946" stdDeviation="2.031"></feGaussianBlur>
            </filter>
            <filter
               id="filter7_f_910_4946"
               width="11.172"
               height="23.867"
               x="16.441"
               y="27.93"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_4946" stdDeviation="2.031"></feGaussianBlur>
            </filter>
            <filter
               id="filter8_i_910_4946"
               width="45.069"
               height="39.26"
               x="21.962"
               y="6.825"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dy="-0.406"></feOffset>
               <feGaussianBlur stdDeviation="0.813"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.164706 0 0 0 0 0.560784 0 0 0 0 0.886275 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_910_4946"></feBlend>
            </filter>
            <filter
               id="filter9_f_910_4946"
               width="44.757"
               height="37.834"
               x="22.466"
               y="7.743"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_4946" stdDeviation="0.508"></feGaussianBlur>
            </filter>
            <linearGradient
               id="paint0_linear_910_4946"
               x1="75.347"
               x2="95.912"
               y1="69.319"
               y2="104.865"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FEC243"></stop>
               <stop offset="1" stopColor="#FDB046"></stop>
            </linearGradient>
            <radialGradient
               id="paint1_radial_910_4946"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(8.74575 11.22632 -15.2703 11.89617 63.642 110.27)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.225" stopColor="#D38D3C"></stop>
               <stop offset="1" stopColor="#D38D3C" stopOpacity="0"></stop>
            </radialGradient>
            <linearGradient
               id="paint2_linear_910_4946"
               x1="105.974"
               x2="105.974"
               y1="64.11"
               y2="89.566"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#F38362"></stop>
               <stop offset="1" stopColor="#F38362" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint3_linear_910_4946"
               x1="76.745"
               x2="102.387"
               y1="89.566"
               y2="89.566"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#F38362"></stop>
               <stop offset="1" stopColor="#F38362" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint4_linear_910_4946"
               x1="44.496"
               x2="44.496"
               y1="31.233"
               y2="112.861"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#59C1FA"></stop>
               <stop offset="1" stopColor="#49A8FF"></stop>
            </linearGradient>
            <linearGradient
               id="paint5_linear_910_4946"
               x1="8.19"
               x2="15.299"
               y1="103.468"
               y2="103.468"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#419CE0"></stop>
               <stop offset="1" stopColor="#449FEE" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint6_linear_910_4946"
               x1="43.099"
               x2="43.099"
               y1="114.766"
               y2="96.993"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#4676F8"></stop>
               <stop offset="1" stopColor="#439AFF" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint7_linear_910_4946"
               x1="44.496"
               x2="44.496"
               y1="31.233"
               y2="39.992"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.159" stopColor="#61C9E8"></stop>
               <stop offset="1" stopColor="#61C9E8" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient id="paint8_linear_910_4946" x1="0" x2="0" y1="0" y2="0" gradientUnits="userSpaceOnUse">
               <stop stopColor="#80E8FF"></stop>
               <stop offset="0.739" stopColor="#5BB8FF"></stop>
               <stop offset="1" stopColor="#54AFFF"></stop>
            </linearGradient>
            <linearGradient
               id="paint9_linear_910_4946"
               x1="8.19"
               x2="15.677"
               y1="62.969"
               y2="62.969"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.196" stopColor="#2A81CD"></stop>
               <stop offset="1" stopColor="#2A81CD" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint10_linear_910_4946"
               x1="80.807"
               x2="73.698"
               y1="62.969"
               y2="62.969"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#51A9F3"></stop>
               <stop offset="1" stopColor="#51A9F3" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint11_linear_910_4946"
               x1="80.807"
               x2="77.504"
               y1="65.764"
               y2="65.764"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#3C97EE"></stop>
               <stop offset="1" stopColor="#3C97EE" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint12_linear_910_4946"
               x1="8.19"
               x2="15.677"
               y1="81.884"
               y2="81.884"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.196" stopColor="#2A81CD"></stop>
               <stop offset="1" stopColor="#2A81CD" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint13_linear_910_4946"
               x1="80.807"
               x2="73.698"
               y1="81.884"
               y2="81.884"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#51A9F3"></stop>
               <stop offset="1" stopColor="#51A9F3" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint14_linear_910_4946"
               x1="80.807"
               x2="77.504"
               y1="84.679"
               y2="84.679"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#3C97EE"></stop>
               <stop offset="1" stopColor="#3C97EE" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint15_linear_910_4946"
               x1="8.19"
               x2="15.677"
               y1="100.803"
               y2="100.803"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.196" stopColor="#2A81CD"></stop>
               <stop offset="1" stopColor="#2A81CD" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint16_linear_910_4946"
               x1="80.807"
               x2="73.698"
               y1="100.803"
               y2="100.803"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#51A9F3"></stop>
               <stop offset="1" stopColor="#51A9F3" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint17_linear_910_4946"
               x1="80.807"
               x2="77.504"
               y1="103.594"
               y2="103.594"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#3C97EE"></stop>
               <stop offset="1" stopColor="#3C97EE" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint18_linear_910_4946"
               x1="2654060"
               x2="2654060"
               y1="58.593"
               y2="111.434"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFD262"></stop>
               <stop offset="1" stopColor="#FFB84A"></stop>
            </linearGradient>
            <radialGradient
               id="paint19_radial_910_4946"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(72.975 9.596 28.949) scale(34.255 40.3809)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#2C94E3"></stop>
               <stop offset="1" stopColor="#2C9CEB"></stop>
            </radialGradient>
            <linearGradient
               id="paint20_linear_910_4946"
               x1="50.529"
               x2="50.529"
               y1="46.085"
               y2="24.757"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#53A9F2"></stop>
               <stop offset="1" stopColor="#53A9F2" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint21_linear_910_4946"
               x1="64.175"
               x2="56.176"
               y1="17.014"
               y2="26.662"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#61B2F2"></stop>
               <stop offset="1" stopColor="#61B2F2" stopOpacity="0"></stop>
            </linearGradient>
         </defs>
      </svg>
   );
}
