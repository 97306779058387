import React from "react";

export default function IconCreditCard(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="130" height="130" fill="none" viewBox="0 0 130 130" {...props}>
         <g filter="url(#filter0_iii_917_4287)">
            <path
               fill="url(#paint0_linear_917_4287)"
               d="M112.608 29H17.392a9.14 9.14 0 00-9.141 9.14v54.463a9.14 9.14 0 009.14 9.14h95.217a9.14 9.14 0 009.141-9.14V38.14A9.14 9.14 0 00112.608 29z"
            ></path>
         </g>
         <path fill="url(#paint1_linear_917_4287)" d="M8.25 53.375V40.68h113.503v12.695H8.247h.004z"></path>
         <path fill="url(#paint2_linear_917_4287)" d="M8.25 53.375V40.68h113.503v12.695H8.247h.004z"></path>
         <path fill="url(#paint3_linear_917_4287)" d="M8.25 53.375V40.68h113.503v12.695H8.247h.004z"></path>
         <g filter="url(#filter1_iii_917_4287)">
            <path
               fill="#E2DDDE"
               d="M23.233 77.75a3.047 3.047 0 01-3.047-3.047V64.547a3.047 3.047 0 013.047-3.047h70.204a3.047 3.047 0 013.047 3.047v10.156a3.047 3.047 0 01-3.046 3.047H23.233z"
            ></path>
         </g>
         <g filter="url(#filter2_iii_917_4287)">
            <path
               fill="url(#paint4_linear_917_4287)"
               d="M70.59 67.72H26.662a2.031 2.031 0 000 4.062H70.59a2.031 2.031 0 100-4.062z"
            ></path>
         </g>
         <g filter="url(#filter3_iii_917_4287)">
            <path
               fill="url(#paint5_linear_917_4287)"
               d="M110.321 79.147h-8.251a3.047 3.047 0 00-3.047 3.047v8.251a3.047 3.047 0 003.047 3.047h8.251a3.047 3.047 0 003.047-3.047v-8.25a3.047 3.047 0 00-3.047-3.047z"
            ></path>
         </g>
         <defs>
            <filter
               id="filter0_iii_917_4287"
               width="116.748"
               height="75.993"
               x="6.626"
               y="27.375"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="1.625" dy="-1.625"></feOffset>
               <feGaussianBlur stdDeviation="1.523"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.254902 0 0 0 0 0.580392 0 0 0 0 0.764706 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_917_4287"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dy="-1.625"></feOffset>
               <feGaussianBlur stdDeviation="1.523"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.223529 0 0 0 0 0.419608 0 0 0 0 0.639216 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="effect1_innerShadow_917_4287" result="effect2_innerShadow_917_4287"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="-1.625" dy="1.625"></feOffset>
               <feGaussianBlur stdDeviation="1.523"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.380392 0 0 0 0 0.929412 0 0 0 0 1 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="effect2_innerShadow_917_4287" result="effect3_innerShadow_917_4287"></feBlend>
            </filter>
            <filter
               id="filter1_iii_917_4287"
               width="77.11"
               height="17.063"
               x="19.78"
               y="61.094"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="-0.406" dy="0.406"></feOffset>
               <feGaussianBlur stdDeviation="0.406"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.211765 0 0 0 0 0.580392 0 0 0 0 0.807843 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_917_4287"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="0.406" dy="-0.406"></feOffset>
               <feGaussianBlur stdDeviation="0.305"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.788235 0 0 0 0 0.764706 0 0 0 0 0.784314 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="effect1_innerShadow_917_4287" result="effect2_innerShadow_917_4287"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="-0.406" dy="0.406"></feOffset>
               <feGaussianBlur stdDeviation="0.305"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.921569 0 0 0 0 0.92549 0 0 0 0 0.92549 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="effect2_innerShadow_917_4287" result="effect3_innerShadow_917_4287"></feBlend>
            </filter>
            <filter
               id="filter2_iii_917_4287"
               width="47.99"
               height="4.875"
               x="24.631"
               y="67.314"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dy="0.406"></feOffset>
               <feGaussianBlur stdDeviation="0.203"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.819608 0 0 0 0 0.788235 0 0 0 0 0.792157 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_917_4287"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dy="-0.406"></feOffset>
               <feGaussianBlur stdDeviation="0.203"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.615686 0 0 0 0 0.592157 0 0 0 0 0.592157 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="effect1_innerShadow_917_4287" result="effect2_innerShadow_917_4287"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dy="0.406"></feOffset>
               <feGaussianBlur stdDeviation="0.203"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.670588 0 0 0 0 0.662745 0 0 0 0 0.662745 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="effect2_innerShadow_917_4287" result="effect3_innerShadow_917_4287"></feBlend>
            </filter>
            <filter
               id="filter3_iii_917_4287"
               width="15.563"
               height="15.157"
               x="98.211"
               y="78.741"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="-0.813" dy="0.406"></feOffset>
               <feGaussianBlur stdDeviation="0.609"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.2 0 0 0 0 0.596078 0 0 0 0 0.835294 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_917_4287"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="-0.406" dy="0.406"></feOffset>
               <feGaussianBlur stdDeviation="0.813"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.996078 0 0 0 0 0.905882 0 0 0 0 0.309804 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="effect1_innerShadow_917_4287" result="effect2_innerShadow_917_4287"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="0.406" dy="-0.406"></feOffset>
               <feGaussianBlur stdDeviation="0.813"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.870588 0 0 0 0 0.694118 0 0 0 0 0.2 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="effect2_innerShadow_917_4287" result="effect3_innerShadow_917_4287"></feBlend>
            </filter>
            <linearGradient
               id="paint0_linear_917_4287"
               x1="65"
               x2="65"
               y1="29"
               y2="98.444"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#57D8F2"></stop>
               <stop offset="1" stopColor="#36A3E7"></stop>
            </linearGradient>
            <linearGradient
               id="paint1_linear_917_4287"
               x1="8.251"
               x2="121.749"
               y1="49.694"
               y2="49.694"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#5D476D"></stop>
               <stop offset="0.88" stopColor="#3D3B3B"></stop>
            </linearGradient>
            <linearGradient
               id="paint2_linear_917_4287"
               x1="8.251"
               x2="14.361"
               y1="48.602"
               y2="48.602"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.239" stopColor="#513E5F"></stop>
               <stop offset="0.707" stopColor="#513E5F" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint3_linear_917_4287"
               x1="121.749"
               x2="118.954"
               y1="49.187"
               y2="49.187"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.328" stopColor="#474646"></stop>
               <stop offset="1" stopColor="#474646" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint4_linear_917_4287"
               x1="24.631"
               x2="68.811"
               y1="69.751"
               y2="69.751"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#A5A0A1"></stop>
               <stop offset="1" stopColor="#A6A2A2"></stop>
            </linearGradient>
            <linearGradient
               id="paint5_linear_917_4287"
               x1="113.368"
               x2="101.688"
               y1="84.859"
               y2="84.859"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#F9D940"></stop>
               <stop offset="1" stopColor="#EAC037"></stop>
            </linearGradient>
         </defs>
      </svg>
   );
}
