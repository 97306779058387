function IconChip(props) {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="72"
         height="54"
         fill="none"
         viewBox="0 0 72 54"
         {...props}
      >
         <mask
            id="b"
            style={{ maskType: "alpha" }}
            width="72"
            height="54"
            x="0"
            y="0"
            maskUnits="userSpaceOnUse"
         >
            <rect width="72" height="52.97" y="0.25" fill="url(#a)" rx="14"></rect>
         </mask>
         <g mask="url(#b)">
            <rect width="72" height="52.97" y="0.25" fill="url(#c)" rx="14"></rect>
            <path
               fill="#000"
               fillOpacity="0.2"
               fillRule="evenodd"
               d="M26.19 34.73C23.85 67.39 7.83 92.66-11.6 92.66c-21.02 0-38.07-29.64-38.07-66.2 0-36.57 17.05-66.21 38.07-66.21 19.25 0 35.16 24.84 37.71 57.1h19.75c2.55-32.26 18.47-57.1 37.72-57.1 21.02 0 38.06 29.64 38.06 66.2 0 36.57-17.04 66.2-38.06 66.2-19.42 0-35.44-25.26-37.78-57.92H26.2zm-2.34-2.48a113.94 113.94 0 00-.04-12.42h-49.47a4.97 4.97 0 00-4.96 4.97v2.48a4.97 4.97 0 004.96 4.97h49.51zm-.15 2.48c-1.07 14.58-4.92 27.57-10.52 37.3C6.5 83.63-2.34 90.18-11.6 90.18c-9.24 0-18.09-6.54-24.76-18.15C-43 60.49-47.17 44.38-47.17 26.45S-43-7.58-36.35-19.13c6.67-11.6 15.52-18.14 24.76-18.14 9.25 0 18.1 6.54 24.77 18.14 5.49 9.55 9.3 22.23 10.45 36.48h-49.29a7.45 7.45 0 00-7.44 7.45v2.48a7.45 7.45 0 007.44 7.45H23.7zm21.96-2.48H26.34a116.45 116.45 0 00-.05-12.42H45.7a116.2 116.2 0 00-.04 12.42zm2.64 2.48c1.07 14.58 4.92 27.57 10.52 37.3 6.67 11.6 15.52 18.15 24.77 18.15 9.24 0 18.09-6.54 24.76-18.15 6.64-11.54 10.82-27.65 10.82-45.58S115-7.58 108.35-19.13c-6.67-11.6-15.52-18.14-24.76-18.14-9.25 0-18.1 6.54-24.77 18.14-5.5 9.55-9.3 22.23-10.46 36.48h4.6a7.45 7.45 0 017.45 7.45v2.48a7.45 7.45 0 01-7.45 7.45H48.3zm-.1-14.9h4.76a4.97 4.97 0 014.97 4.97v2.48a4.97 4.97 0 01-4.97 4.97h-4.81a113.83 113.83 0 01.04-12.42z"
               clipRule="evenodd"
            ></path>
         </g>
         <defs>
            <linearGradient
               id="a"
               x1="5.79"
               x2="58.76"
               y1="-0.03"
               y2="52.93"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#F5D359"></stop>
               <stop offset="0.41" stopColor="#EBC335"></stop>
               <stop offset="1" stopColor="#F5D359"></stop>
            </linearGradient>
            <linearGradient
               id="c"
               x1="5.79"
               x2="58.76"
               y1="-0.03"
               y2="52.93"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#F5D359"></stop>
               <stop offset="0.41" stopColor="#EBC335"></stop>
               <stop offset="1" stopColor="#F5D359"></stop>
            </linearGradient>
         </defs>
      </svg>
   );
}

export default IconChip;
