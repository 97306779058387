import Button from "../../Button";
import Container from "../Container";
import ReactLoading from "react-loading";

import scss from "./index.module.scss";

const { qr } = scss;

function QR({ data }) {
   return (
      <div>
         <Container className={qr} light>
            {data?.qr ? (
               <img src={data?.qr} alt="QR-код для оплаты задолжности через СБП" />
            ) : (
               <ReactLoading type={"spin"} color="#2d9614" />
            )}
         </Container>
         {data?.qr ? (
            <Button download href={data?.qr + "&download"}>
               Скачать QR код
            </Button>
         ) : null}
      </div>
   );
}

export default QR;
