function IconCheckmark(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
         <path
            fill="#010101"
            d="M7.739 17.166L3.573 12.48a.9.9 0 10-1.346 1.195l4.8 5.4a.901.901 0 001.31.039l12.6-12.6a.9.9 0 00-1.273-1.272L7.74 17.166z"
         ></path>
      </svg>
   );
}

export default IconCheckmark;
