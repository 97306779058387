import React from "react";

export default function IconTelescope(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="130" height="130" fill="none" viewBox="0 0 130 130" {...props}>
         <path stroke="url(#paint0_linear_910_4987)" strokeLinecap="round" strokeWidth="5.688" d="M65 65v52.747"></path>
         <path
            stroke="url(#paint1_linear_910_4987)"
            strokeLinecap="round"
            strokeWidth="5.688"
            d="M67.397 64.683l37.387 53.259"
         ></path>
         <path
            stroke="url(#paint2_linear_910_4987)"
            strokeLinecap="round"
            strokeWidth="5.688"
            d="M67.397 64.683l37.387 53.259"
         ></path>
         <path
            stroke="url(#paint3_radial_910_4987)"
            strokeLinecap="round"
            strokeWidth="5.688"
            d="M67.397 64.683l37.387 53.259"
         ></path>
         <path
            stroke="url(#paint4_linear_910_4987)"
            strokeLinecap="round"
            strokeWidth="5.688"
            d="M67.397 64.683l37.387 53.259"
         ></path>
         <path
            stroke="url(#paint5_linear_910_4987)"
            strokeLinecap="round"
            strokeWidth="5.688"
            d="M62.603 64.748l-37.387 53.255"
         ></path>
         <path
            stroke="url(#paint6_linear_910_4987)"
            strokeLinecap="round"
            strokeWidth="5.688"
            d="M62.603 64.748l-37.387 53.255"
         ></path>
         <g filter="url(#filter0_f_910_4987)">
            <path
               stroke="url(#paint7_linear_910_4987)"
               strokeLinecap="round"
               strokeWidth="1.625"
               d="M62.603 64.683l-37.387 53.259"
            ></path>
         </g>
         <path fill="#251350" d="M62.445 46.975h5.11v14.344h-5.11V46.975z"></path>
         <path
            fill="url(#paint8_linear_910_4987)"
            d="M122.537 50.334a3.045 3.045 0 00-1.824-3.9L85.906 33.788l-6.878 18.939 34.807 12.647a3.048 3.048 0 003.9-1.829l4.802-13.21z"
         ></path>
         <path
            fill="url(#paint9_linear_910_4987)"
            d="M122.537 50.334a3.045 3.045 0 00-1.824-3.9L85.906 33.788l-6.878 18.939 34.807 12.647a3.048 3.048 0 003.9-1.829l4.802-13.21z"
         ></path>
         <path
            fill="url(#paint10_linear_910_4987)"
            d="M122.537 50.334a3.045 3.045 0 00-1.824-3.9L85.906 33.788l-6.878 18.939 34.807 12.647a3.048 3.048 0 003.9-1.829l4.802-13.21z"
         ></path>
         <path
            fill="url(#paint11_linear_910_4987)"
            d="M106.291 62.631l6.878-18.94 7.544 2.743a3.047 3.047 0 011.828 3.9L117.74 63.55a3.056 3.056 0 01-1.575 1.721 3.046 3.046 0 01-2.33.104l-7.544-2.743z"
         ></path>
         <path
            fill="url(#paint12_linear_910_4987)"
            d="M106.291 62.631l6.878-18.94 7.544 2.743a3.047 3.047 0 011.828 3.9L117.74 63.55a3.056 3.056 0 01-1.575 1.721 3.046 3.046 0 01-2.33.104l-7.544-2.743z"
         ></path>
         <path
            fill="url(#paint13_radial_910_4987)"
            d="M106.291 62.631l6.878-18.94 7.544 2.743a3.047 3.047 0 011.828 3.9L117.74 63.55a3.056 3.056 0 01-1.575 1.721 3.046 3.046 0 01-2.33.104l-7.544-2.743z"
         ></path>
         <g filter="url(#filter1_f_910_4987)">
            <path fill="#C9C2D2" d="M88.57 41.068l21.288 7.747-1.097 3.006-21.287-7.747 1.097-3.006z"></path>
         </g>
         <path
            fill="url(#paint14_linear_910_4987)"
            d="M11.428 17.456a13.875 13.875 0 0117.777-8.303l63.72 23.144a.406.406 0 01.245.52l-9.198 25.317a.406.406 0 01-.52.244l-63.72-23.144a13.876 13.876 0 01-8.304-17.778z"
         ></path>
         <path
            fill="url(#paint15_linear_910_4987)"
            d="M11.428 17.456a13.875 13.875 0 0117.777-8.303l63.72 23.144a.406.406 0 01.245.52l-9.198 25.317a.406.406 0 01-.52.244l-63.72-23.144a13.876 13.876 0 01-8.304-17.778z"
         ></path>
         <g filter="url(#filter2_i_910_4987)">
            <path
               fill="#431670"
               d="M71.431 57.704a4.538 4.538 0 010 9.075H58.577a4.538 4.538 0 010-9.075h12.854z"
            ></path>
         </g>
         <path
            fill="url(#paint16_radial_910_4987)"
            d="M71.43 57.704a4.538 4.538 0 110 9.075H58.578a4.538 4.538 0 010-9.075h12.854z"
         ></path>
         <path
            fill="url(#paint17_radial_910_4987)"
            d="M71.43 57.704a4.538 4.538 0 110 9.075H58.578a4.538 4.538 0 010-9.075h12.854z"
         ></path>
         <g filter="url(#filter3_f_910_4987)">
            <path fill="#C9C2D2" d="M39.54 20.894L89.444 39.06l-1.093 3.006L38.447 23.9l1.093-3.006z"></path>
         </g>
         <g filter="url(#filter4_i_910_4987)">
            <path
               fill="url(#paint18_radial_910_4987)"
               d="M32.8 10.481l-9.473 26.081-3.595-1.328a13.875 13.875 0 019.473-26.081l3.596 1.328z"
            ></path>
         </g>
         <path
            fill="url(#paint19_linear_910_4987)"
            d="M43.72 14.422l-13.3-4.826-9.474 26.081 13.3 4.834 9.475-26.08v-.009z"
         ></path>
         <path
            fill="url(#paint20_linear_910_4987)"
            d="M43.72 14.422l-13.3-4.826-9.474 26.081 13.3 4.834 9.475-26.08v-.009z"
         ></path>
         <path
            fill="url(#paint21_linear_910_4987)"
            d="M43.72 14.422l-13.3-4.826-9.474 26.081 13.3 4.834 9.475-26.08v-.009z"
         ></path>
         <g filter="url(#filter5_f_910_4987)">
            <path fill="#7468B7" d="M29.38 16.632l10.969 3.993-2.105 5.785-10.968-3.993 2.104-5.785z"></path>
         </g>
         <g filter="url(#filter6_f_910_4987)">
            <path fill="#7266B6" d="M112.288 49.53l7.99 2.908-1.56 4.286-7.99-2.908 1.56-4.286z"></path>
         </g>
         <defs>
            <filter
               id="filter0_f_910_4987"
               width="42.262"
               height="58.134"
               x="22.779"
               y="62.246"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_4987" stdDeviation="0.813"></feGaussianBlur>
            </filter>
            <filter
               id="filter1_f_910_4987"
               width="30.509"
               height="18.878"
               x="83.411"
               y="37.005"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_4987" stdDeviation="2.031"></feGaussianBlur>
            </filter>
            <filter
               id="filter2_i_910_4987"
               width="23.961"
               height="11.107"
               x="52.008"
               y="57.704"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="-2.031" dy="2.031"></feOffset>
               <feGaussianBlur stdDeviation="2.031"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.188235 0 0 0 0 0.129412 0 0 0 0 0.309804 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_910_4987"></feBlend>
            </filter>
            <filter
               id="filter3_f_910_4987"
               width="59.122"
               height="29.299"
               x="34.385"
               y="16.831"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_4987" stdDeviation="2.031"></feGaussianBlur>
            </filter>
            <filter
               id="filter4_i_910_4987"
               width="22.206"
               height="28.243"
               x="10.594"
               y="8.319"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset></feOffset>
               <feGaussianBlur stdDeviation="1.016"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.713726 0 0 0 0 0.780392 0 0 0 0 0.85098 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_910_4987"></feBlend>
            </filter>
            <filter
               id="filter5_f_910_4987"
               width="17.948"
               height="14.653"
               x="24.838"
               y="14.194"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_4987" stdDeviation="1.219"></feGaussianBlur>
            </filter>
            <filter
               id="filter6_f_910_4987"
               width="14.426"
               height="12.07"
               x="108.29"
               y="47.092"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_4987" stdDeviation="1.219"></feGaussianBlur>
            </filter>
            <linearGradient id="paint0_linear_910_4987" x1="0" x2="0" y1="0" y2="0" gradientUnits="userSpaceOnUse">
               <stop stopColor="#3C1681"></stop>
               <stop offset="1" stopColor="#605482"></stop>
            </linearGradient>
            <linearGradient
               id="paint1_linear_910_4987"
               x1="84.915"
               x2="80.661"
               y1="84.232"
               y2="87.027"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#5B4DA4"></stop>
               <stop offset="1" stopColor="#423174"></stop>
            </linearGradient>
            <linearGradient
               id="paint2_linear_910_4987"
               x1="68.157"
               x2="69.619"
               y1="71.159"
               y2="70.269"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#574C77"></stop>
               <stop offset="1" stopColor="#574C77" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint3_radial_910_4987"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-2.18993 -.31737 .25355 -1.74955 107.23 117.687)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.274" stopColor="#604FA8"></stop>
               <stop offset="1" stopColor="#604FA8" stopOpacity="0"></stop>
            </radialGradient>
            <linearGradient
               id="paint4_linear_910_4987"
               x1="69.887"
               x2="69.887"
               y1="62.652"
               y2="69.887"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.407" stopColor="#1D0A4A"></stop>
               <stop offset="1" stopColor="#1D0A4A" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint5_linear_910_4987"
               x1="46.703"
               x2="40.735"
               y1="93.628"
               y2="89.818"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#5640A6"></stop>
               <stop offset="0.944" stopColor="#43356A"></stop>
            </linearGradient>
            <linearGradient
               id="paint6_linear_910_4987"
               x1="60.113"
               x2="60.113"
               y1="62.717"
               y2="69.952"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.407" stopColor="#1D0A4A"></stop>
               <stop offset="1" stopColor="#1D0A4A" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint7_linear_910_4987"
               x1="26.646"
               x2="62.603"
               y1="116.923"
               y2="63.602"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.743" stopColor="#6250A3"></stop>
               <stop offset="1" stopColor="#6250A3" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint8_linear_910_4987"
               x1="104.341"
               x2="99.389"
               y1="46.91"
               y2="59.857"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#C1C0C1"></stop>
               <stop offset="1" stopColor="#9777B3"></stop>
            </linearGradient>
            <linearGradient
               id="paint9_linear_910_4987"
               x1="103.196"
               x2="105.418"
               y1="61.888"
               y2="55.225"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.068" stopColor="#864EB1"></stop>
               <stop offset="1" stopColor="#987BB3" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint10_linear_910_4987"
               x1="82.213"
               x2="87.267"
               y1="50.257"
               y2="51.894"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.256" stopColor="#734497"></stop>
               <stop offset="1" stopColor="#734497" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint11_linear_910_4987"
               x1="119.681"
               x2="113.019"
               y1="45.581"
               y2="64.321"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.343" stopColor="#7166B3"></stop>
               <stop offset="1" stopColor="#503398"></stop>
            </linearGradient>
            <linearGradient
               id="paint12_linear_910_4987"
               x1="109.099"
               x2="111.443"
               y1="52.369"
               y2="52.971"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#533A9A"></stop>
               <stop offset="1" stopColor="#5D47A3" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint13_radial_910_4987"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(109.312 41.6 68.713) scale(7.86955 1.0816)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.091" stopColor="#7D72BE"></stop>
               <stop offset="1" stopColor="#7D72BE" stopOpacity="0"></stop>
            </radialGradient>
            <linearGradient
               id="paint14_linear_910_4987"
               x1="56.477"
               x2="51.89"
               y1="29.262"
               y2="44.371"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#C1C0C1"></stop>
               <stop offset="1" stopColor="#9777B3"></stop>
            </linearGradient>
            <linearGradient
               id="paint15_linear_910_4987"
               x1="58.508"
               x2="61.238"
               y1="50.399"
               y2="43.229"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.18" stopColor="#8E69B0"></stop>
               <stop offset="1" stopColor="#987BB3" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint16_radial_910_4987"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-20.6299 0 0 -9.14062 72.666 63.984)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.622" stopColor="#614F7B" stopOpacity="0"></stop>
               <stop offset="0.92" stopColor="#614F7B"></stop>
            </radialGradient>
            <radialGradient
               id="paint17_radial_910_4987"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-8.69631 0 0 -3.05979 68.543 61.575)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#574775"></stop>
               <stop offset="1" stopColor="#574775" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint18_radial_910_4987"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(109.822 4.576 20.325) scale(11.6056 10.7169)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.224" stopColor="#ADDEFF"></stop>
               <stop offset="1" stopColor="#A2CFFB"></stop>
            </radialGradient>
            <linearGradient
               id="paint19_linear_910_4987"
               x1="39.508"
               x2="29.941"
               y1="12.265"
               y2="38.639"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.343" stopColor="#7166B3"></stop>
               <stop offset="1" stopColor="#503398"></stop>
            </linearGradient>
            <linearGradient
               id="paint20_linear_910_4987"
               x1="25.5"
               x2="27.215"
               y1="21.901"
               y2="22.535"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#533A9A"></stop>
               <stop offset="1" stopColor="#5D47A3" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint21_linear_910_4987"
               x1="25.703"
               x2="27.483"
               y1="38.639"
               y2="34.405"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#7B64AE"></stop>
               <stop offset="1" stopColor="#7B64AE" stopOpacity="0"></stop>
            </linearGradient>
         </defs>
      </svg>
   );
}
