import { useState } from "react";
import { Outlet } from "react-router-dom";
import Aside from "../../Components/Aside";
import AsideList from "../../Components/AsideList";

import scss from "./index.module.scss";

const { container, aside, outlet } = scss;

function Settings() {
   const [menu] = useState([
      { title: "Калькулятор", link: "calculator", disabled: false },
      { title: "Об организации", link: "about-organization", disabled: false },
      { title: "Платёжная инф.", link: "payment-information", disabled: false },
      { title: "Банки СБП", link: "banks-sbp", disabled: false },
   ]);
   return (
      <div className={container}>
         <Aside className={aside}>{menu ? <AsideList data={menu} /> : null}</Aside>
         <div className={outlet}>
            <Outlet />
         </div>
      </div>
   );
}

export default Settings;
