import Button from "../../../components/Button";
import scss from "./index.module.scss";

const { cards__text, cards__grid, cards__grid_white, cards__grid_actions } = scss;

function CurrentCard(status, loan) {
   switch (status) {
      case 1:
      case 2:
      case 3: {
         return <span className={cards__text}>У вас нет активных заявок</span>;
      }
      case 4: {
         return (
            <>
               <div className={cards__grid + " " + cards__grid_white}>
                  <span>
                     Сумма займа:{" "}
                     <strong>
                        {parseFloat(loan.current.total / 100).toLocaleString("ru-RU")} ₽
                     </strong>
                  </span>
                  <span>
                     Ставка в день:{" "}
                     <strong>{loan.current.rate.toLocaleString("ru-RU")}%</strong>
                  </span>
               </div>
               <div
                  className={
                     cards__grid + " " + cards__grid_white + " " + cards__grid_actions
                  }
               >
                  <Button
                     variant={"white"}
                     href="/payment-method/total"
                     disabled={loan.current.balance <= 0}
                  >
                     {loan.current.balance > 0 ? "Погасить заём" : "Заём погашен"}
                  </Button>
                  <span>
                     Остаток:{" "}
                     <strong>
                        {parseFloat(
                           loan.current.balance > 0 ? loan.current.balance / 100 : 0
                        ).toLocaleString("ru-RU")}{" "}
                        ₽
                     </strong>
                     <br /> <span>Включая проценты</span>
                  </span>
               </div>
            </>
         );
      }
      default: {
         return (
            <>
               <span className={cards__text}>У вас нет активных заявок</span>
               <Button variant={"white"} href="https://займ-круглосуточно.рф/">
                  Оформить заём
               </Button>
            </>
         );
      }
   }
}

export default CurrentCard;
