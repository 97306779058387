import React from "react";

export default function IconBanknote() {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="260" height="256" fill="none" viewBox="0 0 260 256">
         <g filter="url(#filter0_f_910_5255)" opacity="0.5">
            <path
               fill="url(#paint0_linear_910_5255)"
               d="M223.776 69H36.224c-9.72 0-17.6 7.88-17.6 17.6v111.048c0 9.72 7.88 17.6 17.6 17.6h187.552c9.72 0 17.6-7.88 17.6-17.6V86.6c0-9.72-7.88-17.6-17.6-17.6z"
            ></path>
            <path
               fill="url(#paint1_linear_910_5255)"
               d="M223.776 69H36.224c-9.72 0-17.6 7.88-17.6 17.6v111.048c0 9.72 7.88 17.6 17.6 17.6h187.552c9.72 0 17.6-7.88 17.6-17.6V86.6c0-9.72-7.88-17.6-17.6-17.6z"
            ></path>
            <path
               fill="url(#paint2_linear_910_5255)"
               d="M223.776 69H36.224c-9.72 0-17.6 7.88-17.6 17.6v111.048c0 9.72 7.88 17.6 17.6 17.6h187.552c9.72 0 17.6-7.88 17.6-17.6V86.6c0-9.72-7.88-17.6-17.6-17.6z"
            ></path>
            <path
               fill="url(#paint3_radial_910_5255)"
               d="M224.152 40H36.6C26.88 40 19 47.88 19 57.6v111.048c0 9.72 7.88 17.6 17.6 17.6h187.552c9.72 0 17.6-7.88 17.6-17.6V57.6c0-9.72-7.88-17.6-17.6-17.6z"
            ></path>
            <path
               fill="#fff"
               d="M72.635 118.11a3 3 0 00-3 3v3.967a3 3 0 003 3h23.019a3 3 0 013 3v.756a3 3 0 01-3 3H72.635a3 3 0 00-3 3v3.967a3 3 0 01-3 3h-3.303a3 3 0 01-3-3v-3.967a3 3 0 00-3-3H55.8a3 3 0 01-3-3v-.756a3 3 0 013-3h1.532a3 3 0 003-3V95.423a3 3 0 00-1.858-2.774l-3.816-1.572a3 3 0 01-1.858-2.774V87a3 3 0 013-3h34.325c6.424 0 11.076 1.329 13.956 3.987 2.879 2.658 4.319 7.05 4.319 13.179 0 5.98-1.44 10.299-4.319 12.957-2.88 2.658-7.532 3.987-13.956 3.987h-17.49zm-3-10.752a3 3 0 003 3h15.94c3.84 0 6.46-.628 7.863-1.883 1.477-1.329 2.216-3.765 2.216-7.309 0-3.692-.739-6.165-2.216-7.42-1.403-1.33-4.024-1.994-7.863-1.994h-15.94a3 3 0 00-3 3v12.606z"
            ></path>
            <path
               fill="url(#paint4_linear_910_5255)"
               fillRule="evenodd"
               d="M218.504 57.248h-177a6 6 0 00-6 6v100.248c0 3.32 2.68 6 6 6h177a6.003 6.003 0 004.243-1.757 6.003 6.003 0 001.757-4.243V63.256a6 6 0 00-6-6v-.008zm-177-6a12 12 0 00-12 12v100.248a11.998 11.998 0 0012 12h177c3.183 0 6.235-1.264 8.485-3.515a11.996 11.996 0 003.515-8.485V63.256a11.998 11.998 0 00-12-12h-177v-.008z"
               clipRule="evenodd"
            ></path>
            <path
               fill="#289D5B"
               d="M168.752 147.504c20.435 0 37-15.28 37-34.128s-16.565-34.128-37-34.128-37 15.28-37 34.128 16.565 34.128 37 34.128z"
            ></path>
            <path
               fill="url(#paint5_linear_910_5255)"
               d="M168.752 147.504c20.435 0 37-15.28 37-34.128s-16.565-34.128-37-34.128-37 15.28-37 34.128 16.565 34.128 37 34.128z"
            ></path>
            <path
               fill="url(#paint6_radial_910_5255)"
               d="M168.752 147.504c20.435 0 37-15.28 37-34.128s-16.565-34.128-37-34.128-37 15.28-37 34.128 16.565 34.128 37 34.128z"
            ></path>
            <path fill="#127531" d="M121.752 42.504H155v144h-34.504l1.256-144z"></path>
            <path fill="#0B3F2E" d="M121.872 213.752H155v-27.256h-34.504l1.376 27.256z"></path>
            <path fill="url(#paint7_linear_910_5255)" d="M122.504 40.248h35v175h-35v-175z"></path>
            <path fill="url(#paint8_linear_910_5255)" d="M122.504 40.248h35v175h-35v-175z"></path>
         </g>
         <path
            fill="url(#paint9_linear_910_5255)"
            d="M223.776 69H36.224c-9.72 0-17.6 7.88-17.6 17.6v111.048c0 9.72 7.88 17.6 17.6 17.6h187.552c9.72 0 17.6-7.88 17.6-17.6V86.6c0-9.72-7.88-17.6-17.6-17.6z"
         ></path>
         <path
            fill="url(#paint10_linear_910_5255)"
            d="M223.776 69H36.224c-9.72 0-17.6 7.88-17.6 17.6v111.048c0 9.72 7.88 17.6 17.6 17.6h187.552c9.72 0 17.6-7.88 17.6-17.6V86.6c0-9.72-7.88-17.6-17.6-17.6z"
         ></path>
         <path
            fill="url(#paint11_linear_910_5255)"
            d="M223.776 69H36.224c-9.72 0-17.6 7.88-17.6 17.6v111.048c0 9.72 7.88 17.6 17.6 17.6h187.552c9.72 0 17.6-7.88 17.6-17.6V86.6c0-9.72-7.88-17.6-17.6-17.6z"
         ></path>
         <path
            fill="url(#paint12_radial_910_5255)"
            d="M224.152 40H36.6C26.88 40 19 47.88 19 57.6v111.048c0 9.72 7.88 17.6 17.6 17.6h187.552c9.72 0 17.6-7.88 17.6-17.6V57.6c0-9.72-7.88-17.6-17.6-17.6z"
         ></path>
         <g filter="url(#filter1_i_910_5255)">
            <path
               fill="#fff"
               d="M72.635 118.11a3 3 0 00-3 3v3.967a3 3 0 003 3h23.019a3 3 0 013 3v.756a3 3 0 01-3 3H72.635a3 3 0 00-3 3v3.967a3 3 0 01-3 3h-3.303a3 3 0 01-3-3v-3.967a3 3 0 00-3-3H55.8a3 3 0 01-3-3v-.756a3 3 0 013-3h1.532a3 3 0 003-3V95.423a3 3 0 00-1.858-2.774l-3.816-1.572a3 3 0 01-1.858-2.774V87a3 3 0 013-3h34.325c6.424 0 11.076 1.329 13.956 3.987 2.879 2.658 4.319 7.05 4.319 13.179 0 5.98-1.44 10.299-4.319 12.957-2.88 2.658-7.532 3.987-13.956 3.987h-17.49zm-3-10.752a3 3 0 003 3h15.94c3.84 0 6.46-.628 7.864-1.883 1.476-1.329 2.215-3.765 2.215-7.309 0-3.692-.739-6.165-2.215-7.42-1.403-1.33-4.025-1.994-7.864-1.994h-15.94a3 3 0 00-3 3v12.606z"
            ></path>
         </g>
         <g filter="url(#filter2_iii_910_5255)">
            <path
               fill="url(#paint13_linear_910_5255)"
               fillRule="evenodd"
               d="M218.504 57.248h-177a6 6 0 00-6 6v100.248c0 3.32 2.68 6 6 6h177a6.003 6.003 0 004.243-1.757 6.003 6.003 0 001.757-4.243V63.256a6 6 0 00-6-6v-.008zm-177-6a12 12 0 00-12 12v100.248a11.998 11.998 0 0012 12h177c3.183 0 6.235-1.264 8.485-3.515a11.996 11.996 0 003.515-8.485V63.256a11.998 11.998 0 00-12-12h-177v-.008z"
               clipRule="evenodd"
            ></path>
         </g>
         <path
            fill="#289D5B"
            d="M168.752 147.504c20.434 0 37-15.28 37-34.128s-16.566-34.128-37-34.128c-20.435 0-37 15.28-37 34.128s16.565 34.128 37 34.128z"
         ></path>
         <path
            fill="url(#paint14_linear_910_5255)"
            d="M168.752 147.504c20.434 0 37-15.28 37-34.128s-16.566-34.128-37-34.128c-20.435 0-37 15.28-37 34.128s16.565 34.128 37 34.128z"
         ></path>
         <path
            fill="url(#paint15_radial_910_5255)"
            d="M168.752 147.504c20.434 0 37-15.28 37-34.128s-16.566-34.128-37-34.128c-20.435 0-37 15.28-37 34.128s16.565 34.128 37 34.128z"
         ></path>
         <g filter="url(#filter3_f_910_5255)">
            <path fill="#127531" d="M121.752 42.504H155v144h-34.504l1.256-144z"></path>
         </g>
         <g filter="url(#filter4_f_910_5255)">
            <path fill="#0B3F2E" d="M121.872 213.752H155v-27.256h-34.504l1.376 27.256z"></path>
         </g>
         <path fill="url(#paint16_linear_910_5255)" d="M122.504 40.248h35v175h-35v-175z"></path>
         <path fill="url(#paint17_linear_910_5255)" d="M122.504 40.248h35v175h-35v-175z"></path>
         <defs>
            <filter
               id="filter0_f_910_5255"
               width="259.128"
               height="211.248"
               x="0.624"
               y="22"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_5255" stdDeviation="9"></feGaussianBlur>
            </filter>
            <filter
               id="filter1_i_910_5255"
               width="58.6"
               height="60.8"
               x="49.8"
               y="84"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="-3"></feOffset>
               <feGaussianBlur stdDeviation="2"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_910_5255"></feBlend>
            </filter>
            <filter
               id="filter2_iii_910_5255"
               width="209"
               height="128.248"
               x="25.504"
               y="51.248"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dy="4"></feOffset>
               <feGaussianBlur stdDeviation="2.5"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.0941176 0 0 0 0 0.509804 0 0 0 0 0.254902 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_910_5255"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="-4"></feOffset>
               <feGaussianBlur stdDeviation="2.5"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.0941176 0 0 0 0 0.509804 0 0 0 0 0.254902 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="effect1_innerShadow_910_5255" result="effect2_innerShadow_910_5255"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="4"></feOffset>
               <feGaussianBlur stdDeviation="2.5"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.172549 0 0 0 0 0.709804 0 0 0 0 0.4 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="effect2_innerShadow_910_5255" result="effect3_innerShadow_910_5255"></feBlend>
            </filter>
            <filter
               id="filter3_f_910_5255"
               width="50.504"
               height="160"
               x="112.496"
               y="34.504"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_5255" stdDeviation="4"></feGaussianBlur>
            </filter>
            <filter
               id="filter4_f_910_5255"
               width="44.504"
               height="37.256"
               x="115.496"
               y="181.496"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_5255" stdDeviation="2.5"></feGaussianBlur>
            </filter>
            <linearGradient
               id="paint0_linear_910_5255"
               x1="227.224"
               x2="26.389"
               y1="203.572"
               y2="203.572"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#12724A"></stop>
               <stop offset="1" stopColor="#116245"></stop>
            </linearGradient>
            <linearGradient
               id="paint1_linear_910_5255"
               x1="241.752"
               x2="220.468"
               y1="195.184"
               y2="195.184"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#1C8F60"></stop>
               <stop offset="1" stopColor="#1C8F60" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint2_linear_910_5255"
               x1="18.624"
               x2="40.661"
               y1="193.986"
               y2="193.986"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.557" stopColor="#195840"></stop>
               <stop offset="1" stopColor="#195840" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint3_radial_910_5255"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(103.67484 139.00185 -175.85269 131.16012 23.512 46.893)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#31DE74"></stop>
               <stop offset="1" stopColor="#20CF5E"></stop>
            </radialGradient>
            <linearGradient
               id="paint4_linear_910_5255"
               x1="215.66"
               x2="29.183"
               y1="43.882"
               y2="165.328"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#24A35B"></stop>
               <stop offset="1" stopColor="#27A05A"></stop>
            </linearGradient>
            <linearGradient
               id="paint5_linear_910_5255"
               x1="93.257"
               x2="136.218"
               y1="138.223"
               y2="138.223"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.228" stopColor="#269553"></stop>
               <stop offset="1" stopColor="#269553" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint6_radial_910_5255"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(-36.318 280.66 -127.736) scale(109.46 103.798)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.587" stopColor="#249755" stopOpacity="0"></stop>
               <stop offset="0.987" stopColor="#16793D"></stop>
            </radialGradient>
            <linearGradient
               id="paint7_linear_910_5255"
               x1="130.163"
               x2="130.163"
               y1="53.275"
               y2="191.967"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFD425"></stop>
               <stop offset="1" stopColor="#FFAD24"></stop>
            </linearGradient>
            <linearGradient
               id="paint8_linear_910_5255"
               x1="148.504"
               x2="148.504"
               y1="215.248"
               y2="184.504"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.894" stopColor="#CC8139"></stop>
               <stop offset="1" stopColor="#CC8139" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint9_linear_910_5255"
               x1="226.872"
               x2="26.376"
               y1="205.504"
               y2="205.504"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#12724A"></stop>
               <stop offset="1" stopColor="#116245"></stop>
            </linearGradient>
            <linearGradient
               id="paint10_linear_910_5255"
               x1="241.376"
               x2="220.128"
               y1="198.504"
               y2="198.504"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#1C8F60"></stop>
               <stop offset="1" stopColor="#1C8F60" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint11_linear_910_5255"
               x1="18.624"
               x2="40.624"
               y1="197.504"
               y2="197.504"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.557" stopColor="#195840"></stop>
               <stop offset="1" stopColor="#195840" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint12_radial_910_5255"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(103.50047 116.00037 -152.11777 135.72594 23.88 45.752)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#31DE74"></stop>
               <stop offset="1" stopColor="#20CF5E"></stop>
            </radialGradient>
            <linearGradient
               id="paint13_linear_910_5255"
               x1="207"
               x2="65"
               y1="54"
               y2="171.504"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#24A35B"></stop>
               <stop offset="1" stopColor="#27A05A"></stop>
            </linearGradient>
            <linearGradient
               id="paint14_linear_910_5255"
               x1="156.504"
               x2="170.752"
               y1="117.504"
               y2="117.504"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.228" stopColor="#269553"></stop>
               <stop offset="1" stopColor="#269553" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint15_radial_910_5255"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(29.25016 -25.24985 24.81824 28.75018 168.747 118.746)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.587" stopColor="#249755" stopOpacity="0"></stop>
               <stop offset="0.987" stopColor="#16793D"></stop>
            </radialGradient>
            <linearGradient
               id="paint16_linear_910_5255"
               x1="140"
               x2="140"
               y1="53.504"
               y2="192"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFD425"></stop>
               <stop offset="1" stopColor="#FFAD24"></stop>
            </linearGradient>
            <linearGradient
               id="paint17_linear_910_5255"
               x1="148.504"
               x2="148.504"
               y1="215.248"
               y2="184.504"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.894" stopColor="#CC8139"></stop>
               <stop offset="1" stopColor="#CC8139" stopOpacity="0"></stop>
            </linearGradient>
         </defs>
      </svg>
   );
}
