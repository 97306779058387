import IconCopy from "../../Icons/Copy";
import Container from "../Container";
import scss from "./index.module.scss";

const { information, information__item, information__copy } = scss;

function Information({ title, data, requisites, wait }) {
   return (
      <Container title={title}>
         {requisites ? (
            <>
               <ul className={information}>
                  <li className={information__item}>
                     <h3>ИНН</h3>
                     {wait ? <p>{data?.inn}</p> : <div className="loader"></div>}
                     <button
                        disabled={wait ? false : true}
                        className={information__copy}
                        type={"button"}
                        onClick={() =>
                           navigator.clipboard.writeText(
                              wait ? data?.inn : "Загрузка данных"
                           )
                        }
                     >
                        <IconCopy />
                     </button>
                  </li>
                  <li className={information__item}>
                     <h3>Расчётный счёт</h3>
                     {wait ? <p>{data?.account}</p> : <div className="loader"></div>}
                     <button
                        disabled={wait ? false : true}
                        className={information__copy}
                        type={"button"}
                        onClick={() =>
                           navigator.clipboard.writeText(
                              wait ? data?.account : "Загрузка данных"
                           )
                        }
                     >
                        <IconCopy />
                     </button>
                  </li>
                  <li className={information__item}>
                     <h3>БИК банка получателя</h3>
                     {wait ? <p>{data?.bic}</p> : <div className="loader"></div>}
                     <button
                        disabled={wait ? false : true}
                        className={information__copy}
                        type={"button"}
                        onClick={() =>
                           navigator.clipboard.writeText(
                              wait ? data?.bic : "Загрузка данных"
                           )
                        }
                     >
                        <IconCopy />
                     </button>
                  </li>
                  <li className={information__item}>
                     <h3>НДС</h3>
                     {wait ? <p>Включён</p> : <div className="loader"></div>}
                     <button
                        disabled={wait ? false : true}
                        className={information__copy}
                        type={"button"}
                        onClick={() =>
                           navigator.clipboard.writeText(
                              wait ? "Включён" : "Загрузка данных"
                           )
                        }
                     >
                        <IconCopy />
                     </button>
                  </li>
                  <li className={information__item}>
                     <h3>Назначение платежа</h3>
                     {wait ? <p>{data?.purpose}</p> : <div className="loader"></div>}
                     <button
                        disabled={wait ? false : true}
                        className={information__copy}
                        type={"button"}
                        onClick={() =>
                           navigator.clipboard.writeText(
                              wait ? data?.purpose : "Загрузка данных"
                           )
                        }
                     >
                        <IconCopy />
                     </button>
                  </li>
                  <li className={information__item}>
                     <h3>Сумма платежа</h3>
                     {wait ? (
                        <p>{(data?.amount / 100).toLocaleString("ru-RU")} ₽</p>
                     ) : (
                        <div className="loader"></div>
                     )}
                     <button
                        disabled={wait ? false : true}
                        className={information__copy}
                        type={"button"}
                        onClick={() =>
                           navigator.clipboard.writeText(
                              wait ? data?.amount : "Загрузка данных"
                           )
                        }
                     >
                        <IconCopy />
                     </button>
                  </li>
               </ul>
            </>
         ) : data ? (
            Object.keys(data).includes("card") ? (
               <ul className={information}>
                  {
                     data?.purpose ? (
                        <li className={information__item}>
                           <h3>Комментарий платежа</h3>
                           {wait ? <p>{data?.purpose}</p> : <div className="loader"></div>}
                        </li>
                     ) : ""
                  }
                  <li className={information__item}>
                     <h3>Сумма платежа</h3>
                     {wait ? (
                        <p>{(data?.amount / 100).toLocaleString("ru-Ru")} ₽</p>
                     ) : (
                        <div className="loader"></div>
                     )}
                  </li>
               </ul>
            ) : (
               <ul className={information}>
                  <li className={information__item}>
                     <h3>Назначение платежа</h3>
                     {wait ? <p>{data?.purpose}</p> : <div className="loader"></div>}
                  </li>
                  <li className={information__item}>
                     <h3>ФИО плательщика</h3>
                     {wait ? (
                        <p>
                           {(data?.name ? data?.name : "—") +
                              " " +
                              (data?.midName ? data?.midName : "—") +
                              " " +
                              (data?.surName ? data?.surName : "—")}
                        </p>
                     ) : (
                        <div className="loader"></div>
                     )}
                  </li>
                  <li className={information__item}>
                     <h3>Сумма платежа</h3>
                     {wait ? (
                        <p>{(data?.amount / 100).toLocaleString("ru-Ru")} ₽</p>
                     ) : (
                        <div className="loader"></div>
                     )}
                  </li>
               </ul>
            )
         ) : (
            <div>
               <ul className={information}>
                  <li className={information__item}>
                     <div className="loader"></div>
                  </li>
               </ul>
            </div>
         )}
      </Container>
   );
}

export default Information;
