import { useRef } from "react";
import classes from "./index.module.scss";

const { infoHtml, infoHtml__text } = classes;

function InfoHtml({
   data,

   editable = false,
   blockIndex = -1,
   onChange = () => {}
}) {

   const defaultContent = useRef(data.html).current;

   return (
      <section className={infoHtml}>
         <div
            className={infoHtml__text}
            dangerouslySetInnerHTML={{ __html: defaultContent }}

            contentEditable={editable} suppressContentEditableWarning={true}
            value={data.html}
            onChange={e => onChange(blockIndex, "html", e.target.innerHTML)}
            onBlur={e => onChange(blockIndex, "html", e.target.innerHTML)}
         />
      </section>
   );
}

export default InfoHtml;
