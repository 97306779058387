import React from "react";

export default function IconPlus(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none" viewBox="0 0 48 48" {...props}>
         <g filter="url(#a)">
            <path
               fill="url(#b)"
               d="M28.87 7.24a4.6 4.6 0 00-9.18 0v10.68a1.13 1.13 0 01-1.13 1.12H7.88a4.6 4.6 0 000 9.18h10.68a1.13 1.13 0 011.13 1.13V40a4.6 4.6 0 009.18 0V29.35a1.12 1.12 0 011.12-1.13h10.67a4.6 4.6 0 000-9.18H29.99a1.13 1.13 0 01-1.12-1.12V7.24z"
            ></path>
         </g>
         <g filter="url(#c)">
            <path
               fill="url(#d)"
               d="M25.17 5.06c-1.12 0-2.03.9-2.03 2.02v12.36a1.5 1.5 0 01-1.5 1.5H8.63a2.02 2.02 0 000 4.04h13.03a1.5 1.5 0 011.5 1.5v13.69a2.02 2.02 0 104.04 0V26.48a1.5 1.5 0 011.5-1.5h13a2.02 2.02 0 000-4.04H28.69a1.5 1.5 0 01-1.5-1.5V7.08c0-1.11-.9-2.02-2.02-2.02z"
            ></path>
            <path
               fill="url(#e)"
               d="M25.17 5.06c-1.12 0-2.03.9-2.03 2.02v12.36a1.5 1.5 0 01-1.5 1.5H8.63a2.02 2.02 0 000 4.04h13.03a1.5 1.5 0 011.5 1.5v13.69a2.02 2.02 0 104.04 0V26.48a1.5 1.5 0 011.5-1.5h13a2.02 2.02 0 000-4.04H28.69a1.5 1.5 0 01-1.5-1.5V7.08c0-1.11-.9-2.02-2.02-2.02z"
            ></path>
         </g>
         <defs>
            <linearGradient id="b" x1="24.27" x2="24.27" y1="2.65" y2="44.6" gradientUnits="userSpaceOnUse">
               <stop stopColor="#5D4C6E"></stop>
               <stop offset="0.85" stopColor="#332C3C"></stop>
            </linearGradient>
            <linearGradient id="d" x1="29.81" x2="29.81" y1="40.59" y2="10.31" gradientUnits="userSpaceOnUse">
               <stop stopColor="#4D4655"></stop>
               <stop offset="1" stopColor="#4D4655" stopOpacity="0"></stop>
            </linearGradient>
            <filter
               id="a"
               width="44.21"
               height="44.21"
               x="3.29"
               y="0.4"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="3"></feOffset>
               <feGaussianBlur stdDeviation="1.13"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.168627 0 0 0 0 0.156863 0 0 0 0 0.188235 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_910_5469"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dy="-3"></feOffset>
               <feGaussianBlur stdDeviation="1.13"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.215686 0 0 0 0 0.117647 0 0 0 0 0.294118 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="effect1_innerShadow_910_5469" result="effect2_innerShadow_910_5469"></feBlend>
            </filter>
            <filter
               id="c"
               width="41.62"
               height="41.63"
               x="4.35"
               y="2.81"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_5469" stdDeviation="1.13"></feGaussianBlur>
            </filter>
            <radialGradient
               id="e"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-10.5 14.5 -15.7 -11.4 36.72 11.23)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.3" stopColor="#6D607C"></stop>
               <stop offset="1" stopColor="#6D607C" stopOpacity="0"></stop>
            </radialGradient>
         </defs>
      </svg>
   );
}
