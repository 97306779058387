import { useRef } from "react";
import IconCheck from "../Icons/Check";
import css from "./styles.module.scss";

const {
    checkbox,
    checkbox__input,
    checkbox__block,
    checkbox__icon,
} = css;

const CheckBox = ({className, value = false, onChange = () => {}}) => {

    let tmpClassName = checkbox;
    tmpClassName += className ? " " + className : "";

    const input = useRef();

    const change = () => {
        onChange(input.current.checked);
    }

    return (
        <div className={tmpClassName} onClick={change}>
            <input
                type={"checkbox"}
                className={checkbox__input}
                checked={value}
                onChange={e=>{}}
                ref={input}
            />
            <div className={checkbox__block}>
                <IconCheck
                    className={checkbox__icon}
                />
            </div>
        </div>
    )
}

export default CheckBox;