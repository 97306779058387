import { Link } from "react-router-dom";
import IconDropDownArrow from "../../Icons/DropDownArrow";
import IconMoneyWind from "../../Icons/MoneyWind";
import IconOk from "../../Icons/Ok";
import IconPensive from "../../Icons/Pensive";

import scss from "./index.module.scss";

const {
   list,
   list__item,
   list__title,
   list__text,
   list__time,
   container,
   icon,
   icon_chevron,
} = scss;

function LoanHistoryList(data) {
   const ICONS = [
      <IconPensive className={icon} />,
      <IconMoneyWind className={icon} />,
      <IconOk className={icon} />,
      <IconMoneyWind className={icon} />,
      <IconOk className={icon} />,
   ];

   const getStatus = (status) => {
      switch (status) {
         case 0: {
            return "Отказ по займу";
         }
         case 1: {
            return "Заявка на новый заём";
         }
         case 2: {
            return "Заём одобрен";
         }
         case 3: {
            return "Получен заём";
         }
         case 4: {
            return "Вы выплатили заём";
         }
         default: {
            return "—";
         }
      }
   };

   return (
      <div className={list}>
         {data.data.map((item, key) =>
            item.status !== 3 && item.status !== 4 ? (
               <div className={list__item} key={key}>
                  {ICONS[item.status]}
                  <article className={container}>
                     <h3 className={list__title}>{getStatus(item.status)}</h3>
                     <p className={list__text}>
                        {item.amount
                           ? "Заём на сумму " +
                             (item.amount / 100).toLocaleString("ru-RU") +
                             " ₽" +
                             (item.date_end
                                ? " до " +
                                  new Date(item.date_end).toLocaleDateString("ru-RU") +
                                  " под " +
                                  item.rate +
                                  "% в день"
                                : "")
                           : ""}
                     </p>
                     <time className={list__time}>
                        {new Date(item.timestamp).toLocaleDateString("ru-RU")}
                     </time>
                  </article>
               </div>
            ) : (
               <Link className={list__item} to={"/loan/" + item.id_loan} key={key}>
                  {ICONS[item.status]}
                  <article className={container}>
                     <h3 className={list__title}>{getStatus(item.status)}</h3>
                     <p className={list__text}>
                        {item.amount
                           ? "Заём на сумму " +
                             (item.amount / 100).toLocaleString("ru-RU") +
                             " ₽" +
                             (item.date_end
                                ? " до " +
                                  new Date(item.date_end).toLocaleDateString("ru-RU") +
                                  " под " +
                                  item.rate +
                                  "% в день"
                                : "")
                           : ""}
                     </p>
                     <time className={list__time}>
                        {new Date(item.timestamp).toLocaleDateString("ru-RU")}
                     </time>
                  </article>
                  <IconDropDownArrow className={icon_chevron} />
               </Link>
            )
         )}
      </div>
   );
}

export default LoanHistoryList;
