function IconPeople(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
         <path
            fill="#010101"
            d="M8 12a4 4 0 100-8 4 4 0 000 8zm9 0a3 3 0 100-6 3 3 0 000 6zM4.5 14A2.5 2.5 0 002 16.5S2 21 8 21c4.756 0 5.742-2.828 5.946-4 .054-.306.054-.5.054-.5a2.5 2.5 0 00-2.5-2.5h-7zm11.492 3.2a5.284 5.284 0 01-.18 1.072c-.126.446-.334.98-.684 1.53a8.2 8.2 0 001.87.198c5 0 5-3.5 5-3.5a2.5 2.5 0 00-2.5-2.5H15.24c.48.716.758 1.574.758 2.5v.5l-.006.2z"
         ></path>
      </svg>
   );
}

export default IconPeople;
