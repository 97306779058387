import classes from "./styles.module.scss";

const {
    floatingTools,
    floatingTools__button
} = classes;

const createFloatingTool = (title = "", icon = <></>, callback = () => {}) => {
    return {
        title,
        icon,
        callback
    };
}

const PageEditorFloatingTools = ({tools = [createFloatingTool("Действие", <>⨯</>, () => {})], className = ""}) => {
    return (
        <div className={floatingTools + (className ? " " + className : "")}>
            {
                tools.map(({title, icon, callback}, i) => (
                    title ? (
                        <button
                            className={floatingTools__button}
                            type="button"
                            title={title}
                            onClick={callback}
                            key={i}
                        >{icon}</button>
                    ) : ""
                ))
            }
        </div>
    );
}

export { createFloatingTool };

export default PageEditorFloatingTools;