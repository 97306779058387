import Button from "../../components/Button";
import Header from "../../components/Header";
import NotFoundIcon from "../../components/Icons/NotFound";

import css from "./styles.module.scss";

const { errorPage, errorPage__img, errorPage__text, errorPage__button } = css;

function Error() {
   return (
      <>
         <Header />
         <main className={errorPage}>
            <NotFoundIcon className={errorPage__img} />
            <h1 className={errorPage__text}>Страница не найдена или&nbsp;недоступна</h1>
            <Button href="/" className={errorPage__button}>
               Вернуться на главную
            </Button>
         </main>
      </>
   );
}

export default Error;
