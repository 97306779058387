function IconCalendar(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
         <path
            fill="#010101"
            d="M7 4a3 3 0 00-3 3v.6h16.8V7a3 3 0 00-3-3H7zm13.8 4.8H4v9a3 3 0 003 3h10.8a3 3 0 003-3v-9zM10 12.4a1.2 1.2 0 11-2.4 0 1.2 1.2 0 012.4 0zm-1.2 4.8a1.2 1.2 0 110-2.4 1.2 1.2 0 010 2.4zm4.8-4.8a1.2 1.2 0 11-2.4 0 1.2 1.2 0 012.4 0zm-1.2 4.8a1.2 1.2 0 110-2.4 1.2 1.2 0 010 2.4zm4.8-4.8a1.2 1.2 0 11-2.4 0 1.2 1.2 0 012.4 0z"
         ></path>
      </svg>
   );
}

export default IconCalendar;
