function IconWalletCreditCard(props) {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="64"
         height="64"
         fill="none"
         viewBox="0 0 64 64"
         {...props}
      >
         <path
            fill="url(#a)"
            d="M37.47 4.89a4 4 0 00-5.65.6L20.23 20h5.12l9.59-12.02 3.08 2.52L30.3 20h5.15l5.67-6.98 6.87 5.62L46.8 20H48a12 12 0 013.52.52 4 4 0 00-1-4.98L37.47 4.9zM12 22a2 2 0 012-2h2.23l3.18-4H14a6 6 0 00-6 6v24a10 10 0 0010 10h30a8 8 0 008-8V32a8 8 0 00-8-8H14a2 2 0 01-2-2zm30 18h4a2 2 0 010 4h-4a2 2 0 010-4z"
         ></path>
         <defs>
            <linearGradient
               id="a"
               x1="8"
               x2="59.85"
               y1="56"
               y2="8.15"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#2D9614"></stop>
               <stop offset="1" stopColor="#BAE860"></stop>
            </linearGradient>
         </defs>
      </svg>
   );
}

export default IconWalletCreditCard;
