import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";

import css from "./styles.module.scss";

const { inputRange, inputRange_white, inputRange__wrapper, inputRange__label, inputRange__value } = css;

const InputRange = ({ label, min = 0, showValue = false, className, variant, value, length, step = 1, onChange }) => {
   let classNameTmp = inputRange + " single-thumb";

   classNameTmp += className ? " " + className : "";

   classNameTmp += variant === "white" ? " " + inputRange_white : "";

   return (
      <div className={inputRange__wrapper}>
         {label ? <p className={inputRange__label}>{label}</p> : ""}
         {showValue ? <p className={inputRange__value}>{showValue}</p> : ""}
         <RangeSlider
            aria-label="Тянулка"
            className={classNameTmp}
            defaultValue={[0, value]}
            step={step}
            min={min}
            max={length}
            thumbsDisabled={[true, false]}
            onInput={(e) => onChange(e[1])}
         />
      </div>
   );
};

export default InputRange;
