import React from "react";

export default function IconClock(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="260" height="260" fill="none" viewBox="0 0 260 260" {...props}>
         <g filter="url(#filter0_f_910_5239)" opacity="0.5">
            <path
               fill="url(#paint0_radial_910_5239)"
               d="M242 130c0 61.856-50.144 112-112 112S18 191.856 18 130 68.144 18 130 18s112 50.144 112 112z"
            ></path>
            <path
               fill="#C2BFC6"
               d="M122.304 62.248a8 8 0 018 8v50.2a15.905 15.905 0 01-7.88 29.653 15.908 15.908 0 01-13.797-7.798 15.905 15.905 0 01-2.219-7.895l-29.68-17.136a7.99 7.99 0 01-3.766-4.858 8.004 8.004 0 012.86-8.451 8.005 8.005 0 015.944-1.572 7.994 7.994 0 012.962 1.025l29.552 17.064.024-.008V70.24a8.001 8.001 0 018-8v.008z"
            ></path>
            <path
               fill="url(#paint1_linear_910_5239)"
               fillRule="evenodd"
               d="M130.232 58.128a8 8 0 018 8v63.96a8 8 0 11-16 0v-63.96a8.001 8.001 0 018-8z"
               clipRule="evenodd"
            ></path>
            <path
               fill="url(#paint2_linear_910_5239)"
               fillRule="evenodd"
               d="M81.728 102.224a8.001 8.001 0 0110.928-2.928L134 123.16a8.002 8.002 0 012.974 10.955A8.001 8.001 0 01126 137.016l-41.344-23.864a8 8 0 01-2.928-10.928z"
               clipRule="evenodd"
            ></path>
            <path
               fill="url(#paint3_linear_910_5239)"
               fillRule="evenodd"
               d="M81.728 102.224a8.001 8.001 0 0110.928-2.928L134 123.16a8.002 8.002 0 012.974 10.955A8.001 8.001 0 01126 137.016l-41.344-23.864a8 8 0 01-2.928-10.928z"
               clipRule="evenodd"
            ></path>
            <path
               fill="url(#paint4_radial_910_5239)"
               fillRule="evenodd"
               d="M81.728 102.224a8.001 8.001 0 0110.928-2.928L134 123.16a8.002 8.002 0 012.974 10.955A8.001 8.001 0 01126 137.016l-41.344-23.864a8 8 0 01-2.928-10.928z"
               clipRule="evenodd"
            ></path>
            <path
               fill="url(#paint5_linear_910_5239)"
               fillRule="evenodd"
               d="M81.728 102.224a8.001 8.001 0 0110.928-2.928L134 123.16a8.002 8.002 0 012.974 10.955A8.001 8.001 0 01126 137.016l-41.344-23.864a8 8 0 01-2.928-10.928z"
               clipRule="evenodd"
            ></path>
            <path
               fill="url(#paint6_linear_910_5239)"
               fillRule="evenodd"
               d="M130 218c48.6 0 88-39.4 88-88s-39.4-88-88-88-88 39.4-88 88 39.4 88 88 88zm0 24c61.856 0 112-50.144 112-112S191.856 18 130 18 18 68.144 18 130s50.144 112 112 112z"
               clipRule="evenodd"
            ></path>
            <path
               fill="url(#paint7_radial_910_5239)"
               d="M146.16 130.088c0 4.22-1.676 8.267-4.661 11.251a15.908 15.908 0 01-22.502 0 15.908 15.908 0 010-22.502 15.908 15.908 0 0122.502 0 15.908 15.908 0 014.661 11.251z"
            ></path>
            <path
               fill="url(#paint8_radial_910_5239)"
               d="M146.16 130.088c0 4.22-1.676 8.267-4.661 11.251a15.908 15.908 0 01-22.502 0 15.908 15.908 0 010-22.502 15.908 15.908 0 0122.502 0 15.908 15.908 0 014.661 11.251z"
            ></path>
         </g>
         <path
            fill="url(#paint9_radial_910_5239)"
            d="M242 130c0 61.856-50.144 112-112 112S18 191.856 18 130 68.144 18 130 18s112 50.144 112 112z"
         ></path>
         <g filter="url(#filter1_f_910_5239)">
            <path
               fill="#C2BFC6"
               d="M122.304 62.248a8 8 0 018 8v50.2a15.905 15.905 0 01-7.88 29.653 15.901 15.901 0 01-13.797-7.797 15.911 15.911 0 01-2.219-7.896l-29.68-17.136a7.992 7.992 0 01-3.766-4.858 8 8 0 0111.766-8.998l29.552 17.064.024-.008V70.24a8.001 8.001 0 018-8v.008z"
            ></path>
         </g>
         <g filter="url(#filter2_i_910_5239)">
            <path
               fill="url(#paint10_linear_910_5239)"
               fillRule="evenodd"
               d="M130.232 58.128a8.001 8.001 0 018 8v63.96a8.002 8.002 0 01-8 8 8 8 0 01-8-8v-63.96a8.001 8.001 0 018-8z"
               clipRule="evenodd"
            ></path>
         </g>
         <path
            fill="url(#paint11_linear_910_5239)"
            fillRule="evenodd"
            d="M81.728 102.224a8.002 8.002 0 0110.928-2.928L134 123.16a8.002 8.002 0 012.974 10.955A8.001 8.001 0 01126 137.016l-41.344-23.864a8 8 0 01-2.928-10.928z"
            clipRule="evenodd"
         ></path>
         <path
            fill="url(#paint12_linear_910_5239)"
            fillRule="evenodd"
            d="M81.728 102.224a8.002 8.002 0 0110.928-2.928L134 123.16a8.002 8.002 0 012.974 10.955A8.001 8.001 0 01126 137.016l-41.344-23.864a8 8 0 01-2.928-10.928z"
            clipRule="evenodd"
         ></path>
         <path
            fill="url(#paint13_radial_910_5239)"
            fillRule="evenodd"
            d="M81.728 102.224a8.002 8.002 0 0110.928-2.928L134 123.16a8.002 8.002 0 012.974 10.955A8.001 8.001 0 01126 137.016l-41.344-23.864a8 8 0 01-2.928-10.928z"
            clipRule="evenodd"
         ></path>
         <path
            fill="url(#paint14_linear_910_5239)"
            fillRule="evenodd"
            d="M81.728 102.224a8.002 8.002 0 0110.928-2.928L134 123.16a8.002 8.002 0 012.974 10.955A8.001 8.001 0 01126 137.016l-41.344-23.864a8 8 0 01-2.928-10.928z"
            clipRule="evenodd"
         ></path>
         <g filter="url(#filter3_ii_910_5239)">
            <path
               fill="url(#paint15_linear_910_5239)"
               fillRule="evenodd"
               d="M130 218c48.6 0 88-39.4 88-88s-39.4-88-88-88-88 39.4-88 88 39.4 88 88 88zm0 24c61.856 0 112-50.144 112-112S191.856 18 130 18 18 68.144 18 130s50.144 112 112 112z"
               clipRule="evenodd"
            ></path>
         </g>
         <path
            fill="url(#paint16_radial_910_5239)"
            d="M146.16 130.088a15.912 15.912 0 11-31.824-.002 15.912 15.912 0 0131.824.002z"
         ></path>
         <path
            fill="url(#paint17_radial_910_5239)"
            d="M146.16 130.088a15.912 15.912 0 11-31.824-.002 15.912 15.912 0 0131.824.002z"
         ></path>
         <defs>
            <filter
               id="filter0_f_910_5239"
               width="260"
               height="260"
               x="0"
               y="0"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_5239" stdDeviation="9"></feGaussianBlur>
            </filter>
            <filter
               id="filter1_f_910_5239"
               width="89.532"
               height="111.861"
               x="60.682"
               y="50.24"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_5239" stdDeviation="6"></feGaussianBlur>
            </filter>
            <filter
               id="filter2_i_910_5239"
               width="20.8"
               height="79.96"
               x="122.232"
               y="58.128"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="4.8"></feOffset>
               <feGaussianBlur stdDeviation="3.4"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.635294 0 0 0 0 0.227451 0 0 0 0 0.403922 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_910_5239"></feBlend>
            </filter>
            <filter
               id="filter3_ii_910_5239"
               width="230.4"
               height="230.4"
               x="14.8"
               y="14.8"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="3.2" dy="-3.2"></feOffset>
               <feGaussianBlur stdDeviation="2.4"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.282353 0 0 0 0 0.254902 0 0 0 0 0.313726 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_910_5239"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="-3.2" dy="3.2"></feOffset>
               <feGaussianBlur stdDeviation="2.4"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.533333 0 0 0 0 0.533333 0 0 0 0 0.533333 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="effect1_innerShadow_910_5239" result="effect2_innerShadow_910_5239"></feBlend>
            </filter>
            <radialGradient
               id="paint0_radial_910_5239"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(-46.212 271.311 6.896) scale(202.373)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.598" stopColor="#DDDADF"></stop>
               <stop offset="1" stopColor="#BDBABF"></stop>
            </radialGradient>
            <linearGradient
               id="paint1_linear_910_5239"
               x1="130"
               x2="130"
               y1="18"
               y2="242"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#F83F4C"></stop>
               <stop offset="1" stopColor="#F74093"></stop>
            </linearGradient>
            <linearGradient
               id="paint2_linear_910_5239"
               x1="22.183"
               x2="202.649"
               y1="40.46"
               y2="112.862"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#32183D"></stop>
               <stop offset="1" stopColor="#2C3A65"></stop>
            </linearGradient>
            <linearGradient
               id="paint3_linear_910_5239"
               x1="88.505"
               x2="114.001"
               y1="147.422"
               y2="116.729"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#2E2840"></stop>
               <stop offset="1" stopColor="#2E2840" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint4_radial_910_5239"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(154.95586 128.71035 -20.78647 25.02506 42.315 25.554)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#50375F"></stop>
               <stop offset="1" stopColor="#50375F" stopOpacity="0"></stop>
            </radialGradient>
            <linearGradient
               id="paint5_linear_910_5239"
               x1="22.183"
               x2="44.472"
               y1="40.46"
               y2="49.377"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#3E1846"></stop>
               <stop offset="1" stopColor="#3E1846" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint6_linear_910_5239"
               x1="198.088"
               x2="36.272"
               y1="34.104"
               y2="230.272"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#6D6B70"></stop>
               <stop offset="1" stopColor="#706C74"></stop>
            </linearGradient>
            <radialGradient
               id="paint7_radial_910_5239"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(134.039 73.328 92.584) scale(191.702)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#C9C6CC"></stop>
               <stop offset="1" stopColor="#928E95"></stop>
            </radialGradient>
            <radialGradient
               id="paint8_radial_910_5239"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(139.399 44.07 87.946) scale(23.0489)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.527" stopColor="#776F7F" stopOpacity="0"></stop>
               <stop offset="1" stopColor="#776F7F"></stop>
            </radialGradient>
            <radialGradient
               id="paint9_radial_910_5239"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(-46.212 271.311 6.896) scale(202.373)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.598" stopColor="#DDDADF"></stop>
               <stop offset="1" stopColor="#BDBABF"></stop>
            </radialGradient>
            <linearGradient
               id="paint10_linear_910_5239"
               x1="130.232"
               x2="130.232"
               y1="58.128"
               y2="138.088"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#F83F4C"></stop>
               <stop offset="1" stopColor="#F74093"></stop>
            </linearGradient>
            <linearGradient
               id="paint11_linear_910_5239"
               x1="81.728"
               x2="121.992"
               y1="102.224"
               y2="125.464"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#32183D"></stop>
               <stop offset="1" stopColor="#2C3A65"></stop>
            </linearGradient>
            <linearGradient
               id="paint12_linear_910_5239"
               x1="98.72"
               x2="102.72"
               y1="121.272"
               y2="114.344"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#2E2840"></stop>
               <stop offset="1" stopColor="#2E2840" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint13_radial_910_5239"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(39.70042 22.92105 -3.26112 5.64843 86.886 99.57)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#50375F"></stop>
               <stop offset="1" stopColor="#50375F" stopOpacity="0"></stop>
            </radialGradient>
            <linearGradient
               id="paint14_linear_910_5239"
               x1="81.728"
               x2="86.704"
               y1="102.224"
               y2="105.088"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#3E1846"></stop>
               <stop offset="1" stopColor="#3E1846" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint15_linear_910_5239"
               x1="198.088"
               x2="36.272"
               y1="34.104"
               y2="230.272"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#6D6B70"></stop>
               <stop offset="1" stopColor="#706C74"></stop>
            </linearGradient>
            <radialGradient
               id="paint16_radial_910_5239"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(134.039 42.64 92.664) scale(27.2354)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#C9C6CC"></stop>
               <stop offset="1" stopColor="#928E95"></stop>
            </radialGradient>
            <radialGradient
               id="paint17_radial_910_5239"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(139.399 44.07 87.946) scale(23.0489)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.527" stopColor="#776F7F" stopOpacity="0"></stop>
               <stop offset="1" stopColor="#776F7F"></stop>
            </radialGradient>
         </defs>
      </svg>
   );
}
