import scss from "./index.module.scss";

const { button } = scss;

function IconButton({ children, onClick, type }) {
   return (
      <button className={button} type={type} onClick={onClick}>
         {children}
      </button>
   );
}

export default IconButton;
