import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../Button";
import Container from "../Container";

import scss from "./index.module.scss";

const { confirm, confirm__text, confirm_red, red } = scss;

function Confirm({ title, subtext, content, wait, number, amount, params }) {
   const token = localStorage.getItem("token");
   const navigate = useNavigate();
   const [denide, setDenide] = useState(false);

   const confirmPayment = async () => {
      if (!params.token) {
         if (token) {
            const body = new FormData();
            body.append("number", number);
            body.append("amount", amount);
            const json = await (
               await fetch(global.api + "payments", {
                  method: "POST",
                  headers: {
                     authorization: token,
                  },
                  body,
               })
            ).json();

            if (json?.status === "success") {
               localStorage.setItem("resultPage", "paymentDone");
               navigate("/payment-method/done");
            } else {
               setDenide(true);
            }
         }
      } else {
         const body = new FormData();
         body.append("number", number);
         body.append("amount", amount);
         body.append("token",  params.token);
         const json = await (
            await fetch(global.api + "payments", {
               method: "POST",
               body,
            })
         ).json();

         if (json?.status === "success") {
            localStorage.setItem("resultPage", "paymentDone");
            navigate("/oplatit/done");
         } else {
            setDenide(true);
         }
      }
   };

   return !denide ? (
      <Container title={title} subtext={subtext}>
         <div className={confirm}>
            <p className={confirm__text}>{content.text}</p>
            <Button isWaiting={wait ? false : true} onClick={confirmPayment} disabled={!number && !amount}>
               {content.buttonText}
            </Button>
         </div>
      </Container>
   ) : (
      <Container
         red
         title={"Что-то пошло не так!"}
         subtext={
            "Сейчас не возможно подтвердить оплату по техническим причинам. Вы можете попровать позже или обратиться в службу поддержки клиентов."
         }
      >
         <div className={confirm + " " + confirm_red}>
            <p className={confirm__text}>Приносим свои извинения. Подтверждение платежа может занять дольше времени.</p>
            <Button className={red} href={!params?.token ? "/profile" : "/"}>
               {!params?.token ? "Вернуться в профиль" : "На главную"}
            </Button>
         </div>
      </Container>
   );
}

export default Confirm;
