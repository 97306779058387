import React from "react";

export default function IconFlash(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="250" height="260" fill="none" viewBox="0 0 250 260" {...props}>
         <g clipPath="url(#clip0_910_5137)">
            <g filter="url(#filter0_f_910_5137)" opacity="0.36">
               <path
                  fill="url(#paint0_linear_910_5137)"
                  d="M52.5 126.289l97.5-100-22.5 86 45.5 20-99.5 97.5 22-81.5-43-22z"
               ></path>
            </g>
            <path
               fill="url(#paint1_linear_910_5137)"
               d="M148.273 26.84l-93.08 99.519a4.002 4.002 0 002.098 6.645l34.817 7.4a4.811 4.811 0 013.7 5.698L80.32 218.968l-4.142-.88-4.198 19.75 6.344 1.348a2.338 2.338 0 002.392-.673l103.203-109.147a2.408 2.408 0 00-1.241-3.994l-39.728-8.444a4.004 4.004 0 01-3.082-4.747l19.203-90.346a2.38 2.38 0 00-1.711-2.816l-7.094-1.516-1.985 9.34-.008-.003z"
            ></path>
            <g filter="url(#filter1_i_910_5137)">
               <path
                  fill="url(#paint2_linear_910_5137)"
                  d="M148.067 18.226l-99.764 106.66a3.998 3.998 0 00-.9 3.935 4.01 4.01 0 002.986 2.716l34.818 7.401a4.805 4.805 0 013.7 5.698l-19.187 90.27c-.502 2.363 2.433 3.903 4.095 2.141l103.211-109.146a2.4 2.4 0 00-.135-3.42 2.403 2.403 0 00-1.114-.575l-39.728-8.445a4 4 0 01-3.082-4.746l19.203-90.347c.506-2.38-2.439-3.913-4.103-2.142z"
               ></path>
            </g>
            <path
               fill="url(#paint3_linear_910_5137)"
               d="M148.067 18.226l-99.764 106.66a3.998 3.998 0 00-.9 3.935 4.01 4.01 0 002.986 2.716l34.818 7.401a4.805 4.805 0 013.7 5.698l-19.187 90.27c-.502 2.363 2.433 3.903 4.095 2.141l103.211-109.146a2.4 2.4 0 00-.135-3.42 2.403 2.403 0 00-1.114-.575l-39.728-8.445a4 4 0 01-3.082-4.746l19.203-90.347c.506-2.38-2.439-3.913-4.103-2.142z"
            ></path>
            <path
               fill="url(#paint4_radial_910_5137)"
               d="M148.067 18.226l-99.764 106.66a3.998 3.998 0 00-.9 3.935 4.01 4.01 0 002.986 2.716l34.818 7.401a4.805 4.805 0 013.7 5.698l-19.187 90.27c-.502 2.363 2.433 3.903 4.095 2.141l103.211-109.146a2.4 2.4 0 00-.135-3.42 2.403 2.403 0 00-1.114-.575l-39.728-8.445a4 4 0 01-3.082-4.746l19.203-90.347c.506-2.38-2.439-3.913-4.103-2.142z"
            ></path>
            <path
               fill="url(#paint5_linear_910_5137)"
               d="M148.067 18.226l-99.764 106.66a3.998 3.998 0 00-.9 3.935 4.01 4.01 0 002.986 2.716l34.818 7.401a4.805 4.805 0 013.7 5.698l-19.187 90.27c-.502 2.363 2.433 3.903 4.095 2.141l103.211-109.146a2.4 2.4 0 00-.135-3.42 2.403 2.403 0 00-1.114-.575l-39.728-8.445a4 4 0 01-3.082-4.746l19.203-90.347c.506-2.38-2.439-3.913-4.103-2.142z"
            ></path>
            <path
               fill="url(#paint6_radial_910_5137)"
               d="M148.276 18.702L48.511 125.36a4.007 4.007 0 00-.898 3.935 3.998 3.998 0 002.985 2.717l34.818 7.4a4.806 4.806 0 013.7 5.698L69.93 235.382c-.502 2.362 2.433 3.903 4.095 2.14l103.211-109.145a2.404 2.404 0 00-1.249-3.996l-39.728-8.444a4.004 4.004 0 01-3.083-4.747l19.204-90.346c.506-2.38-2.439-3.914-4.103-2.142z"
            ></path>
         </g>
         <defs>
            <filter
               id="filter0_f_910_5137"
               width="240.5"
               height="323.5"
               x="-7.5"
               y="-33.711"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_5137" stdDeviation="30"></feGaussianBlur>
            </filter>
            <filter
               id="filter1_i_910_5137"
               width="130.454"
               height="220.35"
               x="47.221"
               y="17.458"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset></feOffset>
               <feGaussianBlur stdDeviation="5"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.968627 0 0 0 0 0.431373 0 0 0 0 0.337255 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_910_5137"></feBlend>
            </filter>
            <linearGradient
               id="paint0_linear_910_5137"
               x1="112.75"
               x2="112.75"
               y1="26.289"
               y2="229.789"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#E89847"></stop>
               <stop offset="1" stopColor="#F3594E"></stop>
            </linearGradient>
            <linearGradient
               id="paint1_linear_910_5137"
               x1="155.006"
               x2="126.77"
               y1="64.98"
               y2="197.821"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFBD61"></stop>
               <stop offset="1" stopColor="#EB5C74"></stop>
            </linearGradient>
            <linearGradient
               id="paint2_linear_910_5137"
               x1="121.34"
               x2="93.48"
               y1="85.018"
               y2="216.087"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFA547"></stop>
               <stop offset="1" stopColor="#F14B49"></stop>
            </linearGradient>
            <linearGradient
               id="paint3_linear_910_5137"
               x1="136.321"
               x2="116.478"
               y1="14.539"
               y2="107.889"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#DF9844"></stop>
               <stop offset="1" stopColor="#DF9844" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint4_radial_910_5137"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(102 46.81 89.468) scale(55.6825 12.1489)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FE9052"></stop>
               <stop offset="1" stopColor="#FE9052" stopOpacity="0"></stop>
            </radialGradient>
            <linearGradient
               id="paint5_linear_910_5137"
               x1="109.193"
               x2="112.358"
               y1="58.822"
               y2="61.77"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#C76C38"></stop>
               <stop offset="1" stopColor="#C76C38" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint6_radial_910_5137"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-11.99228 56.41926 -20.56409 -4.37103 145.118 61.105)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFB54B"></stop>
               <stop offset="1" stopColor="#FFB54B" stopOpacity="0"></stop>
            </radialGradient>
            <clipPath id="clip0_910_5137">
               <path fill="#fff" d="M0 0H250V259.124H0z"></path>
            </clipPath>
         </defs>
      </svg>
   );
}
