import { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../../components/Footer";
import PageBuilder from "../../components/pageBuilder";

import css from "./styles.module.scss";

const {
   header,
   header__breadcrumbs,
   header__breadcrumbsItem,
   header__breadcrumbsLink,
   header__breadcrumbsText,
   header__title,
   header__description,
} = css;

function ContentPages(props) {
   let initialState = {};

   if (window.initialState) {
      initialState = window.initialState;
      window.initialState = null;
   }

   const [content, setContent] = useState(initialState.content ? initialState.content : null);
   const [pageData, setPageData] = useState({});

   const location = useLocation();

   const getPageContent = async () => {
      const resp = await (await fetch(global.api + "pages?url=" + location.pathname)).json();
      setContent(resp.json_content);
      document.title = "Удобные-займы - " + resp.title;

      setPageData({
         title: resp.title,
         meta: resp.meta,
         pathname: resp.pathname,
      });
   };

   if (content === null) {
      setContent([]);
      getPageContent();
   }

   useEffect(() => {
      getPageContent();
   }, [location]);

   return (
      <>
         <main>
            <div className={header}>
               <ol className={header__breadcrumbs} itemScope itemType="https://schema.org/BreadcrumbList">
                  <li
                     className={header__breadcrumbsItem}
                     itemProp="itemListElement"
                     itemScope
                     itemType="https://schema.org/ListItem"
                  >
                     <Link className={header__breadcrumbsLink} itemProp="item" to="/">
                        <span className={header__breadcrumbsText} itemProp="name">
                           Главная
                        </span>
                     </Link>
                     <meta itemProp="position" content="1" />
                  </li>
                  <li
                     className={header__breadcrumbsItem}
                     itemProp="itemListElement"
                     itemScope
                     itemType="https://schema.org/ListItem"
                  >
                     <Link className={header__breadcrumbsLink} itemProp="item" to={"/" + pageData.pathname}>
                        <span className={header__breadcrumbsText} itemProp="name">
                           {pageData.title}
                        </span>
                     </Link>
                     <meta itemProp="position" content="2" />
                  </li>
               </ol>

               <h1 className={header__title}>{pageData.title}</h1>
               <p className={header__description}>{pageData.meta?.description}</p>
            </div>
            <PageBuilder content={content} />
         </main>
         <Footer />
      </>
   );
}

export default ContentPages;
