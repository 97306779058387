import { useEffect } from "react";
import { useState } from "react";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import css from "./styles.module.scss";

const {
    formBuilder,
    formBuilder__block,
    formBuilder__blockName,
    formBuilder__blockFields,
    formBuilder__input,
    formBuilder__input_long
} = css;


const FormBuilder = ({form = [], onSubmit = () => {}, disabled = false}) => {

    const [formState, setFormState] = useState(form);

    useEffect(() => {
        setFormState(form);
    }, [form]);

    const onChange = (blockIndex, field, value) => {
        const type = formState[blockIndex].fields[field].type;
        let formatedValue = value;

        if (type === "number") {
            formatedValue = parseInt(formatedValue);
        }
        else if (type === "textarea") {
            formatedValue = value.map(val => val.toString().replaceAll('"', '&Prime;') );
        }
        else {
            formatedValue = formatedValue.toString().replaceAll('"', '&Prime;');
        }

        formState[blockIndex].fields[field].value = formatedValue;
        setFormState(formState);
    }

    return (
        <form className={formBuilder} method="POST" action="" onSubmit={(e) => {e.preventDefault(); onSubmit(formState)}}>
            {
                formState.map(({name, fields}, i) => (
                    <div className={formBuilder__block} key={i}>
                        <h2 className={formBuilder__blockName}>{name}</h2>
                        <div className={formBuilder__blockFields}>
                            {
                                Object.keys(fields).map((param_name, j) => {

                                    const {
                                        type,
                                        value,
                                        name,
                                        placeholder,
                                        mask
                                    } = fields[param_name];

                                    return (
                                        type === "number" ? (
                                            <Input
                                                className={formBuilder__input}
                                                label={name}
                                                placeholder={placeholder}
                                                type="number"
                                                mask={mask}
                                                value={value}
                                                key={i}
                                                onChange={(e) => onChange(i, param_name, e)}
                                            />
                                        ) : (
                                            type === "long_text" ? (
                                                <Input
                                                    className={formBuilder__input + " " + formBuilder__input_long}
                                                    label={name}
                                                    placeholder={placeholder}
                                                    type="text"
                                                    mask={mask}
                                                    value={value.toString().replaceAll('&Prime;', '"')}
                                                    key={i}
                                                    onChange={(e) => onChange(i, param_name, e)}
                                                />
                                            ) : (
                                                type === "textarea" ? (
                                                    <Input
                                                        className={formBuilder__input + " " + formBuilder__input_long}
                                                        label={name}
                                                        placeholder={placeholder}
                                                        type="textarea"
                                                        mask={mask}
                                                        value={value}
                                                        key={i}
                                                        onChange={(e) => onChange(i, param_name, e)}
                                                    />
                                                ) : (
                                                    <Input
                                                        className={formBuilder__input}
                                                        label={name}
                                                        placeholder={placeholder}
                                                        type={type ? type : "text"}
                                                        mask={mask}
                                                        value={value.toString().replaceAll('&Prime;', '"')}
                                                        key={i}
                                                        onChange={(e) => onChange(i, param_name, e)}
                                                    />
                                                )
                                            )
                                        )
                                    )
                                })
                            }
                        </div>
                    </div>
                ))
            }
            <Button type="submit" disabled={disabled}>Сохранить</Button>
        </form>
    );
}

export default FormBuilder;