import React from "react";

export default function IconLightLogo(props) {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="211"
         height="81"
         fill="none"
         viewBox="0 0 211 81"
         {...props}
      >
         <path
            fill="#fff"
            d="M76.17 18.36c-8.34-3.81-10.64-4.97-9.6-7.72 1.03-2.7 8.23-3.4 12.99-1.03l1.66-6.32S69.82-1.8 61.28 5.2c-5.67 4.64-3.73 14.63 6.06 17.5 9.79 2.88 8.13 8.24 4.79 9.43-2.85 1-9.01.47-12.35-1.66l-2.02 6.68s13.07 5.78 22.5-1.84c4.48-3.62 4.24-13.15-4.1-16.95zM96.13.6h-7.87v38.65h7.87V.6zm20.09 11.1a13.88 13.88 0 00-13.98 13.78c0 7.6 6.27 13.77 13.98 13.77 7.72 0 13.98-6.16 13.98-13.77 0-7.62-6.26-13.78-13.98-13.78zm0 22.09c-3.18 0-5.75-3.73-5.75-8.31 0-4.59 2.57-8.32 5.75-8.32 3.19 0 5.75 3.73 5.75 8.32 0 4.6-2.56 8.3-5.75 8.3zm35.68-21.54c-6.5 0-9.43 4.3-9.43 4.3v-3.7h-7.06v26.4h7.87v-15.8a4.68 4.68 0 119.37 0v15.8h7.94V25.22c0-7.77-2.2-12.97-8.7-12.97zm-51.24 43.88c-5.75 0-8.34 3.78-8.34 3.78v-3.26h-6.26V80h6.96V66.02a4.15 4.15 0 014.15-4.14 4.15 4.15 0 014.14 4.14v13.95h7.01v-12.4c.03-6.86-1.91-11.44-7.66-11.44zm53.85 0c-5.75 0-8.33 3.78-8.33 3.78v-3.26h-6.27V80h6.96V66.02a4.15 4.15 0 014.15-4.14 4.16 4.16 0 014.14 4.14v13.95h7.02v-12.4c.02-6.86-1.92-11.44-7.67-11.44zm-78.13-4.12l1.42-5.4c-5.26-1.9-18.25-3.68-18.77 9.88h-3.21v5.2H59v18.26h7V61.7h7.17v18.25h6.99V56.47H66.02c.28-4.92 3.47-7.18 10.36-4.46zm51.7 4.15c-5.93-2.18-13.47 1.32-13.47 1.32l1.61 4.55c11.1-4.94 10.59 2.13 10.59 2.13-16.57-.76-16.52 12.47-9.9 15.06 6.64 2.6 10.47-2.25 10.47-2.25l.41 2.51h6.27V68.35c0-5.28-.05-10.02-5.98-12.2zM126.96 72s-.18 1.86-2.04 2.7c-3.31 1.47-6.37-1.59-4.56-4.3 1.81-2.7 6.58-2.26 6.58-2.26V72h.02zm53.86-11.31c1.4 0 2.71.44 3.8 1.16l1.01-5.41a14.76 14.76 0 00-5.38-1.01c-7.51 0-13.62 5.49-13.62 12.27 0 6.79 6.08 12.27 13.62 12.27 1.91 0 3.72-.36 5.38-1l-.98-5.8a6.83 6.83 0 11-3.83-12.48zm26.22 9.37h3.29c.1-.7.18-1.4.18-2.12 0-6.79-5.02-12.28-11.23-12.28-6.22 0-11.24 5.5-11.24 12.28 0 6.78 5.05 11.6 11.24 12 6.18.42 9.57-1.75 9.57-1.75l-1.55-4.77s-2.82 1.97-8.03 1.43c-2.1-.24-3.9-2.13-4.63-4.77h12.4v-.02zM194.5 65.4c.62-2.93 2.53-5.08 4.78-5.08 2.26 0 4.17 2.13 4.8 5.08h-9.58zM0 0v43.18h15.35C18.87 64.21 4.76 67.88 0 67.26L1.06 80s35.44 1.84 42.95-36.82V0H0z"
         ></path>
      </svg>
   );
}
