import checkAuth from "../../helpers/checkAuth";
import api from "../../helpers/fetch";
import logOut from "../../helpers/logout";
import TextOverflow from "../../helpers/text-overflow";
import IconArrowLeft from "../../manage/Components/Icons/IconArrowLeft";
import IconArrowRight from "../../manage/Components/Icons/IconArrowRight";
import IconDelete from "../../manage/Components/Icons/IconDelete";
import IconPage from "../Icons/Page";
import PageEditorFloatingTools, { createFloatingTool } from "../PageEditorFloatingTools";
import classes from "./index.module.scss";

const {
   documents,
   documents__link,
   documents__icon,
   documents__text,
   documents__input
} = classes;

function DocumentsList({
   data,

   editable = false,
   blockIndex = -1,
   onChange = () => {},
   onChangeGlobal = () => {}
}) {

   const defaultFiles = [];

   data.files.forEach(file => {
      defaultFiles.push(file.name + "");
   });

   const removeFile = async (index) => {
      
      const auth = checkAuth();
      if (!auth) logOut();

      const role = parseInt(localStorage.getItem("role"));

      if (role !== 2 && role !== 3) logOut();

      const body = {
         file: data.files[index].src
      };

      const response = await new api(`manage/files`, auth, {
         method: "DELETE",
         body: JSON.stringify(body)
      }).send();

      if (response.status === "success" || response.message === "File doesn't exist") {
         const tmpData = JSON.parse(JSON.stringify(data));
         
         tmpData.files.splice(index, 1);
         defaultFiles.splice(index, 1);

         onChangeGlobal(blockIndex, tmpData, true);
      }
   }

   const moveFile = (index, position) => {
      const newPosition = index + position;
      // console.log(newPosition);
      if (newPosition >= 0 && newPosition < data.files.length) {
         const tmpData = JSON.parse(JSON.stringify(data));
         const tmpFile  = JSON.parse(JSON.stringify(data.files[index]));
         const tmpDefaultTitle = defaultFiles[index] + "";
         
         tmpData.files.splice(index, 1);
         tmpData.files.splice(newPosition, 0, tmpFile);

         defaultFiles.splice(index, 1);
         defaultFiles.splice(newPosition, 0, tmpDefaultTitle);

         onChangeGlobal(blockIndex, tmpData);
      }
   }

   const uploadFile = async (e) => {

      if (e.target.files.length === 0) return;

      const auth = checkAuth();
      if (!auth) logOut();

      const role = parseInt(localStorage.getItem("role"));

      if (role !== 2 && role !== 3) logOut();

      const body = new FormData();
      body.append("file", e.target.files[0]);

      const response = await new api(`manage/files`, auth, {
         method: "POST",
         body
      }).send();

      if (response.status === "success") {
         const tmpData = JSON.parse(JSON.stringify(data));

         const src = response.data.file_name;
         
         tmpData.files.push({
            name: "",
            src
         });

         defaultFiles.push("");

         onChangeGlobal(blockIndex, tmpData, true);
      }
   }

   return (
      <section className={documents}>
         {data.files.map((file, key) => (
            <a
               className={documents__link}
               href={editable ? null : file.src}
               target={"_blank"}
               rel="noreferrer"
               key={key}
               title={editable ? null : "Скачать \""+file.name+"\""}
            >
               <IconPage className={documents__icon} />
               <span
                  className={documents__text}

                  contentEditable={editable} suppressContentEditableWarning={true}
                  value={file.name}
                  onChange={e => onChange(blockIndex, "files."+key+".name", e.target.textContent)}
                  onBlur={e => onChange(blockIndex, "files."+key+".name", e.target.textContent)}
               >{TextOverflow(defaultFiles[key], 76)}</span>
               {
                  editable ? (
                     <PageEditorFloatingTools
                        tools={[
                           key === 0 ? {} : createFloatingTool("Переместить назад", <IconArrowLeft />, () => moveFile(key, -1)),
                           key === data.files.length - 1 ? {} : createFloatingTool("Переместить вперёд", <IconArrowRight />   , () => moveFile(key, 1)),
                           createFloatingTool("Удалить \"" + file.name + "\"", <IconDelete />, () => removeFile(key))
                        ]}
                     />
                  ) : ""
               }
            </a>
         ))}

         {
            editable ? (
               <label
                  className={documents__link}
                  title={"Добавить новый документ"}
                  style={{cursor: "pointer"}}
               >
                  <input
                     className={documents__input}
                     type={"file"}
                     multiple={false}
                     onChange={uploadFile}
                  />
                  <IconPage className={documents__icon} />
                  <span className={documents__text}>Добавить новый документ</span>
               </label>
            ) : ""
         }
      </section>
   );
}

export default DocumentsList;
