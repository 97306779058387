import AuthWrapper from "../../AuthWrapper";
import PAFooter from "../Footer";

import css from "./index.module.scss";

const {
   main
} = css;

function PAContainer({ children }) {
   return (
      <AuthWrapper>
         <main className={main}>{children}</main>
         <PAFooter tel={9008007060} mail={"support@slon-finance.ru"} />
      </AuthWrapper>
   );
}

export default PAContainer;
