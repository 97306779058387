import React from "react";

export default function IconSbp() {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="120" height="60" fill="none" viewBox="0 0 120 60">
         <path fill="#000" d="M103.33 17.13V31.8H98.1V21.5h-5.04v10.3h-5.24V17.13h15.52z"></path>
         <path
            fill="#000"
            fillRule="evenodd"
            d="M77.06 32.32c4.68 0 8.17-2.87 8.17-7.23 0-4.22-2.57-6.96-6.87-6.96-1.98 0-3.61.7-4.84 1.9a4.83 4.83 0 014.7-4.3l4.57-.01 2.27-4.36s-5.05.12-7.4.12c-5.36.1-8.99 4.97-8.99 10.9 0 6.9 3.54 9.94 8.39 9.94zm.02-10.33c1.74 0 2.95 1.14 2.95 3.1 0 1.76-1.07 3.2-2.95 3.21-1.79 0-3-1.34-3-3.18 0-1.96 1.21-3.13 3-3.13z"
            clipRule="evenodd"
         ></path>
         <path
            fill="#000"
            d="M64.4 26.89s-1.23.7-3.08.84c-2.12.07-4.01-1.28-4.01-3.66 0-2.32 1.67-3.66 3.96-3.66 1.4 0 3.27.98 3.27.98s1.36-2.5 2.06-3.75a8.19 8.19 0 00-5-1.51c-5.06 0-8.96 3.29-8.96 7.91 0 4.68 3.67 7.9 8.95 7.8 1.48-.05 3.52-.57 4.76-1.37L64.4 26.9z"
         ></path>
         <path fill="#5B57A2" d="M0 13.06l7.27 12.99v7.92L0 46.93V13.06z"></path>
         <path fill="#D90751" d="M27.9 21.32l6.8-4.17 13.94-.01-20.74 12.7v-8.52z"></path>
         <path fill="#FAB718" d="M27.86 12.98l.04 17.2-7.28-4.48V0l7.24 12.98z"></path>
         <path fill="#ED6F26" d="M48.64 17.14H34.71l-6.85-4.16L20.62 0l28.02 17.14z"></path>
         <path fill="#63B22F" d="M27.9 47v-8.34l-7.28-4.39V60l7.28-13z"></path>
         <path fill="#1487C9" d="M34.69 42.87L7.27 26.05 0 13.06l48.61 29.8H34.7z"></path>
         <path fill="#017F36" d="M20.62 60l7.28-13 6.79-4.13 13.92-.02L20.62 60z"></path>
         <path fill="#984995" d="M0 46.93l20.68-12.66-6.95-4.26-6.46 3.96L0 46.93z"></path>
         <path
            fill="#000"
            d="M57.3 41.67c-.15.2-.36.36-.61.46a2.32 2.32 0 01-1.63.02 1.81 1.81 0 01-1.05-1 2.09 2.09 0 01.4-2.17 1.9 1.9 0 011.36-.57c.29 0 .57.05.82.12.25.08.47.2.64.38l-.3.52a1.44 1.44 0 00-.52-.3 1.8 1.8 0 00-.56-.1 1.28 1.28 0 00-1.28 1.33 1.31 1.31 0 00.4.96 1.45 1.45 0 00.95.35c.23 0 .43-.04.61-.13s.33-.2.46-.35l.32.48zm.88.53v-3.7h.66v2.76L61 38.5h.67v3.7H61v-2.76l-2.16 2.76h-.66zm7.9-.53c-.17.2-.38.36-.63.46a2.32 2.32 0 01-1.63.02 1.77 1.77 0 01-1.04-1 2.09 2.09 0 01.39-2.17c.16-.17.37-.3.6-.4.23-.11.5-.16.75-.16.3 0 .58.04.83.11.26.08.47.2.65.38l-.31.52a1.44 1.44 0 00-.51-.3 1.8 1.8 0 00-.56-.1c-.34.01-.66.14-.9.37a1.32 1.32 0 00-.39.96 1.31 1.31 0 00.4.96c.13.12.27.2.43.26.17.06.34.1.52.1a1.48 1.48 0 001.08-.49l.31.48zm.33-3.17h3.42v.66h-1.38v3.04h-.66v-3.04h-1.37v-.66z"
         ></path>
         <path
            fill="#000"
            fillRule="evenodd"
            d="M72.93 42.16c.25-.07.47-.2.65-.36l-.2-.52c-.12.11-.28.2-.48.28a2 2 0 01-.67.1c-.36 0-.67-.09-.9-.28-.25-.2-.38-.47-.4-.82h2.87a2 2 0 00-.1-1.14 1.64 1.64 0 00-.92-.88 2.12 2.12 0 00-1.5.03 1.74 1.74 0 00-.98 1.02 2.24 2.24 0 00.01 1.56 1.75 1.75 0 001.04 1 2.39 2.39 0 001.58.01zm-1.68-2.85c.2-.18.48-.27.82-.27.33 0 .59.08.78.26.19.17.29.41.3.72h-2.27c.03-.27.17-.52.37-.7z"
            clipRule="evenodd"
         ></path>
         <path
            fill="#000"
            d="M74.75 38.5h.6l1.44 1.83 1.37-1.83h.59v3.7h-.67v-2.7l-1.3 1.72h-.02l-1.35-1.72v2.7h-.66v-3.7z"
         ></path>
         <path
            fill="#000"
            fillRule="evenodd"
            d="M80.56 38.51c-.2.05-.4.14-.58.25l.18.52.45-.2c.16-.05.35-.07.58-.07.19 0 .33.03.45.09.11.05.2.13.25.23.06.1.1.23.12.38.02.14.03.3.03.47-.14-.1-.3-.16-.45-.19a2.06 2.06 0 00-1.04.03 1.2 1.2 0 00-.45.23 1.07 1.07 0 00-.4.87c0 .36.1.64.3.84.22.2.5.3.84.3.31 0 .56-.05.75-.16.19-.1.34-.23.45-.37v.48h.62v-2.28c0-.48-.1-.85-.32-1.12-.2-.26-.58-.4-1.11-.4-.24.01-.46.04-.67.1zm1.1 3a1 1 0 01-.59.16.73.73 0 01-.5-.16.55.55 0 01-.17-.42c0-.1.02-.2.06-.27a.7.7 0 01.18-.2.8.8 0 01.26-.1 1.4 1.4 0 011.14.2v.47c-.11.12-.24.23-.37.32zm6.87-4.31c.14-.07.26-.17.38-.32l-.36-.52c-.1.12-.2.2-.32.25-.12.05-.25.09-.38.11l-.13.02-.35.07c-.2.03-.4.1-.6.18-.29.13-.53.3-.71.52a2.5 2.5 0 00-.42.75c-.1.28-.17.56-.2.86-.03.3-.05.59-.05.86 0 .35.05.67.14.96a1.93 1.93 0 001.02 1.18c.24.11.51.16.82.16a1.98 1.98 0 001.39-.56 1.85 1.85 0 00.53-1.34c0-.28-.04-.53-.12-.75a1.54 1.54 0 00-.9-.95 1.91 1.91 0 00-1.6.1 1.6 1.6 0 00-.35.27c-.1.1-.19.22-.25.36h-.02c.02-.17.04-.35.08-.53a2.16 2.16 0 01.44-.95c.12-.14.27-.24.44-.31.18-.1.37-.15.57-.2l.13-.01.38-.08c.16-.03.3-.07.44-.13zm-2.34 3.55a2 2 0 01-.09-.65 1.24 1.24 0 01.42-.65c.25-.2.56-.3.87-.3.4 0 .69.13.9.36.2.25.3.54.3.9 0 .19-.03.35-.09.5a1.18 1.18 0 01-.64.66 1.22 1.22 0 01-1.41-.33c-.11-.14-.2-.3-.26-.49zm8.2-2.25h-.66v3.7h.66v-3.7zm-4.14 0h.67v1.1h.61c.3 0 .53.05.72.12.19.07.34.16.45.28.12.12.2.25.24.4a1.61 1.61 0 01-.01.97c-.05.16-.13.3-.25.42s-.28.22-.48.3c-.2.07-.45.12-.74.12h-1.21V38.5zm.67 1.73v1.36h.48c.34 0 .57-.06.7-.18.14-.12.2-.28.2-.5 0-.23-.06-.4-.2-.51-.14-.12-.37-.18-.69-.18h-.5z"
            clipRule="evenodd"
         ></path>
         <path
            fill="#000"
            d="M98.8 41.67c-.17.2-.38.36-.63.46a2.32 2.32 0 01-1.63.02 1.8 1.8 0 01-1.05-1 2.1 2.1 0 01.4-2.17 1.93 1.93 0 011.35-.56c.3 0 .58.04.83.11.25.08.47.2.65.38l-.31.52a1.44 1.44 0 00-.52-.3 1.8 1.8 0 00-.55-.1 1.29 1.29 0 00-1.29 1.33 1.31 1.31 0 00.4.96c.12.12.27.2.43.26.17.06.34.1.52.1.23 0 .43-.05.61-.14.18-.09.33-.2.47-.35l.31.48zm.33-3.17h3.41v.66h-1.37v3.04h-.66v-3.04h-1.37v-.66z"
         ></path>
         <path
            fill="#000"
            fillRule="evenodd"
            d="M103.93 38.5h-.66v5.35h.66v-1.82c.14.09.3.15.48.2.19.03.38.05.58.05a1.9 1.9 0 001.41-.57c.17-.18.3-.39.4-.63a2.29 2.29 0 00.02-1.54c-.08-.22-.2-.42-.35-.6a1.5 1.5 0 00-.54-.38 1.88 1.88 0 00-1.46.01c-.23.1-.41.22-.54.37v-.44zm.47.69a1.39 1.39 0 011.14-.06c.15.06.27.15.38.26a1.46 1.46 0 01.31.93c0 .2-.03.38-.09.54a1.19 1.19 0 01-.66.7c-.17.08-.36.11-.55.1-.18 0-.35-.01-.5-.05a1.67 1.67 0 01-.5-.23v-1.83c.12-.14.28-.26.47-.36zm7.64-.7h-.67v3.71h.67v-3.7zm-4.15 0h.67v1.12h.61c.3 0 .53.04.72.1.19.08.34.17.45.29.11.12.2.25.24.4a1.62 1.62 0 010 .97 1.23 1.23 0 01-.74.72c-.2.07-.45.12-.75.12h-1.2V38.5zm.67 1.74v1.36h.48c.34 0 .57-.06.7-.18a.61.61 0 00.21-.5c0-.23-.07-.4-.2-.51-.14-.12-.38-.18-.7-.18h-.49z"
            clipRule="evenodd"
         ></path>
         <path
            fill="#000"
            d="M115.23 40.28l1.42 1.92h-.82l-1.1-1.5-1.11 1.5h-.78l1.42-1.88-1.33-1.82h.81l1.02 1.4 1.03-1.4h.77l-1.33 1.78zm-58.41 6.5h-2.04v3.09h-.67v-3.7h3.37v3.7h-.66v-3.1zm4.44.04h-1.32l-.04.62c-.05.53-.1.96-.2 1.28-.09.33-.2.58-.32.75a.93.93 0 01-.44.35c-.18.07-.36.1-.55.1l-.05-.65c.08 0 .16 0 .25-.05a.7.7 0 00.28-.27c.08-.14.16-.34.24-.6.07-.26.11-.6.14-1.03l.07-1.15h2.6v3.7h-.66v-3.05z"
         ></path>
         <path
            fill="#000"
            fillRule="evenodd"
            d="M63.71 46.17c-.21.06-.4.14-.57.25l.18.52.45-.2c.15-.05.35-.07.58-.07.18 0 .33.03.44.09.12.05.2.13.26.23s.1.23.12.37c.02.15.03.3.03.48-.14-.1-.3-.16-.46-.19a2.04 2.04 0 00-1.04.03c-.17.05-.32.13-.45.23a1.07 1.07 0 00-.4.87c0 .36.1.64.32.84.2.2.48.3.83.3.31 0 .56-.05.75-.16.19-.1.34-.23.45-.37v.47h.62V47.6c0-.48-.1-.85-.32-1.12-.2-.26-.58-.4-1.11-.4-.23 0-.46.04-.68.1zm1.12 3a1 1 0 01-.6.16.72.72 0 01-.5-.16.55.55 0 01-.17-.42c0-.1.02-.2.07-.27a.6.6 0 01.18-.2.8.8 0 01.25-.1c.1-.03.2-.05.3-.05.33 0 .6.08.84.25v.47a1.9 1.9 0 01-.37.32z"
            clipRule="evenodd"
         ></path>
         <path fill="#000" d="M66.47 46.16h3.42v.66H68.5v3.04h-.66v-3.04h-1.37v-.66z"></path>
         <path
            fill="#000"
            fillRule="evenodd"
            d="M73 49.83c.24-.08.46-.2.64-.37l-.2-.51a1.87 1.87 0 01-1.15.39c-.36 0-.67-.1-.9-.3-.25-.2-.38-.47-.4-.82h2.87a2.03 2.03 0 00-.1-1.13 1.63 1.63 0 00-.92-.89 2.12 2.12 0 00-1.5.03 1.75 1.75 0 00-.98 1.02 2.24 2.24 0 00.01 1.56 1.76 1.76 0 001.04 1 2.38 2.38 0 001.58.02zm-1.7-2.86c.21-.18.48-.27.83-.27.33 0 .58.09.77.26.2.17.3.41.31.72h-2.27c.03-.27.16-.52.36-.7zm2.93-.8h.84l1.28 1.78-1.37 1.91h-.82l1.44-1.91-1.37-1.79zm2.29 0h.66v3.7h-.66v-3.7zm3.04 3.7l-1.47-1.92 1.37-1.79h-.82l-1.3 1.79 1.4 1.91h.82zm3.04-.04c.25-.08.46-.2.65-.37l-.2-.51a1.87 1.87 0 01-1.15.39c-.37 0-.67-.1-.91-.3-.24-.2-.37-.47-.4-.82h2.88a2 2 0 00-.1-1.13 1.64 1.64 0 00-.93-.89 2.12 2.12 0 00-1.5.03 1.73 1.73 0 00-.98 1.02c-.08.25-.13.5-.12.76 0 .3.05.56.14.8a1.75 1.75 0 001.03 1 2.39 2.39 0 001.59.02zm-1.7-2.86c.22-.18.49-.27.83-.27.33 0 .59.09.78.26.19.17.29.41.3.72h-2.27c.04-.27.17-.52.37-.7zm5.3-2.05c-.38 0-.6-.22-.63-.65h-.63c0 .17.03.32.08.46.06.14.14.26.24.36.1.1.24.18.4.24.16.06.34.1.54.1a1.32 1.32 0 00.9-.34c.1-.1.19-.22.25-.36.06-.14.09-.3.09-.46h-.63c-.03.43-.23.65-.6.65zm-1.79 1.24v3.7h.66l2.17-2.75v2.76h.66v-3.7h-.66l-2.17 2.75v-2.76h-.66z"
            clipRule="evenodd"
         ></path>
      </svg>
   );
}
