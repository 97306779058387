import { useRef } from "react";
import Picture from "../Picture";
import css from "./styles.module.scss";

const {
    aboutTwoColumns,
    aboutTwoColumns__card,
    aboutTwoColumns__image,
    aboutTwoColumns__title,
    aboutTwoColumns__text
} = css;

const AboutTwoColumns = ({
    data,

    editable = false,
    blockIndex = -1,
    onChange = () => {}

}) => {

    const {
        column1,
        column2
    } = data;

    const defaultTitle1 = useRef(column1.title).current;
    const defaultTitle2 = useRef(column2.title).current;
    const defaultText1 = useRef(column1.text).current;
    const defaultText2 = useRef(column2.text).current;

    return (
        <section className={aboutTwoColumns}>
            <div className={aboutTwoColumns__card}>
                <Picture fileName={"aboutPeople"} alt="" className={aboutTwoColumns__image} />
                <h2
                    className={aboutTwoColumns__title}

                    contentEditable={editable} suppressContentEditableWarning={true}
                    value={column1.title}
                    onChange={e => onChange(blockIndex, "column1.title", e.target.textContent)}
                    onBlur={e => onChange(blockIndex, "column1.title", e.target.textContent)}
                >{defaultTitle1}</h2>
                <p
                    className={aboutTwoColumns__text}

                    contentEditable={editable} suppressContentEditableWarning={true}
                    value={column1.text}
                    onChange={e => onChange(blockIndex, "column1.text", e.target.textContent)}
                    onBlur={e => onChange(blockIndex, "column1.text", e.target.textContent)}
                >{defaultText1}</p>
            </div>
            <div className={aboutTwoColumns__card}>
                <Picture fileName={"aboutLicense"} alt="" className={aboutTwoColumns__image} />
                <h2
                    className={aboutTwoColumns__title}

                    contentEditable={editable} suppressContentEditableWarning={true}
                    value={column2.title}
                    onChange={e => onChange(blockIndex, "column2.title", e.target.textContent)}
                    onBlur={e => onChange(blockIndex, "column2.title", e.target.textContent)}
                >{defaultTitle2}</h2>
                <p
                    className={aboutTwoColumns__text}

                    contentEditable={editable} suppressContentEditableWarning={true}
                    value={column2.text}
                    onChange={e => onChange(blockIndex, "column2.text", e.target.textContent)}
                    onBlur={e => onChange(blockIndex, "column2.text", e.target.textContent)}
                >{defaultText2}</p>
            </div>
        </section>
    );
}

export default AboutTwoColumns;