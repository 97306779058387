function IconFilter(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
         <path
            fill="#010101"
            d="M15.1 16.2a.9.9 0 110 1.8H9.7a.9.9 0 110-1.8h5.4zm2.4-5.1a.9.9 0 110 1.8H7.3a.9.9 0 110-1.8h10.2zM19.9 6a.9.9 0 010 1.8h-15a.9.9 0 010-1.8h15z"
         ></path>
      </svg>
   );
}

export default IconFilter;
