import Button from "../../../components/Button";
import PACardWrapper from "../../../components/PersonalArea/CardWrapper";
import Timer from "../../../components/Timer";
import scss from "./index.module.scss";

const {
   cards__current,
   cards__current_white,
   cards__notify,
   cards__content,
   cards__title,
   cards__text,
   cards__text_mb14,
   cards__title_black,
   cards__text_black,
   cards__pay,
   cards__grid,
} = scss;

function NotifyCard(status, loan) {
   switch (status) {
      case 1: {
         return (
            <PACardWrapper className={cards__notify}>
               {
                  loan.waiting_time - new Date().getTime() > 0 ? (
                     <>
                        <h3 className={cards__title + " " + cards__title_black}>
                           Рассмотрение заявки
                        </h3>
                        <div className={cards__content}>
                           <span className={cards__text + " " + cards__text_black}>
                              Мы сообщим о решении по Вашему займу в течении:
                           </span>
                           <Timer date={loan.waiting_time} />
                        </div>
                     </>
                  ) : (
                     <>
                        <h3 className={cards__title + " " + cards__title_black}>
                           Рассмотрение заявки
                        </h3>
                        <div className={cards__content}>
                           <span className={cards__text + " " + cards__text_black}>
                              Нам требуется еще некоторое время для&nbsp;принятия решения по&nbsp;вашей заявке.<br /><br />
                              Пожалуйста ожидайте решения.
                           </span>
                        </div>
                     </>
                  )
               }
            </PACardWrapper>
         );
      }
      case 2: {
         return (
            <PACardWrapper className={cards__current + " " + cards__current_white}>
               <h3 className={cards__title + " " + cards__title_black}>Ограничения ЦБ РФ</h3>
               <span className={cards__text + " " + cards__text_black + " " + cards__text_mb14}>
                  В связи с ограничениями ЦБ РФ, при Вашей долговой нагрузке мы не можем выдать сумму выше&nbsp;одобренной.
               </span>
               <span className={cards__text + " " + cards__text_black} style={{marginBottom: "auto"}}>
                  Данные ограничения действуют во всех МФО с 1 января 2023 года.
               </span>
            </PACardWrapper>
         );
      }
      case 3: {
         return (
            <PACardWrapper className={cards__current + " " + cards__current_white}>
               <h3 className={cards__title + " " + cards__title_black}>
                  В займе отказано
               </h3>
               <span className={cards__text + " " + cards__text_black}>
                  К сожалению, мы не можем выдать Вам заём в данный момент.
               </span>
               <span className={cards__text + " " + cards__text_black}>
                  Вы сможете оформить новую заявку на заём{" "}
                  <strong>{new Date(loan.denied_to).toLocaleDateString("ru-RU")}</strong>.
               </span>
            </PACardWrapper>
         );
      }
      case 4: {
         return (
            <PACardWrapper className={cards__pay}>
               <h3 className={cards__title + " " + cards__title_black}>
                  Минимальный платёж
               </h3>
               <div className={cards__grid}>
                  <span>
                     Сумма платежа:{" "}
                     <strong>
                        {parseFloat(
                           loan.info.total > 0 ? loan.info.total / 100 : 0
                        ).toLocaleString("ru-RU")}{" "}
                        ₽
                     </strong>
                  </span>
                  <span>
                     Оплатить до:{" "}
                     <strong>
                        {new Date(loan.info.date).toLocaleDateString("ru-RU")}
                     </strong>
                  </span>
               </div>
               <Button
                  href={"/payment-method"}
                  variant={"gray"}
                  disabled={loan.info.total <= 0}
               >
                  {loan.info.total > 0 ? "Внести платёж" : "Платёж оплачен"}
               </Button>
            </PACardWrapper>
         );
      }
      default: {
         return (
            <PACardWrapper className={cards__current + " " + cards__current_white}>
               <h3 className={cards__title + " " + cards__title_black}>Уведомления</h3>
               <span className={cards__text + " " + cards__text_black}>
                  У вас пока что нет новых уведомлений.
               </span>
            </PACardWrapper>
         );
      }
   }
}

export default NotifyCard;
