import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import ContentHead from "../../../components/ContentHead";
import EmptyData from "../../../components/EmptyData";
import Table from "../../../components/Table";
import checkAuth from "../../../helpers/checkAuth";
import api from "../../../helpers/fetch";
import getFIO from "../../../helpers/getFIO";
import TextOverflow from "../../../helpers/text-overflow";
import Card from "../../Components/Card";
import Container from "../../Components/Container";
import InfoLoanHeader from "../../Components/InfoLoanHeader";
import LongNotice from "../../Components/longNotice";

import scss from "./index.module.scss";

const { archive, archive__list } = scss;

function Archive() {
   const params = useParams();
   const [state, setState] = useState({
      id_client: 0,
      id_loan: 0,
      midname: "",
      name: "",
      number: 0,
      rate: 0,
      status: "",
      surname: "",
      total_amount: 0,
   });
   const [documents, setDocuments] = useState([]);
   const [sort, setSort] = useState([]);
   const [pagination, setPagination] = useState({});
   const [paginationPage, setPaginationPage] = useState(1);

   const statusesTransaction = {
      confirmed: "Одобрена",
      processing: "Обрабатывается",
      denied: "Отклонёна",
   };

   const getData = async () => {
      const auth = checkAuth();
      const response = await new api(`manage/loans/${params.id}?archive`, auth).send();
      if (response.status === "success") {
         setState(response?.data?.loan);
         setDocuments(response?.data?.documents);
      }
   };

   useEffect(() => {
      getData();
      getDataTable();
   }, []);

   const toTable = (rows = []) => {
      const tmpArray = [];
      rows.forEach((el, key) => {
         tmpArray.push([
            {
               type: "text",
               value: el.id_loan_transaction,
            },
            {
               type: "date",
               value: el.date_created,
            },
            {
               type: "money",
               value: el.amount,
            },
            {
               type: el.status === "confirmed" ? "success" : "text",
               value: statusesTransaction[el.status],
            },
         ]);
      });
      return tmpArray;
   };

   const getDataTable = async () => {
      const auth = checkAuth();
      let queryParams = getSortQuery("date_created");
      const response = await new api(
         `manage/transactions?id_loan=${params.id}` +
            queryParams +
            "&page=" +
            (paginationPage !== 0 && paginationPage ? paginationPage : 1),
         auth
      ).send();
      if (response.status === "success") {
         setTable(toTable(response.data.data));
         setPagination(response.data.pagination);
      }
   };

   const getSortQuery = (nameField) => {
      if (sort.length === 0) {
         setSort([nameField, "asc"]);
      } else {
         return "&sort_param=" + sort[0] + "&sort_direction=" + sort[1];
      }
   };

   const [table, setTable] = useState([]);

   useEffect(() => {
      if (state?.status !== "denied") getDataTable();
   }, [sort, paginationPage]);

   const onPaginate = (number) => {
      setPaginationPage(number);
   };

   return (
      <div className={archive}>
         <InfoLoanHeader
            params={state?.number}
            rate={state?.rate}
            amount={state?.total_amount / 100}
            initials={getFIO(
               state?.name,
               TextOverflow(state?.surname, 11),
               state?.midname,
               "ser-initials"
            )}
            id={state?.id_client}
         />
         {state?.status !== "denied" && state?.status !== "" ? (
            <>
               {documents.length > 0 ? (
                  <Container>
                     <h2>Документы</h2>
                     <div className={archive__list}>
                        {documents.map(({ name, url }, key) => (
                           <Card
                              type={"document"}
                              title={TextOverflow(name, 55)}
                              link={url}
                              key={key.toString()}
                           />
                        ))}
                     </div>
                  </Container>
               ) : null}
               <Container>
                  <ContentHead title={"Транзакции"} />
                  <Table
                     rows={table}
                     colums={[
                        {
                           paramName: "number",
                           name: "Номер транзакции",
                        },
                        {
                           paramName: "date_created",
                           name: "Дата платежа",
                        },
                        {
                           paramName: "amount",
                           name: "Сумма",
                        },
                        {
                           paramName: "status",
                           name: "Статус",
                        },
                     ]}
                     onSort={(param, direction) => setSort([param, direction])}
                     sortParam={sort[0]}
                     sortDirection={sort[1]}
                     baseColumnsWidth={[null, 260, 260, 200, 200]}
                     blockOnEmpty={
                        <EmptyData
                           title="Нет данных"
                           text="Вероятно, что-то пошло не так."
                        />
                     }
                     pagination={pagination}
                     onPaginate={onPaginate}
                  />
               </Container>
            </>
         ) : null}
      </div>
   );
}

export default Archive;
