import React from "react";

export default function IconPage(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="130" height="130" fill="none" viewBox="0 0 130 130" {...props}>
         <g filter="url(#a)">
            <path
               fill="url(#b)"
               d="M82.47 9.19A4.06 4.06 0 0079.59 8H25.7a5.69 5.69 0 00-5.7 5.69V115.7a5.69 5.69 0 005.69 5.7h77.68a5.7 5.7 0 005.69-5.7V37.47a4.06 4.06 0 00-1.2-2.88L82.47 9.2z"
            ></path>
         </g>
         <g filter="url(#c)">
            <path
               fill="#D2C5DF"
               d="M80.24 9.98c0-.81.23-1.34-.21-1.43.42.03 1.04.18 1.43.34.5.2-.12.63.27 1.01l.04.03 25.4 25.4a4.1 4.1 0 011.19 2.88v.26c0-.94-.68-1.5-1.2-1.5H85.12a4.88 4.88 0 01-4.87-4.88V9.99z"
            ></path>
         </g>
         <g filter="url(#d)">
            <path
               fill="url(#e)"
               d="M81.41 9.54c0-.82-.64-1.14-1.09-1.23.99.06 1.92.48 2.62 1.18l25.4 25.4a4.11 4.11 0 011.19 2.87v.26c0-.94-.68-1.5-1.2-1.5H86.29a4.88 4.88 0 01-4.87-4.88V9.54z"
            ></path>
         </g>
         <path
            fill="url(#f)"
            d="M81.41 9.54c0-.82-.64-1.14-1.09-1.23.99.06 1.92.48 2.62 1.18l25.4 25.4a4.11 4.11 0 011.19 2.87v.26c0-.94-.68-1.5-1.2-1.5H86.29a4.88 4.88 0 01-4.87-4.88V9.54z"
         ></path>
         <path fill="url(#g)" d="M91.23 48.73h-52.5a2.02 2.02 0 000 4.03h52.5a2.02 2.02 0 000-4.03z"></path>
         <path fill="url(#h)" d="M91.23 60.92h-52.5a2.02 2.02 0 000 4.03h52.5a2.02 2.02 0 000-4.03z"></path>
         <path fill="url(#i)" d="M91.23 73.1h-52.5a2.02 2.02 0 000 4.04h52.5a2.02 2.02 0 000-4.03z"></path>
         <path fill="url(#j)" d="M71.2 85.3H38.74a2.02 2.02 0 000 4.03H71.2a2.02 2.02 0 100-4.03z"></path>
         <defs>
            <linearGradient id="b" x1="64.53" x2="64.53" y1="8" y2="121.4" gradientUnits="userSpaceOnUse">
               <stop stopColor="#EBE4F3"></stop>
               <stop offset="1" stopColor="#E4DAF0"></stop>
            </linearGradient>
            <linearGradient id="e" x1="83.44" x2="94.17" y1="35.37" y2="24.64" gradientUnits="userSpaceOnUse">
               <stop stopColor="#D9D3E1"></stop>
               <stop offset="1" stopColor="#CCC7D1"></stop>
            </linearGradient>
            <linearGradient id="f" x1="96.58" x2="93.98" y1="22.55" y2="25.09" gradientUnits="userSpaceOnUse">
               <stop offset="0.12" stopColor="#DBDADC"></stop>
               <stop offset="0.58" stopColor="#CBC7D0" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient id="g" x1="36.72" x2="93.25" y1="50.75" y2="50.75" gradientUnits="userSpaceOnUse">
               <stop stopColor="#9C96A2"></stop>
               <stop offset="1" stopColor="#9A95A1"></stop>
            </linearGradient>
            <linearGradient id="h" x1="36.72" x2="93.25" y1="62.94" y2="62.94" gradientUnits="userSpaceOnUse">
               <stop stopColor="#9C96A2"></stop>
               <stop offset="1" stopColor="#9A95A1"></stop>
            </linearGradient>
            <linearGradient id="i" x1="36.72" x2="93.25" y1="75.12" y2="75.12" gradientUnits="userSpaceOnUse">
               <stop stopColor="#9C96A2"></stop>
               <stop offset="1" stopColor="#9A95A1"></stop>
            </linearGradient>
            <linearGradient id="j" x1="36.72" x2="73.22" y1="87.31" y2="87.31" gradientUnits="userSpaceOnUse">
               <stop stopColor="#9C96A2"></stop>
               <stop offset="1" stopColor="#9A95A1"></stop>
            </linearGradient>
            <filter
               id="a"
               width="90.68"
               height="115.03"
               x="19.59"
               y="6.78"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="1.22" dy="-1.22"></feOffset>
               <feGaussianBlur stdDeviation="1.22"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.807843 0 0 0 0 0.768627 0 0 0 0 0.85098 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_910_5456"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="0.41" dy="-0.41"></feOffset>
               <feGaussianBlur stdDeviation="0.41"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.709804 0 0 0 0 0.690196 0 0 0 0 0.733333 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="effect1_innerShadow_910_5456" result="effect2_innerShadow_910_5456"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dy="-0.41"></feOffset>
               <feGaussianBlur stdDeviation="0.41"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.72549 0 0 0 0 0.65098 0 0 0 0 0.8 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="effect2_innerShadow_910_5456" result="effect3_innerShadow_910_5456"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="-0.61" dy="0.61"></feOffset>
               <feGaussianBlur stdDeviation="0.2"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.952941 0 0 0 0 0.941176 0 0 0 0 0.964706 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="effect3_innerShadow_910_5456" result="effect4_innerShadow_910_5456"></feBlend>
            </filter>
            <filter
               id="c"
               width="32.39"
               height="33.98"
               x="78"
               y="6.52"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_5456" stdDeviation="1.02"></feGaussianBlur>
            </filter>
            <filter
               id="d"
               width="29.62"
               height="30.12"
               x="80.32"
               y="7.9"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="0.41"></feOffset>
               <feGaussianBlur stdDeviation="0.3"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.631373 0 0 0 0 0.611765 0 0 0 0 0.654902 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_910_5456"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dy="-0.41"></feOffset>
               <feGaussianBlur stdDeviation="0.3"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.752941 0 0 0 0 0.67451 0 0 0 0 0.835294 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="effect1_innerShadow_910_5456" result="effect2_innerShadow_910_5456"></feBlend>
            </filter>
         </defs>
      </svg>
   );
}
