import Error from "../pages/Error";
import CheckkAuth from "./Components/CheckAuth";
import Application from "./pages/Application";
import Applications from "./pages/Applications";
import Archive from "./pages/Archive";
import Archives from "./pages/Archives";
import Dashboard from "./pages/Dashboard";
import Loan from "./pages/Loan";
import Loans from "./pages/Loans";
import MainManagePage from "./pages/Main";
import Pages from "./pages/Pages";
import Settings from "./pages/Settings";
import Staff from "./pages/Staff";
import Customers from "./pages/Customers";
import Customer from "./pages/Customer";
import Employee from "./pages/Employee";
import Page from "./pages/Page";
import SettingsPage from "./pages/SettingsPage";
import Support from "./pages/Support";

const manageRouter = [
   {
      path: "/",
      breadcrumb: "",
   },
   {
      path: "/manage",
      element: (
         <CheckkAuth>
            <MainManagePage />
         </CheckkAuth>
      ),
      elementError: <Error />,
      breadcrumb: "Панель управления",
      children: [
         {
            index: true,
            element: <Dashboard />,
         },
         {
            path: "pages",
            breadcrumb: "Страницы",
            element: <Pages />,
         },
         {
            path: "pages/:id",
            breadcrumb: "Страница",
            element: <Page />,
         },
         {
            path: "applications",
            breadcrumb: "Заявки",
            element: <Applications />,
         },
         {
            path: "applications/:id",
            breadcrumb: "Заявка",
            element: <Application />,
         },
         {
            path: "loans",
            breadcrumb: "Займы",
            element: <Loans />,
         },
         {
            path: "loans/:id",
            breadcrumb: "Займ",
            element: <Loan />,
         },
         {
            path: "archive",
            breadcrumb: "Архив",
            element: <Archives />,
         },
         {
            path: "archive/:id",
            breadcrumb: "Архивный займ",
            element: <Archive />,
         },
         {
            path: "customers",
            breadcrumb: "Клиенты",
            element: <Customers />,
         },
         {
            path: "customers/:id",
            breadcrumb: "Клиент",
            element: <Customer />,
         },
         {
            path: "staff",
            breadcrumb: "Сотрудники",
            element: <Staff />,
         },
         {
            path: "staff/:id",
            breadcrumb: "Сотрудник",
            element: <Employee />,
         },
         {
            path: "education",
            breadcrumb: "Обучение",
            element: <></>,
         },
         {
            path: "support",
            breadcrumb: "Поддержка",
            element: <Support />,
         },
         {
            path: "settings",
            breadcrumb: "Настройки",
            element: <Settings />,
            children: [
               {
                  path: ":slug",
                  breadcrumb: " ",
                  element: <SettingsPage />,
               },
            ],
         },
      ],
   },
];

export default manageRouter;
