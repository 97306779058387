import React from "react";

export default function IconGreyScaleCard(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none" viewBox="0 0 48 48" {...props}>
         <path
            fill="gray"
            d="M0 12a6 6 0 016-6h36a6 6 0 016 6v24a6 6 0 01-6 6H6a6 6 0 01-6-6V12zm6-3a3 3 0 00-3 3v3h42v-3a3 3 0 00-3-3H6zm39 12H3v15a3 3 0 003 3h36a3 3 0 003-3V21z"
         ></path>
         <path fill="gray" d="M6 30a3 3 0 013-3h3a3 3 0 013 3v3a3 3 0 01-3 3H9a3 3 0 01-3-3v-3z"></path>
      </svg>
   );
}
