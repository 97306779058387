import IconButton from "../IconButton";
import IconDismiss from "../Icons/IconDismiss";
import scss from "./index.module.scss";

const { wrapper, popup, popup__header, popup__title, popup__main } = scss;

function PopUp({ title, children, onCloase }) {
   return (
      <div className={wrapper}>
         <section className={popup}>
            <div className={popup__header}>
               <span className={popup__title}>{title}</span>
               <IconButton onClick={() => onCloase()}>
                  <IconDismiss />
               </IconButton>
            </div>
            <div className={popup__main}>{children}</div>
         </section>
      </div>
   );
}

export default PopUp;
