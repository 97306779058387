function IconPersonFeedback(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
         <path
            fill="#010101"
            d="M12.2 14a1.8 1.8 0 011.8 1.8v.6c0 2.365-2.232 4.8-6 4.8s-6-2.435-6-4.8v-.6A1.8 1.8 0 013.8 14h8.4zM8 6.2a3.3 3.3 0 110 6.6 3.3 3.3 0 010-6.6zM18.8 2a2.4 2.4 0 012.393 2.22l.007.18v2.4a2.4 2.4 0 01-2.22 2.394l-.18.006h-1.801l-1.438 1.92c-.636.847-1.925.504-2.132-.451l-.02-.133-.009-.136V9.122l-.094-.024a2.404 2.404 0 01-1.676-1.92l-.024-.2L11.6 6.8V4.4a2.4 2.4 0 012.22-2.394L14 2h4.8z"
         ></path>
      </svg>
   );
}

export default IconPersonFeedback;
