function logOut (redirect = null, callback = () => {}) {
    localStorage.removeItem("token");
    localStorage.removeItem("expireIn");
    localStorage.removeItem("role");

    if (redirect !== null) {
        window.location.href = redirect;
    }
    else {
        window.location.reload();
    }

    if (typeof callback === "function") callback();
}

export default logOut;