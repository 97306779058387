import formatPhone from "../../../helpers/formatPhone";
import scss from "./index.module.scss";

const { footer } = scss;

const initialState = window.initialState ? window.initialState : {
   "settings": {
      "about": {
          "phone": 8126288181,
          "email": "support@slon.finance"
      }
   }
};

function PAFooter({ tel, mail }) {
   return (
      <footer className={footer}>
         <span>
            Телефон для связи: <a href={"tel:+7" + initialState.settings.about.phone}>{formatPhone(initialState.settings.about.phone)}</a>
         </span>
         <span>
            Служба поддержки клиентов: <a href={"mailto:" + initialState.settings.about.email}>{initialState.settings.about.email}</a>
         </span>
      </footer>
   );
}

export default PAFooter;
