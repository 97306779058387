import manageRouter from "../manage/router";

import Error from "../pages/Error";
import Home from "../pages/Home";
import Root from "./root";
import Profile from "../pages/Profile";
import PAContainer from "../components/PersonalArea/Container";
import ResetPassword from "../pages/ResetPassword";
import Payment from "../pages/Payment";
import PaymentList from "../components/PaymentList";
import PaymentPageByQR from "../pages/PaymentPageByQR";
import PaymentPageByRequisites from "../pages/PaymentPageByRequisites";
import PaymentPageByBankCard from "../pages/PaymentPageByBankCard";
import Confirmed from "../pages/Confirmed";
import Application from "../pages/Application";
import PaymentUnauthorized from "../components/PaymentUnauthorized";
import AboutLoan from "../pages/AboutLoan";
import Approved from "../pages/Approved";
import VirtualCard from "../pages/VirtualСard";

const router = [
   {
      path: "/",
      element: <Root />,
      errorElement: <Error />,
      children: [
         {
            index: true,
            element: <Home />,
         },
         {
            path: "404",
            element: <Error />,
         },
         {
            path: "oplatit",
            element: <PaymentUnauthorized />,
            children: [
               {
                  path: ":token",
                  element: <PaymentList />,
               },
               {
                  path: ":token/:payment",
                  element: <PaymentList />,
               },
               {
                  path: ":token/qr-code",
                  element: <PaymentPageByQR />,
               },
               {
                  path: ":token/:payment/qr-code",
                  element: <PaymentPageByQR />,
               },
               {
                  path: ":token/requisites",
                  element: <PaymentPageByRequisites />,
               },
               {
                  path: ":token/:payment/requisites",
                  element: <PaymentPageByRequisites />,
               },
               {
                  path: ":token/bank-card",
                  element: <PaymentPageByBankCard />,
               },
               {
                  path: ":token/:payment/bank-card",
                  element: <PaymentPageByBankCard />,
               },
            ],
         },
         {
            path: "oplatit/done",
            element: (
               <Confirmed
                  title="Ваш платёж принят в работу"
                  text="Как только платёж будет обработан, мы обновим данные по займу."
                  buttonText="Вернуться на главную"
                  buttonLink="/"
               />
            ),
         },
         {
            path: "message",
            element: <Confirmed />,
         },
         {
            path: "reset-password",
            element: <ResetPassword />,
         },
         {
            path: "reset-password/:token",
            element: <ResetPassword />,
         },
         {
            path: "profile",
            element: <PAContainer children={<Profile />} />,
         },
         {
            path: "sign",
            element: <PAContainer children={<Approved />} />,
         },
         {
            path: "application",
            element: <PAContainer children={<Application />} />,
         },
         {
            path: "application/done",
            element: <PAContainer children={<Confirmed />} />,
         },
         {
            path: "loan/:idLoan",
            element: <PAContainer children={<AboutLoan />} />,
         },
         {
            path: "loan/",
            element: <PAContainer children={<AboutLoan />} />,
         },
         {
            path: "payment-method",
            element: <PAContainer children={<Payment />} />,
            children: [
               {
                  index: true,
                  element: <PaymentList />,
               },
               {
                  path: ":payment",
                  element: <PaymentList />,
               },
               {
                  path: "qr-code",
                  element: <PaymentPageByQR />,
               },
               {
                  path: ":payment/qr-code",
                  element: <PaymentPageByQR />,
               },
               {
                  path: "requisites",
                  element: <PaymentPageByRequisites />,
               },
               {
                  path: ":payment/requisites",
                  element: <PaymentPageByRequisites />,
               },
               {
                  path: "bank-card",
                  element: <PaymentPageByBankCard />,
               },
               {
                  path: ":payment/bank-card",
                  element: <PaymentPageByBankCard />,
               },
            ],
         },
         {
            path: "payment-method/done",
            element: (
               <PAContainer
                  children={
                     <Confirmed
                        title="Ваш платёж принят в работу"
                        text="Как только платёж будет обработан, мы обновим данные по Вашему займу."
                        buttonText="Вернуться в личный кабинет"
                        buttonLink="/profile"
                     />
                  }
               />
            ),
         },
         {
            path: "virtual-card",
            element: <PAContainer children={<VirtualCard />} />,
         },
      ],
   },
   ...manageRouter,
];

export default router;
