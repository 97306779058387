function IconSBP(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
         <path fill="#010101" d="M2 5.22l2.9 5.2v3.17L2 18.77V5.22zm11.15 3.31l2.73-1.67h5.58l-8.3 5.08V8.53z"></path>
         <path fill="#010101" d="M13.14 5.2l.02 6.87-2.91-1.79V0l2.9 5.2z"></path>
         <path
            fill="#010101"
            d="M21.46 6.85h-5.58L13.14 5.2 10.24 0l11.22 6.85zm-8.3 11.95v-3.34l-2.89-1.75L10.25 24l2.9-5.2z"
         ></path>
         <path
            fill="#010101"
            d="M15.88 17.15L4.9 10.42 2 5.22l19.44 11.92h-5.56zM10.25 24l2.9-5.2 2.73-1.65h5.56L10.24 24z"
         ></path>
         <path fill="#010101" d="M2 18.77l8.27-5.06L7.49 12l-2.58 1.58L2 18.77z"></path>
      </svg>
   );
}

export default IconSBP;
