import React from "react";

export default function IconGem(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="130" height="130" fill="none" viewBox="0 0 130 130" {...props}>
         <path fill="#72ADF1" d="M37.513 45.517l27.422 64.65 30.599-64.65h-58.02z"></path>
         <path fill="url(#paint0_linear_910_5024)" d="M37.513 45.517l27.422 64.65 30.599-64.65h-58.02z"></path>
         <path fill="url(#paint1_linear_910_5024)" d="M37.513 45.517l27.422 64.65 30.599-64.65h-58.02z"></path>
         <path fill="url(#paint2_linear_910_5024)" d="M30.66 20L8.316 50.087h36.688L65 20H30.66z"></path>
         <path fill="url(#paint3_linear_910_5024)" d="M30.66 20L8.316 50.087h36.688L65 20H30.66z"></path>
         <path fill="url(#paint4_linear_910_5024)" d="M30.66 20L8.316 50.087h36.688L65 20H30.66z"></path>
         <path fill="url(#paint5_radial_910_5024)" d="M99.247 20l22.344 30.087H84.898L64.906 20h34.34z"></path>
         <path fill="url(#paint6_linear_910_5024)" d="M99.247 20l22.344 30.087H84.898L64.906 20h34.34z"></path>
         <path fill="url(#paint7_linear_910_5024)" d="M64.96 110.208L8.315 50.087h36.688l19.955 60.121z"></path>
         <path fill="url(#paint8_linear_910_5024)" d="M64.96 110.208L8.315 50.087h36.688l19.955 60.121z"></path>
         <path fill="url(#paint9_radial_910_5024)" d="M64.935 110.167l56.672-60.08H84.914L64.935 110.17v-.004z"></path>
         <path fill="url(#paint10_linear_910_5024)" d="M64.935 110.167l56.672-60.08H84.914L64.935 110.17v-.004z"></path>
         <path fill="url(#paint11_linear_910_5024)" d="M64.935 110.167l56.672-60.08H84.914L64.935 110.17v-.004z"></path>
         <path fill="url(#paint12_linear_910_5024)" d="M45.004 50.087h39.894L64.951 20.069 45.004 50.087z"></path>
         <defs>
            <linearGradient
               id="paint0_linear_910_5024"
               x1="66.523"
               x2="66.523"
               y1="47.946"
               y2="56.071"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#72C8F9"></stop>
               <stop offset="1" stopColor="#73AEF2" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint1_linear_910_5024"
               x1="77.963"
               x2="64.785"
               y1="89.647"
               y2="87.121"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#73D2FF"></stop>
               <stop offset="1" stopColor="#73AEF2" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint2_linear_910_5024"
               x1="45.386"
               x2="29.388"
               y1="48.563"
               y2="27.743"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.33" stopColor="#2E97D8"></stop>
               <stop offset="1" stopColor="#1C82CA"></stop>
            </linearGradient>
            <linearGradient
               id="paint3_linear_910_5024"
               x1="10.091"
               x2="25.581"
               y1="52.882"
               y2="40.946"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#2E8BDB"></stop>
               <stop offset="1" stopColor="#2E8BDB" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint4_linear_910_5024"
               x1="55.128"
               x2="51.61"
               y1="38.289"
               y2="36.035"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#32B3EA"></stop>
               <stop offset="1" stopColor="#32B3EA" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint5_radial_910_5024"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(33.51567 -3.36428 4.54698 45.29796 73.06 38.407)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#20D5FC"></stop>
               <stop offset="1" stopColor="#20BFF7"></stop>
            </radialGradient>
            <linearGradient
               id="paint6_linear_910_5024"
               x1="79.536"
               x2="82.075"
               y1="42.852"
               y2="41.202"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.17" stopColor="#20D0F6"></stop>
               <stop offset="1" stopColor="#20D0F6" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint7_linear_910_5024"
               x1="36.64"
               x2="64.959"
               y1="50.087"
               y2="108.42"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.379" stopColor="#296BCC"></stop>
               <stop offset="1" stopColor="#3E7DE1"></stop>
            </linearGradient>
            <linearGradient
               id="paint8_linear_910_5024"
               x1="36.64"
               x2="36.64"
               y1="50.087"
               y2="51.736"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#2D75D2"></stop>
               <stop offset="1" stopColor="#2D75D2" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint9_radial_910_5024"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(23.25634 -41.89466 21.96574 12.1935 70.013 107.726)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#42A2EC"></stop>
               <stop offset="1" stopColor="#3294E4"></stop>
            </radialGradient>
            <linearGradient
               id="paint10_linear_910_5024"
               x1="71.35"
               x2="73.381"
               y1="88.774"
               y2="89.57"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#368FE1"></stop>
               <stop offset="1" stopColor="#368FE1" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint11_linear_910_5024"
               x1="93.271"
               x2="93.271"
               y1="48.819"
               y2="53.008"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#309EE9"></stop>
               <stop offset="1" stopColor="#309EE9" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint12_linear_910_5024"
               x1="79.918"
               x2="45.004"
               y1="33.581"
               y2="57.956"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#6CE8FE"></stop>
               <stop offset="0.642" stopColor="#68CAEA"></stop>
            </linearGradient>
         </defs>
      </svg>
   );
}
