/**
 * Convert initials to various string formats.
 * @param {string} name - Name.
 * @param {string} surname - surname.
 * @param {string} midname - Midname.
 * @param {string} format - format string.
 * You may use it:
 * - initials
 * - ser-initials
 * - ser-name-mid
 * - name-ser-mid - default
 * @returns formated string.
 */

function getFIO(name, surname, midname, format = "name-ser-mid") {
   switch (format) {
      case "name-ser-mid": {
         return name + " " + surname + " " + (midname ? midname : "");
      }
      case "initials": {
         return name.substring(0, 1) + ". " + midname.substring(0, 1) + ".";
      }
      case "ser-initials": {
         return surname + " " + name.substring(0, 1) + ". " + (midname ? midname.substring(0, 1) + "." : "");
      }
      case "ser-name-mid": {
         return surname + " " + name + " " + (midname ? midname : "");
      }
      default: {
         return "Ошибка преобразования строки...";
      }
   }
}

export default getFIO;
