import { Link } from "react-router-dom";
import css from "./styles.module.scss";

const { button, button_white, button_gray, button_waiting } = css;

const Button = ({
   variant,
   download,
   disabled,
   className,
   children,
   isWaiting,
   href,
   target = null,
   onClick = () => {},
   type = "button",

   style = {},
   contentEditable = false,
   suppressContentEditableWarning = false,
   value = "",
   onChange = () => {}
}) => {
   let classNameTmp = button;

   classNameTmp += variant === "white" ? " " + button_white : "";
   classNameTmp += variant === "gray" ? " " + button_gray : "";

   classNameTmp += className ? " " + className : "";

   classNameTmp += isWaiting ? " " + button_waiting : "";

   if (contentEditable) {
      return (
         <div
            disabled={disabled}
            className={classNameTmp}
            style={style}

            contentEditable={contentEditable}
            suppressContentEditableWarning={suppressContentEditableWarning}
   
            value={value}
            onChange={onChange}
            onBlur={onChange}
         >
            {children}
         </div>
      );
   }

   return href && !disabled ? (
      href.substr(0, 4) === "http" ? (
         <a
            download={download}
            href={href}
            onClick={onClick}
            target={target}
            className={classNameTmp}
            style={style}
         >
            {children}
         </a>
      ) : (
         <Link
            download={download}
            to={href}
            onClick={onClick}
            target={target}
            className={classNameTmp}
            style={style}
         >
            {children}
         </Link>
      )
   ) : (
      <button
         disabled={disabled}
         onClick={isWaiting ? null : onClick}
         type={type}
         className={classNameTmp}
         style={style}
      >
         {children}
      </button>
   );
};

export default Button;
