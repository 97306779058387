import React from "react";

export default function IconElephant(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" fill="none" viewBox="0 0 200 200" {...props}>
         <g clipPath="url(#clip0_910_5040)">
            <g filter="url(#filter0_f_910_5040)">
               <ellipse cx="105" cy="166.5" fill="url(#paint0_radial_910_5040)" rx="97" ry="19.5"></ellipse>
            </g>
            <g filter="url(#filter1_i_910_5040)">
               <path
                  fill="#7F7590"
                  d="M178.445 127.818v34.63a7.578 7.578 0 01-7.575 7.576h-15.152a7.579 7.579 0 01-7.576-7.576v-19.509a15.154 15.154 0 00-14.297-15.127h44.607l-.007.006z"
               ></path>
               <path
                  fill="url(#paint1_linear_910_5040)"
                  d="M178.445 127.818v34.63a7.578 7.578 0 01-7.575 7.576h-15.152a7.579 7.579 0 01-7.576-7.576v-19.509a15.154 15.154 0 00-14.297-15.127h44.607l-.007.006z"
               ></path>
               <path
                  fill="url(#paint2_linear_910_5040)"
                  d="M178.445 127.818v34.63a7.578 7.578 0 01-7.575 7.576h-15.152a7.579 7.579 0 01-7.576-7.576v-19.509a15.154 15.154 0 00-14.297-15.127h44.607l-.007.006z"
               ></path>
            </g>
            <path
               fill="url(#paint3_radial_910_5040)"
               d="M112.063 118.37H93.979a6.06 6.06 0 00-6.061 6.06v39.776a6.061 6.061 0 006.06 6.061h18.085a6.06 6.06 0 006.061-6.061V124.43a6.06 6.06 0 00-6.061-6.06z"
            ></path>
            <path
               fill="url(#paint4_linear_910_5040)"
               d="M112.063 118.37H93.979a6.06 6.06 0 00-6.061 6.06v39.776a6.061 6.061 0 006.06 6.061h18.085a6.06 6.06 0 006.061-6.061V124.43a6.06 6.06 0 00-6.061-6.06z"
            ></path>
            <path
               fill="url(#paint5_linear_910_5040)"
               d="M112.063 118.37H93.979a6.06 6.06 0 00-6.061 6.06v39.776a6.061 6.061 0 006.06 6.061h18.085a6.06 6.06 0 006.061-6.061V124.43a6.06 6.06 0 00-6.061-6.06z"
            ></path>
            <path
               fill="url(#paint6_linear_910_5040)"
               d="M112.063 118.37H93.979a6.06 6.06 0 00-6.061 6.06v39.776a6.061 6.061 0 006.06 6.061h18.085a6.06 6.06 0 006.061-6.061V124.43a6.06 6.06 0 00-6.061-6.06z"
            ></path>
            <path
               fill="#B0ACBC"
               d="M15.245 63.042a31.818 31.818 0 0131.819-31.818h83.09A54.545 54.545 0 01184.7 85.77v76.678a7.579 7.579 0 01-7.576 7.576h-15.151a7.579 7.579 0 01-7.576-7.576v-19.509a15.148 15.148 0 00-15.152-15.151h-27.484a15.144 15.144 0 00-12.516 6.612l-20.503 30.054a12.116 12.116 0 01-10.012 5.285H51.718a6.063 6.063 0 01-6.034-6.627 6.062 6.062 0 011.204-3.094l13.879-18.327a18.183 18.183 0 00-1.213-20.976l-2.884-3.612a15.15 15.15 0 00-11.837-5.697H15.245V63.042z"
            ></path>
            <path
               fill="url(#paint7_linear_910_5040)"
               d="M15.245 63.042a31.818 31.818 0 0131.819-31.818h83.09A54.545 54.545 0 01184.7 85.77v76.678a7.579 7.579 0 01-7.576 7.576h-15.151a7.579 7.579 0 01-7.576-7.576v-19.509a15.148 15.148 0 00-15.152-15.151h-27.484a15.144 15.144 0 00-12.516 6.612l-20.503 30.054a12.116 12.116 0 01-10.012 5.285H51.718a6.063 6.063 0 01-6.034-6.627 6.062 6.062 0 011.204-3.094l13.879-18.327a18.183 18.183 0 00-1.213-20.976l-2.884-3.612a15.15 15.15 0 00-11.837-5.697H15.245V63.042z"
            ></path>
            <path
               fill="url(#paint8_linear_910_5040)"
               d="M15.245 63.042a31.818 31.818 0 0131.819-31.818h83.09A54.545 54.545 0 01184.7 85.77v76.678a7.579 7.579 0 01-7.576 7.576h-15.151a7.579 7.579 0 01-7.576-7.576v-19.509a15.148 15.148 0 00-15.152-15.151h-27.484a15.144 15.144 0 00-12.516 6.612l-20.503 30.054a12.116 12.116 0 01-10.012 5.285H51.718a6.063 6.063 0 01-6.034-6.627 6.062 6.062 0 011.204-3.094l13.879-18.327a18.183 18.183 0 00-1.213-20.976l-2.884-3.612a15.15 15.15 0 00-11.837-5.697H15.245V63.042z"
            ></path>
            <path
               fill="url(#paint9_linear_910_5040)"
               d="M15.245 63.042a31.818 31.818 0 0131.819-31.818h83.09A54.545 54.545 0 01184.7 85.77v76.678a7.579 7.579 0 01-7.576 7.576h-15.151a7.579 7.579 0 01-7.576-7.576v-19.509a15.148 15.148 0 00-15.152-15.151h-27.484a15.144 15.144 0 00-12.516 6.612l-20.503 30.054a12.116 12.116 0 01-10.012 5.285H51.718a6.063 6.063 0 01-6.034-6.627 6.062 6.062 0 011.204-3.094l13.879-18.327a18.183 18.183 0 00-1.213-20.976l-2.884-3.612a15.15 15.15 0 00-11.837-5.697H15.245V63.042z"
            ></path>
            <path
               fill="url(#paint10_radial_910_5040)"
               d="M15.245 63.042a31.818 31.818 0 0131.819-31.818h83.09A54.545 54.545 0 01184.7 85.77v76.678a7.579 7.579 0 01-7.576 7.576h-15.151a7.579 7.579 0 01-7.576-7.576v-19.509a15.148 15.148 0 00-15.152-15.151h-27.484a15.144 15.144 0 00-12.516 6.612l-20.503 30.054a12.116 12.116 0 01-10.012 5.285H51.718a6.063 6.063 0 01-6.034-6.627 6.062 6.062 0 011.204-3.094l13.879-18.327a18.183 18.183 0 00-1.213-20.976l-2.884-3.612a15.15 15.15 0 00-11.837-5.697H15.245V63.042z"
            ></path>
            <path
               fill="url(#paint11_linear_910_5040)"
               d="M15.245 63.042a31.818 31.818 0 0131.819-31.818h83.09A54.545 54.545 0 01184.7 85.77v76.678a7.579 7.579 0 01-7.576 7.576h-15.151a7.579 7.579 0 01-7.576-7.576v-19.509a15.148 15.148 0 00-15.152-15.151h-27.484a15.144 15.144 0 00-12.516 6.612l-20.503 30.054a12.116 12.116 0 01-10.012 5.285H51.718a6.063 6.063 0 01-6.034-6.627 6.062 6.062 0 011.204-3.094l13.879-18.327a18.183 18.183 0 00-1.213-20.976l-2.884-3.612a15.15 15.15 0 00-11.837-5.697H15.245V63.042z"
            ></path>
            <path
               fill="url(#paint12_radial_910_5040)"
               d="M15.245 63.042a31.818 31.818 0 0131.819-31.818h83.09A54.545 54.545 0 01184.7 85.77v76.678a7.579 7.579 0 01-7.576 7.576h-15.151a7.579 7.579 0 01-7.576-7.576v-19.509a15.148 15.148 0 00-15.152-15.151h-27.484a15.144 15.144 0 00-12.516 6.612l-20.503 30.054a12.116 12.116 0 01-10.012 5.285H51.718a6.063 6.063 0 01-6.034-6.627 6.062 6.062 0 011.204-3.094l13.879-18.327a18.183 18.183 0 00-1.213-20.976l-2.884-3.612a15.15 15.15 0 00-11.837-5.697H15.245V63.042z"
            ></path>
            <path
               fill="url(#paint13_radial_910_5040)"
               d="M15.245 63.042a31.818 31.818 0 0131.819-31.818h83.09A54.545 54.545 0 01184.7 85.77v76.678a7.579 7.579 0 01-7.576 7.576h-15.151a7.579 7.579 0 01-7.576-7.576v-19.509a15.148 15.148 0 00-15.152-15.151h-27.484a15.144 15.144 0 00-12.516 6.612l-20.503 30.054a12.116 12.116 0 01-10.012 5.285H51.718a6.063 6.063 0 01-6.034-6.627 6.062 6.062 0 011.204-3.094l13.879-18.327a18.183 18.183 0 00-1.213-20.976l-2.884-3.612a15.15 15.15 0 00-11.837-5.697H15.245V63.042z"
            ></path>
            <path
               fill="url(#paint14_radial_910_5040)"
               d="M15.245 63.042a31.818 31.818 0 0131.819-31.818h83.09A54.545 54.545 0 01184.7 85.77v76.678a7.579 7.579 0 01-7.576 7.576h-15.151a7.579 7.579 0 01-7.576-7.576v-19.509a15.148 15.148 0 00-15.152-15.151h-27.484a15.144 15.144 0 00-12.516 6.612l-20.503 30.054a12.116 12.116 0 01-10.012 5.285H51.718a6.063 6.063 0 01-6.034-6.627 6.062 6.062 0 011.204-3.094l13.879-18.327a18.183 18.183 0 00-1.213-20.976l-2.884-3.612a15.15 15.15 0 00-11.837-5.697H15.245V63.042z"
            ></path>
            <path
               fill="url(#paint15_radial_910_5040)"
               d="M15.245 63.042a31.818 31.818 0 0131.819-31.818h83.09A54.545 54.545 0 01184.7 85.77v76.678a7.579 7.579 0 01-7.576 7.576h-15.151a7.579 7.579 0 01-7.576-7.576v-19.509a15.148 15.148 0 00-15.152-15.151h-27.484a15.144 15.144 0 00-12.516 6.612l-20.503 30.054a12.116 12.116 0 01-10.012 5.285H51.718a6.063 6.063 0 01-6.034-6.627 6.062 6.062 0 011.204-3.094l13.879-18.327a18.183 18.183 0 00-1.213-20.976l-2.884-3.612a15.15 15.15 0 00-11.837-5.697H15.245V63.042z"
            ></path>
            <path
               fill="url(#paint16_radial_910_5040)"
               d="M15.245 63.042a31.818 31.818 0 0131.819-31.818h83.09A54.545 54.545 0 01184.7 85.77v76.678a7.579 7.579 0 01-7.576 7.576h-15.151a7.579 7.579 0 01-7.576-7.576v-19.509a15.148 15.148 0 00-15.152-15.151h-27.484a15.144 15.144 0 00-12.516 6.612l-20.503 30.054a12.116 12.116 0 01-10.012 5.285H51.718a6.063 6.063 0 01-6.034-6.627 6.062 6.062 0 011.204-3.094l13.879-18.327a18.183 18.183 0 00-1.213-20.976l-2.884-3.612a15.15 15.15 0 00-11.837-5.697H15.245V63.042z"
            ></path>
            <path
               fill="url(#paint17_linear_910_5040)"
               d="M15.245 63.042a31.818 31.818 0 0131.819-31.818h83.09A54.545 54.545 0 01184.7 85.77v76.678a7.579 7.579 0 01-7.576 7.576h-15.151a7.579 7.579 0 01-7.576-7.576v-19.509a15.148 15.148 0 00-15.152-15.151h-27.484a15.144 15.144 0 00-12.516 6.612l-20.503 30.054a12.116 12.116 0 01-10.012 5.285H51.718a6.063 6.063 0 01-6.034-6.627 6.062 6.062 0 011.204-3.094l13.879-18.327a18.183 18.183 0 00-1.213-20.976l-2.884-3.612a15.15 15.15 0 00-11.837-5.697H15.245V63.042z"
            ></path>
            <path
               fill="url(#paint18_radial_910_5040)"
               d="M15.245 63.042a31.818 31.818 0 0131.819-31.818h83.09A54.545 54.545 0 01184.7 85.77v76.678a7.579 7.579 0 01-7.576 7.576h-15.151a7.579 7.579 0 01-7.576-7.576v-19.509a15.148 15.148 0 00-15.152-15.151h-27.484a15.144 15.144 0 00-12.516 6.612l-20.503 30.054a12.116 12.116 0 01-10.012 5.285H51.718a6.063 6.063 0 01-6.034-6.627 6.062 6.062 0 011.204-3.094l13.879-18.327a18.183 18.183 0 00-1.213-20.976l-2.884-3.612a15.15 15.15 0 00-11.837-5.697H15.245V63.042z"
            ></path>
            <path
               fill="url(#paint19_radial_910_5040)"
               d="M15.245 63.042a31.818 31.818 0 0131.819-31.818h83.09A54.545 54.545 0 01184.7 85.77v76.678a7.579 7.579 0 01-7.576 7.576h-15.151a7.579 7.579 0 01-7.576-7.576v-19.509a15.148 15.148 0 00-15.152-15.151h-27.484a15.144 15.144 0 00-12.516 6.612l-20.503 30.054a12.116 12.116 0 01-10.012 5.285H51.718a6.063 6.063 0 01-6.034-6.627 6.062 6.062 0 011.204-3.094l13.879-18.327a18.183 18.183 0 00-1.213-20.976l-2.884-3.612a15.15 15.15 0 00-11.837-5.697H15.245V63.042z"
            ></path>
            <path
               fill="url(#paint20_radial_910_5040)"
               d="M15.245 63.042a31.818 31.818 0 0131.819-31.818h83.09A54.545 54.545 0 01184.7 85.77v76.678a7.579 7.579 0 01-7.576 7.576h-15.151a7.579 7.579 0 01-7.576-7.576v-19.509a15.148 15.148 0 00-15.152-15.151h-27.484a15.144 15.144 0 00-12.516 6.612l-20.503 30.054a12.116 12.116 0 01-10.012 5.285H51.718a6.063 6.063 0 01-6.034-6.627 6.062 6.062 0 011.204-3.094l13.879-18.327a18.183 18.183 0 00-1.213-20.976l-2.884-3.612a15.15 15.15 0 00-11.837-5.697H15.245V63.042z"
            ></path>
            <path
               fill="url(#paint21_radial_910_5040)"
               d="M15.245 63.042a31.818 31.818 0 0131.819-31.818h83.09A54.545 54.545 0 01184.7 85.77v76.678a7.579 7.579 0 01-7.576 7.576h-15.151a7.579 7.579 0 01-7.576-7.576v-19.509a15.148 15.148 0 00-15.152-15.151h-27.484a15.144 15.144 0 00-12.516 6.612l-20.503 30.054a12.116 12.116 0 01-10.012 5.285H51.718a6.063 6.063 0 01-6.034-6.627 6.062 6.062 0 011.204-3.094l13.879-18.327a18.183 18.183 0 00-1.213-20.976l-2.884-3.612a15.15 15.15 0 00-11.837-5.697H15.245V63.042z"
            ></path>
            <path
               fill="url(#paint22_radial_910_5040)"
               d="M15.245 63.042a31.818 31.818 0 0131.819-31.818h83.09A54.545 54.545 0 01184.7 85.77v76.678a7.579 7.579 0 01-7.576 7.576h-15.151a7.579 7.579 0 01-7.576-7.576v-19.509a15.148 15.148 0 00-15.152-15.151h-27.484a15.144 15.144 0 00-12.516 6.612l-20.503 30.054a12.116 12.116 0 01-10.012 5.285H51.718a6.063 6.063 0 01-6.034-6.627 6.062 6.062 0 011.204-3.094l13.879-18.327a18.183 18.183 0 00-1.213-20.976l-2.884-3.612a15.15 15.15 0 00-11.837-5.697H15.245V63.042z"
            ></path>
            <path
               fill="url(#paint23_radial_910_5040)"
               d="M15.245 63.042a31.818 31.818 0 0131.819-31.818h83.09A54.545 54.545 0 01184.7 85.77v76.678a7.579 7.579 0 01-7.576 7.576h-15.151a7.579 7.579 0 01-7.576-7.576v-19.509a15.148 15.148 0 00-15.152-15.151h-27.484a15.144 15.144 0 00-12.516 6.612l-20.503 30.054a12.116 12.116 0 01-10.012 5.285H51.718a6.063 6.063 0 01-6.034-6.627 6.062 6.062 0 011.204-3.094l13.879-18.327a18.183 18.183 0 00-1.213-20.976l-2.884-3.612a15.15 15.15 0 00-11.837-5.697H15.245V63.042z"
            ></path>
            <g filter="url(#filter2_f_910_5040)">
               <path
                  fill="url(#paint24_linear_910_5040)"
                  d="M27.597 92.606v18.945l8.709-.951-8.715-17.988.006-.006z"
               ></path>
            </g>
            <path
               fill="url(#paint25_linear_910_5040)"
               d="M45.633 70.666a3.055 3.055 0 00-6.109 0v5.964a3.054 3.054 0 106.11 0v-5.964z"
            ></path>
            <path
               fill="url(#paint26_linear_910_5040)"
               d="M45.633 70.666a3.055 3.055 0 00-6.109 0v5.964a3.054 3.054 0 106.11 0v-5.964z"
            ></path>
            <path
               fill="url(#paint27_linear_910_5040)"
               d="M45.633 70.666a3.055 3.055 0 00-6.109 0v5.964a3.054 3.054 0 106.11 0v-5.964z"
            ></path>
            <path
               fill="url(#paint28_radial_910_5040)"
               d="M45.633 70.666a3.055 3.055 0 00-6.109 0v5.964a3.054 3.054 0 106.11 0v-5.964z"
            ></path>
            <g filter="url(#filter3_f_910_5040)">
               <path
                  fill="#796C8C"
                  d="M63.954 38.121a1.455 1.455 0 011.455-1.455h2.727c27.127 2.243 46.546 19.758 47.818 46.28a5.78 5.78 0 01-5.788 5.781H71.215a7.26 7.26 0 01-7.26-7.26V38.12z"
               ></path>
            </g>
            <g filter="url(#filter4_f_910_5040)">
               <path
                  stroke="url(#paint29_linear_910_5040)"
                  strokeLinecap="square"
                  strokeWidth="11.636"
                  d="M89.052 45.121h40.86c14.146.121 42.424 8.86 42.424 42.8v72.491"
               ></path>
            </g>
            <path
               fill="url(#paint30_linear_910_5040)"
               d="M63.954 32.97a1.515 1.515 0 011.515-1.515h2.849c28.31 2.351 48.576 20.624 49.903 48.303a6.037 6.037 0 01-6.036 6.036H71.53a7.579 7.579 0 01-7.576-7.576V32.97z"
            ></path>
            <path
               fill="url(#paint31_linear_910_5040)"
               d="M63.954 32.97a1.515 1.515 0 011.515-1.515h2.849c28.31 2.351 48.576 20.624 49.903 48.303a6.037 6.037 0 01-6.036 6.036H71.53a7.579 7.579 0 01-7.576-7.576V32.97z"
            ></path>
            <path
               fill="url(#paint32_linear_910_5040)"
               d="M63.954 32.97a1.515 1.515 0 011.515-1.515h2.849c28.31 2.351 48.576 20.624 49.903 48.303a6.037 6.037 0 01-6.036 6.036H71.53a7.579 7.579 0 01-7.576-7.576V32.97z"
            ></path>
            <path
               fill="url(#paint33_linear_910_5040)"
               fillRule="evenodd"
               d="M24.336 99.236a9.09 9.09 0 019.091 9.091v22.728a3.03 3.03 0 003.03 3.03 9.094 9.094 0 019.091 9.091 9.09 9.09 0 01-9.09 9.091h-3.03a18.181 18.181 0 01-18.183-18.182v-25.758a9.09 9.09 0 019.091-9.09z"
               clipRule="evenodd"
            ></path>
            <path
               fill="url(#paint34_linear_910_5040)"
               fillRule="evenodd"
               d="M24.336 99.236a9.09 9.09 0 019.091 9.091v22.728a3.03 3.03 0 003.03 3.03 9.094 9.094 0 019.091 9.091 9.09 9.09 0 01-9.09 9.091h-3.03a18.181 18.181 0 01-18.183-18.182v-25.758a9.09 9.09 0 019.091-9.09z"
               clipRule="evenodd"
            ></path>
            <path
               fill="url(#paint35_radial_910_5040)"
               fillRule="evenodd"
               d="M24.336 99.236a9.09 9.09 0 019.091 9.091v22.728a3.03 3.03 0 003.03 3.03 9.094 9.094 0 019.091 9.091 9.09 9.09 0 01-9.09 9.091h-3.03a18.181 18.181 0 01-18.183-18.182v-25.758a9.09 9.09 0 019.091-9.09z"
               clipRule="evenodd"
            ></path>
            <path
               fill="url(#paint36_radial_910_5040)"
               fillOpacity="0.3"
               fillRule="evenodd"
               d="M24.336 99.236a9.09 9.09 0 019.091 9.091v22.728a3.03 3.03 0 003.03 3.03 9.094 9.094 0 019.091 9.091 9.09 9.09 0 01-9.09 9.091h-3.03a18.181 18.181 0 01-18.183-18.182v-25.758a9.09 9.09 0 019.091-9.09z"
               clipRule="evenodd"
            ></path>
            <path
               fill="url(#paint37_radial_910_5040)"
               fillRule="evenodd"
               d="M24.336 99.236a9.09 9.09 0 019.091 9.091v22.728a3.03 3.03 0 003.03 3.03 9.094 9.094 0 019.091 9.091 9.09 9.09 0 01-9.09 9.091h-3.03a18.181 18.181 0 01-18.183-18.182v-25.758a9.09 9.09 0 019.091-9.09z"
               clipRule="evenodd"
            ></path>
            <path
               fill="url(#paint38_radial_910_5040)"
               fillRule="evenodd"
               d="M24.336 99.236a9.09 9.09 0 019.091 9.091v22.728a3.03 3.03 0 003.03 3.03 9.094 9.094 0 019.091 9.091 9.09 9.09 0 01-9.09 9.091h-3.03a18.181 18.181 0 01-18.183-18.182v-25.758a9.09 9.09 0 019.091-9.09z"
               clipRule="evenodd"
            ></path>
            <path
               fill="url(#paint39_radial_910_5040)"
               fillRule="evenodd"
               d="M24.336 99.236a9.09 9.09 0 019.091 9.091v22.728a3.03 3.03 0 003.03 3.03 9.094 9.094 0 019.091 9.091 9.09 9.09 0 01-9.09 9.091h-3.03a18.181 18.181 0 01-18.183-18.182v-25.758a9.09 9.09 0 019.091-9.09z"
               clipRule="evenodd"
            ></path>
            <path
               fill="url(#paint40_radial_910_5040)"
               fillRule="evenodd"
               d="M24.336 99.236a9.09 9.09 0 019.091 9.091v22.728a3.03 3.03 0 003.03 3.03 9.094 9.094 0 019.091 9.091 9.09 9.09 0 01-9.09 9.091h-3.03a18.181 18.181 0 01-18.183-18.182v-25.758a9.09 9.09 0 019.091-9.09z"
               clipRule="evenodd"
            ></path>
            <g strokeWidth="2.327" filter="url(#filter5_f_910_5040)">
               <path
                  stroke="url(#paint41_linear_910_5040)"
                  d="M53.742 101.115v-6.824a8.842 8.842 0 10-17.685 0v6.824a3.68 3.68 0 003.68 3.673h10.326a3.672 3.672 0 003.679-3.673v0z"
               ></path>
               <path
                  stroke="url(#paint42_linear_910_5040)"
                  d="M53.742 101.115v-6.824a8.842 8.842 0 10-17.685 0v6.824a3.68 3.68 0 003.68 3.673h10.326a3.672 3.672 0 003.679-3.673v0z"
               ></path>
            </g>
            <path
               fill="url(#paint43_linear_910_5040)"
               fillRule="evenodd"
               d="M45.948 93.188a6.06 6.06 0 015.74 6.363c-.46 8.928-4.746 14.855-10 18.352a26.546 26.546 0 01-14.14 4.303 6.063 6.063 0 01-6.06-6.061 6.059 6.059 0 016.06-6.06c1.758 0 4.861-.57 7.425-2.273 2.272-1.515 4.363-4.036 4.606-8.885a6.061 6.061 0 016.363-5.74h.006z"
               clipRule="evenodd"
            ></path>
            <path
               fill="url(#paint44_linear_910_5040)"
               fillRule="evenodd"
               d="M45.948 93.188a6.06 6.06 0 015.74 6.363c-.46 8.928-4.746 14.855-10 18.352a26.546 26.546 0 01-14.14 4.303 6.063 6.063 0 01-6.06-6.061 6.059 6.059 0 016.06-6.06c1.758 0 4.861-.57 7.425-2.273 2.272-1.515 4.363-4.036 4.606-8.885a6.061 6.061 0 016.363-5.74h.006z"
               clipRule="evenodd"
            ></path>
            <path
               fill="url(#paint45_radial_910_5040)"
               fillRule="evenodd"
               d="M45.948 93.188a6.06 6.06 0 015.74 6.363c-.46 8.928-4.746 14.855-10 18.352a26.546 26.546 0 01-14.14 4.303 6.063 6.063 0 01-6.06-6.061 6.059 6.059 0 016.06-6.06c1.758 0 4.861-.57 7.425-2.273 2.272-1.515 4.363-4.036 4.606-8.885a6.061 6.061 0 016.363-5.74h.006z"
               clipRule="evenodd"
            ></path>
            <g filter="url(#filter6_f_910_5040)">
               <path
                  stroke="url(#paint46_linear_910_5040)"
                  strokeLinecap="round"
                  strokeWidth="3.879"
                  d="M73.142 36.545c13.006 1.643 39.322 12.582 40.534 43.188"
               ></path>
            </g>
            <g filter="url(#filter7_f_910_5040)">
               <path
                  stroke="url(#paint47_linear_910_5040)"
                  strokeLinecap="round"
                  strokeWidth="1.939"
                  d="M66.7 34.654v45.08"
               ></path>
            </g>
            <g filter="url(#filter8_f_910_5040)">
               <path
                  stroke="url(#paint48_linear_910_5040)"
                  strokeLinecap="round"
                  strokeOpacity="0.75"
                  strokeWidth="5.818"
                  d="M26.93 118.745v14.394a9.09 9.09 0 009.091 9.091h2.46"
               ></path>
            </g>
            <g filter="url(#filter9_f_910_5040)">
               <path
                  stroke="#EDE7F5"
                  strokeLinecap="round"
                  strokeWidth="3.103"
                  d="M26.93 115.527c5.97 0 15.721-4.606 16.855-14.679"
               ></path>
            </g>
            <path
               fill="#ACAAB5"
               d="M36.967 94.29a7.933 7.933 0 1115.86 0v6.825a2.763 2.763 0 01-2.763 2.764H39.736a2.77 2.77 0 01-2.558-1.705 2.766 2.766 0 01-.211-1.059v-6.824z"
            ></path>
            <path
               fill="url(#paint49_linear_910_5040)"
               d="M36.967 94.29a7.933 7.933 0 1115.86 0v6.825a2.763 2.763 0 01-2.763 2.764H39.736a2.77 2.77 0 01-2.558-1.705 2.766 2.766 0 01-.211-1.059v-6.824z"
            ></path>
            <path
               fill="url(#paint50_linear_910_5040)"
               d="M36.967 94.29a7.933 7.933 0 1115.86 0v6.825a2.763 2.763 0 01-2.763 2.764H39.736a2.77 2.77 0 01-2.558-1.705 2.766 2.766 0 01-.211-1.059v-6.824z"
            ></path>
            <path
               fill="url(#paint51_radial_910_5040)"
               d="M36.967 94.29a7.933 7.933 0 1115.86 0v6.825a2.763 2.763 0 01-2.763 2.764H39.736a2.77 2.77 0 01-2.558-1.705 2.766 2.766 0 01-.211-1.059v-6.824z"
            ></path>
            <path
               fill="url(#paint52_linear_910_5040)"
               d="M36.967 94.29a7.933 7.933 0 1115.86 0v6.825a2.763 2.763 0 01-2.763 2.764H39.736a2.77 2.77 0 01-2.558-1.705 2.766 2.766 0 01-.211-1.059v-6.824z"
            ></path>
            <path
               fill="url(#paint53_linear_910_5040)"
               fillOpacity="0.5"
               d="M36.967 94.29a7.933 7.933 0 1115.86 0v6.825a2.763 2.763 0 01-2.763 2.764H39.736a2.77 2.77 0 01-2.558-1.705 2.766 2.766 0 01-.211-1.059v-6.824z"
            ></path>
            <g filter="url(#filter10_f_910_5040)">
               <path fill="#B8B4C2" d="M45.918 91.945a1.418 1.418 0 012.836 0v8.904h-2.836v-8.904z"></path>
            </g>
            <g filter="url(#filter11_f_910_5040)">
               <path
                  fill="#87747C"
                  d="M43.645 69.873c0-.349-.28-.63-.627-.63a.629.629 0 00-.627.63v6.763c0 .348.28.63.627.63a.629.629 0 00.627-.63v-6.763z"
               ></path>
            </g>
            <g filter="url(#filter12_f_910_5040)">
               <path fill="url(#paint54_linear_910_5040)" d="M103.967 132.764h7.812v32.763h-7.812v-32.763z"></path>
            </g>
            <g filter="url(#filter13_f_910_5040)">
               <path
                  fill="url(#paint55_linear_910_5040)"
                  d="M78.257 131.248l-21.212 31.437h13.449L95.3 126.891l-17.043 4.357z"
               ></path>
            </g>
         </g>
         <defs>
            <filter
               id="filter0_f_910_5040"
               width="258"
               height="103"
               x="-24"
               y="115"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_5040" stdDeviation="16"></feGaussianBlur>
            </filter>
            <filter
               id="filter1_i_910_5040"
               width="45.382"
               height="42.988"
               x="133.845"
               y="127.036"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="0.776" dy="-0.776"></feOffset>
               <feGaussianBlur stdDeviation="1.939"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.631373 0 0 0 0 0.607843 0 0 0 0 0.67451 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_910_5040"></feBlend>
            </filter>
            <filter
               id="filter2_f_910_5040"
               width="24.23"
               height="34.461"
               x="19.833"
               y="84.848"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_5040" stdDeviation="3.879"></feGaussianBlur>
            </filter>
            <filter
               id="filter3_f_910_5040"
               width="70.618"
               height="70.679"
               x="54.645"
               y="27.357"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_5040" stdDeviation="4.655"></feGaussianBlur>
            </filter>
            <filter
               id="filter4_f_910_5040"
               width="125.951"
               height="157.958"
               x="67.718"
               y="23.788"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_5040" stdDeviation="7.758"></feGaussianBlur>
            </filter>
            <filter
               id="filter5_f_910_5040"
               width="26.218"
               height="27.873"
               x="31.791"
               y="81.182"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_5040" stdDeviation="1.552"></feGaussianBlur>
            </filter>
            <filter
               id="filter6_f_910_5040"
               width="56.049"
               height="58.703"
               x="65.385"
               y="28.788"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_5040" stdDeviation="2.909"></feGaussianBlur>
            </filter>
            <filter
               id="filter7_f_910_5040"
               width="9.697"
               height="54.776"
               x="61.851"
               y="29.806"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_5040" stdDeviation="1.939"></feGaussianBlur>
            </filter>
            <filter
               id="filter8_f_910_5040"
               width="29.782"
               height="41.715"
               x="17.815"
               y="109.63"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_5040" stdDeviation="3.103"></feGaussianBlur>
            </filter>
            <filter
               id="filter9_f_910_5040"
               width="29.267"
               height="27.091"
               x="20.724"
               y="94.642"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_5040" stdDeviation="2.327"></feGaussianBlur>
            </filter>
            <filter
               id="filter10_f_910_5040"
               width="12.146"
               height="19.63"
               x="41.263"
               y="85.873"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_5040" stdDeviation="2.327"></feGaussianBlur>
            </filter>
            <filter
               id="filter11_f_910_5040"
               width="9.012"
               height="15.782"
               x="38.512"
               y="65.363"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_5040" stdDeviation="1.939"></feGaussianBlur>
            </filter>
            <filter
               id="filter12_f_910_5040"
               width="20.224"
               height="45.176"
               x="97.76"
               y="126.558"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_5040" stdDeviation="3.103"></feGaussianBlur>
            </filter>
            <filter
               id="filter13_f_910_5040"
               width="53.77"
               height="51.309"
               x="49.288"
               y="119.133"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_5040" stdDeviation="3.879"></feGaussianBlur>
            </filter>
            <radialGradient
               id="paint0_radial_910_5040"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(0 19.5 -97 0 105 166.5)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.005" stopColor="#B1AEBD"></stop>
               <stop offset="1" stopColor="#D9D9D9" stopOpacity="0"></stop>
            </radialGradient>
            <linearGradient
               id="paint1_linear_910_5040"
               x1="157.591"
               x2="157.591"
               y1="174.951"
               y2="158.285"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#7249A0"></stop>
               <stop offset="0.659" stopColor="#7F56AE" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint2_linear_910_5040"
               x1="156.148"
               x2="156.148"
               y1="133.327"
               y2="151.891"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#685A7B"></stop>
               <stop offset="1" stopColor="#786A8C" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint3_radial_910_5040"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(68.927 -67.514 151.514) scale(44.2469 51.1053)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#9F99AC"></stop>
               <stop offset="1" stopColor="#685A7C"></stop>
            </radialGradient>
            <linearGradient
               id="paint4_linear_910_5040"
               x1="103.997"
               x2="103.997"
               y1="177.079"
               y2="164.261"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#7249A0"></stop>
               <stop offset="1" stopColor="#7F56AE" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint5_linear_910_5040"
               x1="115.566"
               x2="107.803"
               y1="123.479"
               y2="144.315"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.153" stopColor="#6B5B7F"></stop>
               <stop offset="1" stopColor="#6B5B7F" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint6_linear_910_5040"
               x1="84.13"
               x2="95.494"
               y1="170.261"
               y2="170.261"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#BCBAC3"></stop>
               <stop offset="1" stopColor="#BCBAC3" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint7_linear_910_5040"
               x1="193.221"
               x2="170.494"
               y1="128.739"
               y2="128.739"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#ACADAF"></stop>
               <stop offset="1" stopColor="#ACADAF" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint8_linear_910_5040"
               x1="137.536"
               x2="137.536"
               y1="26.085"
               y2="49"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#ACADAF"></stop>
               <stop offset="1" stopColor="#ACADAF" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint9_linear_910_5040"
               x1="159.506"
               x2="150.415"
               y1="34.8"
               y2="53.17"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#ACADAF"></stop>
               <stop offset="1" stopColor="#ACADAF" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint10_radial_910_5040"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-24.24241 -12.09739 8.823 -17.68073 71.627 43.321)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#ACADAF"></stop>
               <stop offset="1" stopColor="#ACADAF" stopOpacity="0"></stop>
            </radialGradient>
            <linearGradient
               id="paint11_linear_910_5040"
               x1="7.991"
               x2="22.009"
               y1="73.812"
               y2="73.812"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#CBCCCF"></stop>
               <stop offset="1" stopColor="#CBCCCF" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint12_radial_910_5040"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(16.2879 14.58333 -18.74717 20.93843 13.864 42.182)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#CBCCCF"></stop>
               <stop offset="0.81" stopColor="#CBCCCF" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint13_radial_910_5040"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(15.3409 -21.4962 12.8762 9.18918 40.379 124)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#7F56AE"></stop>
               <stop offset="1" stopColor="#7F56AE" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint14_radial_910_5040"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-36.36346 28.32653 -24.5227 -31.48038 80.336 72.303)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#918B9E"></stop>
               <stop offset="1" stopColor="#918B9E" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint15_radial_910_5040"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(-62.549 119.556 20.929) scale(32.8674 22.415)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#918B9E"></stop>
               <stop offset="1" stopColor="#918B9E" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint16_radial_910_5040"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(-36.802 208.994 -3.878) scale(39.7372 27.1001)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#918B9E"></stop>
               <stop offset="1" stopColor="#918B9E" stopOpacity="0"></stop>
            </radialGradient>
            <linearGradient
               id="paint17_linear_910_5040"
               x1="105.457"
               x2="105.457"
               y1="177.079"
               y2="153.976"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#7249A0"></stop>
               <stop offset="0.574" stopColor="#7F56AE" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint18_radial_910_5040"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-24.62141 -34.2328 46.1204 -33.1714 111.399 170.019)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#7249A0"></stop>
               <stop offset="1" stopColor="#7F56AE" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint19_radial_910_5040"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(0 -33.3333 38.6364 0 128.07 140.339)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#918B9E"></stop>
               <stop offset="1" stopColor="#918B9E" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint20_radial_910_5040"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(-142.079 76.337 62.81) scale(20.6474 54.3406)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.338" stopColor="#918B9E"></stop>
               <stop offset="1" stopColor="#918B9E" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint21_radial_910_5040"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-26.57961 -26.89413 17.99713 -17.78666 126.554 144.885)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.12" stopColor="#7249A0"></stop>
               <stop offset="0.886" stopColor="#7F56AE" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint22_radial_910_5040"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(0 -17.2348 27.0833 0 129.773 137.115)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.12" stopColor="#7249A0"></stop>
               <stop offset="1" stopColor="#7F56AE" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint23_radial_910_5040"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(11.9318 -7.8598 5.49295 8.33873 39.809 166)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#C9C7D0"></stop>
               <stop offset="0.781" stopColor="#C9C7D0" stopOpacity="0"></stop>
            </radialGradient>
            <linearGradient
               id="paint24_linear_910_5040"
               x1="30.53"
               x2="42.342"
               y1="109.842"
               y2="101.424"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#AEACB7"></stop>
               <stop offset="1" stopColor="#C0B8D0" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint25_linear_910_5040"
               x1="45.633"
               x2="42.1"
               y1="73.648"
               y2="73.648"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.225" stopColor="#594C4F"></stop>
               <stop offset="1" stopColor="#392833"></stop>
            </linearGradient>
            <linearGradient
               id="paint26_linear_910_5040"
               x1="46.591"
               x2="46.591"
               y1="79.86"
               y2="74.188"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#552D52"></stop>
               <stop offset="1" stopColor="#552D52" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint27_linear_910_5040"
               x1="37.506"
               x2="41.712"
               y1="76.491"
               y2="76.491"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#594C4F"></stop>
               <stop offset="1" stopColor="#594C4F" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint28_radial_910_5040"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-2.35606 -6.58167 6.4563 -2.31118 43.773 74.37)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.814" stopColor="#594C4F" stopOpacity="0"></stop>
               <stop offset="1" stopColor="#594C4F"></stop>
            </radialGradient>
            <linearGradient
               id="paint29_linear_910_5040"
               x1="162.536"
               x2="65.948"
               y1="172.533"
               y2="8.139"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#CDCCD5"></stop>
               <stop offset="1" stopColor="#CDCCD5" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint30_linear_910_5040"
               x1="91.088"
               x2="91.088"
               y1="36.358"
               y2="85.794"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#E5E0EA"></stop>
               <stop offset="1" stopColor="#DACEE6"></stop>
            </linearGradient>
            <linearGradient
               id="paint31_linear_910_5040"
               x1="91.088"
               x2="91.088"
               y1="116.097"
               y2="77.079"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#BA9CDC"></stop>
               <stop offset="1" stopColor="#BA9CDC" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint32_linear_910_5040"
               x1="61.306"
               x2="66.088"
               y1="66.285"
               y2="66.285"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#B3AEBB"></stop>
               <stop offset="1" stopColor="#B3AEBB" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint33_linear_910_5040"
               x1="35.451"
               x2="35.451"
               y1="152.267"
               y2="100.188"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#A79FB8"></stop>
               <stop offset="1" stopColor="#B0ACBC"></stop>
            </linearGradient>
            <linearGradient
               id="paint34_linear_910_5040"
               x1="9.694"
               x2="21.815"
               y1="115.509"
               y2="115.509"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#CBCCCF"></stop>
               <stop offset="1" stopColor="#CBCCCF" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint35_radial_910_5040"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(-98.412 91.189 69.921) scale(27.1864 26.3456)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#7F56AE"></stop>
               <stop offset="1" stopColor="#7F56AE" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint36_radial_910_5040"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-5.11364 3.97727 -4.53309 -5.82825 27.785 119.315)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#7F56AE"></stop>
               <stop offset="1" stopColor="#7F56AE" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint37_radial_910_5040"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(150.866 2.53 69.7) scale(13.2264 14.7533)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#85828E"></stop>
               <stop offset="0.959" stopColor="#85828E" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint38_radial_910_5040"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-10.6061 0 0 -11.8304 48.336 143.176)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#85828E"></stop>
               <stop offset="1" stopColor="#85828E" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint39_radial_910_5040"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-14.96212 10.03783 -6.22032 -9.27184 38.678 116.851)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#85828E"></stop>
               <stop offset="1" stopColor="#85828E" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint40_radial_910_5040"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(-157.557 30.21 53.945) scale(18.8521 6.26376)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.346" stopColor="#6E6380"></stop>
               <stop offset="0.983" stopColor="#6E6380" stopOpacity="0"></stop>
            </radialGradient>
            <linearGradient
               id="paint41_linear_910_5040"
               x1="55.621"
               x2="44.9"
               y1="89.485"
               y2="94.6"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#C7C1D7"></stop>
               <stop offset="1" stopColor="#B9B4C5" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint42_linear_910_5040"
               x1="34.603"
               x2="39.433"
               y1="91.57"
               y2="93.933"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#C7C1D7"></stop>
               <stop offset="1" stopColor="#B9B4C5" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint43_linear_910_5040"
               x1="34.221"
               x2="42.27"
               y1="108.806"
               y2="121.491"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#D5D0DB"></stop>
               <stop offset="1" stopColor="#CABADB"></stop>
            </linearGradient>
            <linearGradient
               id="paint44_linear_910_5040"
               x1="20.682"
               x2="24.045"
               y1="118.133"
               y2="118.133"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#E8E4ED"></stop>
               <stop offset="1" stopColor="#E8E4ED" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint45_radial_910_5040"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(54.767 -85.857 86.257) scale(16.5787 25.7444)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.717" stopColor="#B9A9CB" stopOpacity="0"></stop>
               <stop offset="0.962" stopColor="#B9A9CB"></stop>
            </radialGradient>
            <linearGradient
               id="paint46_linear_910_5040"
               x1="116.512"
               x2="71.627"
               y1="82.194"
               y2="34.279"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#F9F5FF"></stop>
               <stop offset="1" stopColor="#F9F5FF" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient id="paint47_linear_910_5040" x1="0" x2="0" y1="0" y2="0" gradientUnits="userSpaceOnUse">
               <stop stopColor="#F9F5FF" stopOpacity="0"></stop>
               <stop offset="1" stopColor="#F9F5FF"></stop>
            </linearGradient>
            <linearGradient
               id="paint48_linear_910_5040"
               x1="34.112"
               x2="27.833"
               y1="143.866"
               y2="121.86"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#CDCCD5"></stop>
               <stop offset="1" stopColor="#CDCCD5" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint49_linear_910_5040"
               x1="44.9"
               x2="44.9"
               y1="103.879"
               y2="101.321"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#887D99"></stop>
               <stop offset="1" stopColor="#887D99" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint50_linear_910_5040"
               x1="54.773"
               x2="47.385"
               y1="103.879"
               y2="103.879"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#8A8696"></stop>
               <stop offset="1" stopColor="#928E9E" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint51_radial_910_5040"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(2.2017 -11.88438 9.58497 1.7757 44.9 98.715)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.713" stopColor="#A9A7B3" stopOpacity="0"></stop>
               <stop offset="1" stopColor="#A9A7B3"></stop>
            </radialGradient>
            <linearGradient
               id="paint52_linear_910_5040"
               x1="35.452"
               x2="42.27"
               y1="103.218"
               y2="103.218"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#BAB9C2"></stop>
               <stop offset="1" stopColor="#ACABB3" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint53_linear_910_5040"
               x1="44.9"
               x2="44.9"
               y1="85.6"
               y2="92.418"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#9A97A7"></stop>
               <stop offset="1" stopColor="#A9A7B2" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint54_linear_910_5040"
               x1="111.779"
               x2="111.779"
               y1="165.527"
               y2="130.297"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#A99FBA"></stop>
               <stop offset="1" stopColor="#A99FBA" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint55_linear_910_5040"
               x1="64.809"
               x2="89.809"
               y1="162.685"
               y2="124.236"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#C0B8D0"></stop>
               <stop offset="1" stopColor="#C0B8D0" stopOpacity="0"></stop>
            </linearGradient>
            <clipPath id="clip0_910_5040">
               <path fill="#fff" d="M0 0H200V200H0z"></path>
            </clipPath>
         </defs>
      </svg>
   );
}
