import { useRef } from "react";
import IconCalendar from "../Icons/Calendar";
import IconChart from "../Icons/Chart";
import IconCreditCard from "../Icons/CreditCard";
import IconHands from "../Icons/Hands";
import IconLock from "../Icons/Lock";
import IconWatch from "../Icons/Watch";

import css from "./styles.module.scss";

const {
   aboutAdvantages,
   aboutAdvantages__title,
   aboutAdvantages__items,
   aboutAdvantages__item,
   aboutAdvantages__icon,
   aboutAdvantages__text,
} = css;

const AboutAdvantages = ({
   data,
   className,
   id,

   editable = false,
   blockIndex = -1,
   onChange = () => {}
}) => {
   const { title, items } = data;

   const icons = [
      IconWatch,
      IconCalendar,
      IconHands,
      IconLock,
      IconCreditCard,
      IconChart,
   ];

   const defaultHeader = useRef(title).current;
   const defaultItems = [];

   items.forEach(item => {
      defaultItems.push({
         Item: () => useRef(item).current
      });
   });

   return (
      <section id={id} className={aboutAdvantages + " " + className}>
         <h2
            className={aboutAdvantages__title}

            contentEditable={editable} suppressContentEditableWarning={true}
            value={title}
            onChange={e => onChange(blockIndex, "title", e.target.textContent)}
            onBlur={e => onChange(blockIndex, "title", e.target.textContent)}
         >{defaultHeader}</h2>
         <div className={aboutAdvantages__items}>
            {items.map((item, i) => (
               <div className={aboutAdvantages__item} key={i}>
                  {(() => {
                     const IconTmp = icons[i];
                     return <IconTmp className={aboutAdvantages__icon} />;
                  })()}
                  <p
                     className={aboutAdvantages__text}

                     contentEditable={editable} suppressContentEditableWarning={true}
                     value={item}
                     onChange={e => onChange(blockIndex, "items."+i, e.target.textContent)}
                     onBlur={e => onChange(blockIndex, "items."+i, e.target.textContent)}
                  >{defaultItems[i].Item()}</p>
               </div>
            ))}
         </div>
      </section>
   );
};

export default AboutAdvantages;
