import { Link } from "react-router-dom";
import IconDropDownArrow from "../../Icons/DropDownArrow";
import scss from "./index.module.scss";

const { list, list__item, list__title, list__text, list__time, container, icon } = scss;

// const data = {
//    data: [
//       {
//          link: null,
//          title: "xuy",
//          dateTo: new Date(),
//       },
//       {
//          link: null,
//          title: "xuy",
//          dateTo: new Date(),
//       },
//       {
//          link: null,
//          title: "xuy",
//          dateTo: new Date(),
//       },
//       {
//          link: null,
//          title: "xuy",
//          dateTo: new Date(),
//       },
//    ],
// };

function ServiceList(data) {
   if (data?.data[0]?.title?.length > 0) {
      return (
         <div className={list}>
            {data.data.map(({ link, title, timestamp }, key) => (
               <Link to={link} className={list__item} key={key}>
                  <article className={container}>
                     <h3 className={list__title}>{title}</h3>
                     <time className={list__time}>
                        Действует до: {new Date(timestamp).toLocaleDateString("ru-RU")}
                     </time>
                  </article>
                  <IconDropDownArrow className={icon} />
               </Link>
            ))}
         </div>
      );
   } else {
      return (
         <div className={list}>
            <Link to={"/dopolnitelnie-uslugi"} className={list__item}>
               <article className={container}>
                  <p className={list__text}>
                     Нажмите, чтобы узнать больше о наших услугах
                  </p>
               </article>
            </Link>
         </div>
      );
   }
}

export default ServiceList;
