import React from "react";

export default function IconOk(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="none" viewBox="0 0 64 64" {...props}>
         <g filter="url(#a)">
            <path
               fill="#FFC03A"
               d="M30.31 10.21a4.69 4.69 0 019.04-2.48l1.95 7.1V12.8a4.66 4.66 0 019.31 0v19.08l-.01.39v3.87c0 12.43-5.08 17.9-10.08 21.1-4 2.54-11.72 3.76-17.81.54a19.37 19.37 0 01-8.85-9.02 5.1 5.1 0 019.25-4.3c1.33 2.85 4.4 4.97 7.34 4.97a7.78 7.78 0 100-15.57 7.6 7.6 0 00-7.19 4.53 5.1 5.1 0 11-9.39-3.97 17.56 17.56 0 0111.26-10L17.78 12.5a5.08 5.08 0 018.65-5.33l5.5 8.94-1.62-5.89z"
            ></path>
            <path
               fill="url(#b)"
               d="M30.31 10.21a4.69 4.69 0 019.04-2.48l1.95 7.1V12.8a4.66 4.66 0 019.31 0v19.08l-.01.39v3.87c0 12.43-5.08 17.9-10.08 21.1-4 2.54-11.72 3.76-17.81.54a19.37 19.37 0 01-8.85-9.02 5.1 5.1 0 019.25-4.3c1.33 2.85 4.4 4.97 7.34 4.97a7.78 7.78 0 100-15.57 7.6 7.6 0 00-7.19 4.53 5.1 5.1 0 11-9.39-3.97 17.56 17.56 0 0111.26-10L17.78 12.5a5.08 5.08 0 018.65-5.33l5.5 8.94-1.62-5.89z"
            ></path>
            <path
               fill="url(#c)"
               d="M30.31 10.21a4.69 4.69 0 019.04-2.48l1.95 7.1V12.8a4.66 4.66 0 019.31 0v19.08l-.01.39v3.87c0 12.43-5.08 17.9-10.08 21.1-4 2.54-11.72 3.76-17.81.54a19.37 19.37 0 01-8.85-9.02 5.1 5.1 0 019.25-4.3c1.33 2.85 4.4 4.97 7.34 4.97a7.78 7.78 0 100-15.57 7.6 7.6 0 00-7.19 4.53 5.1 5.1 0 11-9.39-3.97 17.56 17.56 0 0111.26-10L17.78 12.5a5.08 5.08 0 018.65-5.33l5.5 8.94-1.62-5.89z"
            ></path>
            <path
               fill="url(#d)"
               d="M30.31 10.21a4.69 4.69 0 019.04-2.48l1.95 7.1V12.8a4.66 4.66 0 019.31 0v19.08l-.01.39v3.87c0 12.43-5.08 17.9-10.08 21.1-4 2.54-11.72 3.76-17.81.54a19.37 19.37 0 01-8.85-9.02 5.1 5.1 0 019.25-4.3c1.33 2.85 4.4 4.97 7.34 4.97a7.78 7.78 0 100-15.57 7.6 7.6 0 00-7.19 4.53 5.1 5.1 0 11-9.39-3.97 17.56 17.56 0 0111.26-10L17.78 12.5a5.08 5.08 0 018.65-5.33l5.5 8.94-1.62-5.89z"
            ></path>
            <path
               fill="url(#e)"
               d="M30.31 10.21a4.69 4.69 0 019.04-2.48l1.95 7.1V12.8a4.66 4.66 0 019.31 0v19.08l-.01.39v3.87c0 12.43-5.08 17.9-10.08 21.1-4 2.54-11.72 3.76-17.81.54a19.37 19.37 0 01-8.85-9.02 5.1 5.1 0 019.25-4.3c1.33 2.85 4.4 4.97 7.34 4.97a7.78 7.78 0 100-15.57 7.6 7.6 0 00-7.19 4.53 5.1 5.1 0 11-9.39-3.97 17.56 17.56 0 0111.26-10L17.78 12.5a5.08 5.08 0 018.65-5.33l5.5 8.94-1.62-5.89z"
            ></path>
         </g>
         <path fill="url(#f)" d="M43.87 24.08h6.74V12.7a4.66 4.66 0 10-9.3 0v2.02l2.56 9.36z"></path>
         <path fill="url(#g)" d="M43.87 24.08h6.74V12.7a4.66 4.66 0 10-9.3 0v2.02l2.56 9.36z"></path>
         <path fill="url(#h)" d="M43.87 24.08h6.74V12.7a4.66 4.66 0 10-9.3 0v2.02l2.56 9.36z"></path>
         <path fill="url(#i)" d="M43.87 24.08h6.74V12.7a4.66 4.66 0 10-9.3 0v2.02l2.56 9.36z"></path>
         <path fill="url(#j)" d="M43.87 24.08h6.74V12.7a4.66 4.66 0 10-9.3 0v2.02l2.56 9.36z"></path>
         <path
            fill="url(#k)"
            d="M30.31 10.21a4.69 4.69 0 019.04-2.48l4.43 16.35h-7.3a4.19 4.19 0 00-.53-1.44l-4.02-6.54-1.62-5.89z"
         ></path>
         <path
            fill="url(#l)"
            d="M30.31 10.21a4.69 4.69 0 019.04-2.48l4.43 16.35h-7.3a4.19 4.19 0 00-.53-1.44l-4.02-6.54-1.62-5.89z"
         ></path>
         <path
            fill="url(#m)"
            d="M30.31 10.21a4.69 4.69 0 019.04-2.48l4.43 16.35h-7.3a4.19 4.19 0 00-.53-1.44l-4.02-6.54-1.62-5.89z"
         ></path>
         <path
            fill="url(#n)"
            d="M30.31 10.21a4.69 4.69 0 019.04-2.48l4.43 16.35h-7.3a4.19 4.19 0 00-.53-1.44l-4.02-6.54-1.62-5.89z"
         ></path>
         <path
            fill="url(#o)"
            d="M19.44 5.48a5.08 5.08 0 00-1.66 6.99l7.35 11.94c1.7-.5 4.28-.32 6.11-.32h7.04c-2.25-1.15-2.66-2.1-3.07-2.77L26.43 7.14a5.08 5.08 0 00-6.99-1.66z"
         ></path>
         <path
            fill="url(#p)"
            d="M19.44 5.48a5.08 5.08 0 00-1.66 6.99l7.35 11.94c1.7-.5 4.28-.32 6.11-.32h7.04c-2.25-1.15-2.66-2.1-3.07-2.77L26.43 7.14a5.08 5.08 0 00-6.99-1.66z"
         ></path>
         <path
            fill="url(#q)"
            d="M19.44 5.48a5.08 5.08 0 00-1.66 6.99l7.35 11.94c1.7-.5 4.28-.32 6.11-.32h7.04c-2.25-1.15-2.66-2.1-3.07-2.77L26.43 7.14a5.08 5.08 0 00-6.99-1.66z"
         ></path>
         <path
            fill="url(#r)"
            d="M19.44 5.48a5.08 5.08 0 00-1.66 6.99l7.35 11.94c1.7-.5 4.28-.32 6.11-.32h7.04c-2.25-1.15-2.66-2.1-3.07-2.77L26.43 7.14a5.08 5.08 0 00-6.99-1.66z"
         ></path>
         <g filter="url(#s)">
            <path
               stroke="url(#t)"
               strokeLinecap="round"
               strokeWidth="3"
               d="M19.76 44.98c1.6 2.95 5.21 7.09 10.38 7.68"
            ></path>
         </g>
         <g filter="url(#u)">
            <path
               stroke="url(#v)"
               strokeLinecap="round"
               strokeWidth="4"
               d="M34.22 24.91c5.09 1.08 14.6 6.84 11.91 21.24"
            ></path>
         </g>
         <g filter="url(#w)">
            <path stroke="url(#x)" strokeLinecap="round" strokeWidth="3" d="M22.85 7.22l12.29 19.76"></path>
         </g>
         <g filter="url(#y)">
            <path stroke="url(#z)" strokeLinecap="round" strokeWidth="3" d="M36.27 7.88l5.87 21.37"></path>
         </g>
         <g filter="url(#A)">
            <path stroke="url(#B)" strokeLinecap="round" strokeWidth="2" d="M46.77 13.74v21.09"></path>
         </g>
         <defs>
            <linearGradient id="f" x1="40.77" x2="46.79" y1="18.82" y2="18.82" gradientUnits="userSpaceOnUse">
               <stop stopColor="#DB8E1E"></stop>
               <stop offset="1" stopColor="#DB8E1E" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient id="g" x1="41.74" x2="43.66" y1="19.57" y2="19.07" gradientUnits="userSpaceOnUse">
               <stop stopColor="#E37917"></stop>
               <stop offset="1" stopColor="#E37917" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient id="i" x1="51.63" x2="47.85" y1="20.65" y2="20.65" gradientUnits="userSpaceOnUse">
               <stop stopColor="#FFDE3F"></stop>
               <stop offset="1" stopColor="#FFDE3F" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient id="j" x1="50.94" x2="49.47" y1="21.86" y2="21.86" gradientUnits="userSpaceOnUse">
               <stop stopColor="#FAC54C"></stop>
               <stop offset="1" stopColor="#FAC54C" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient id="k" x1="31.34" x2="37.01" y1="19.25" y2="17.44" gradientUnits="userSpaceOnUse">
               <stop stopColor="#D58430"></stop>
               <stop offset="1" stopColor="#D58430" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient id="l" x1="32.7" x2="34.5" y1="18.57" y2="17.81" gradientUnits="userSpaceOnUse">
               <stop stopColor="#E37917"></stop>
               <stop offset="1" stopColor="#E37917" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient id="n" x1="42.84" x2="38.96" y1="16.62" y2="18.12" gradientUnits="userSpaceOnUse">
               <stop stopColor="#FFE54D"></stop>
               <stop offset="1" stopColor="#FFE54D" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient id="o" x1="21.96" x2="28.62" y1="20.9" y2="17.04" gradientUnits="userSpaceOnUse">
               <stop stopColor="#D58441"></stop>
               <stop offset="1" stopColor="#EFA230" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient id="p" x1="32.65" x2="29.59" y1="15" y2="17.2" gradientUnits="userSpaceOnUse">
               <stop stopColor="#FFE946"></stop>
               <stop offset="1" stopColor="#FFE946" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient id="q" x1="38.28" x2="34.42" y1="24.3" y2="23.09" gradientUnits="userSpaceOnUse">
               <stop offset="0.61" stopColor="#FFD747"></stop>
               <stop offset="1" stopColor="#FFD747" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient id="t" x1="20.36" x2="34.02" y1="44.98" y2="54.29" gradientUnits="userSpaceOnUse">
               <stop stopColor="#FFD535"></stop>
               <stop offset="1" stopColor="#FFD535" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient id="x" x1="28.14" x2="40.24" y1="13.08" y2="30.7" gradientUnits="userSpaceOnUse">
               <stop stopColor="#FFE746"></stop>
               <stop offset="1" stopColor="#FFE746" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient id="z" x1="40.43" x2="52.53" y1="13.08" y2="30.7" gradientUnits="userSpaceOnUse">
               <stop stopColor="#FFE746"></stop>
               <stop offset="1" stopColor="#FFE746" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient id="B" x1="0" x2="0" y1="0" y2="0" gradientUnits="userSpaceOnUse">
               <stop stopColor="#FFE949"></stop>
               <stop offset="1" stopColor="#FFE949" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="b"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(0 1 -2.3 0 18.52 41.54)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#BE6E00"></stop>
               <stop offset="0.79" stopColor="#BE6E00" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="c"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(4.2 5.5 -6.7 5.1 33.01 44.72)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.42" stopColor="#D08C3B"></stop>
               <stop offset="0.88" stopColor="#D08C3B" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="d"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(9.3 -7.3 7 8.8 33.34 39.27)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#E17C51"></stop>
               <stop offset="1" stopColor="#E17C51" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="e"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-20 13 -26.6 -41 55.3 25.46)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFE452"></stop>
               <stop offset="1" stopColor="#FFE452" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="h"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-1.5 -6.2 4.6 -1.1 43.64 24.6)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFDD4B"></stop>
               <stop offset="1" stopColor="#FFDD4B" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="m"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(4.6 4 -7.8 8.9 29.36 5.4)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#DEA23E"></stop>
               <stop offset="1" stopColor="#DEA23E" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="r"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(4.1 5.8 -7.6 5.4 18.71 5.66)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#DEA23E"></stop>
               <stop offset="1" stopColor="#DEA23E" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="v"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(154 18.96 21.14) scale(8.6 41.4)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#FFEB5C"></stop>
               <stop offset="1" stopColor="#FFE94A" stopOpacity="0"></stop>
            </radialGradient>
            <filter
               id="a"
               width="38.41"
               height="56.63"
               x="13.4"
               y="3.08"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="1.2" dy="-1.2"></feOffset>
               <feGaussianBlur stdDeviation="2"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.909804 0 0 0 0 0.494118 0 0 0 0 0.301961 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_910_5532"></feBlend>
            </filter>
            <filter
               id="s"
               width="19.38"
               height="16.68"
               x="15.26"
               y="40.48"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_5532" stdDeviation="1.5"></feGaussianBlur>
            </filter>
            <filter
               id="u"
               width="24.39"
               height="33.24"
               x="28.22"
               y="18.91"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_5532" stdDeviation="2"></feGaussianBlur>
            </filter>
            <filter
               id="w"
               width="21.29"
               height="28.76"
               x="18.35"
               y="2.72"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_5532" stdDeviation="1.5"></feGaussianBlur>
            </filter>
            <filter
               id="y"
               width="14.86"
               height="30.37"
               x="31.77"
               y="3.38"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_5532" stdDeviation="1.5"></feGaussianBlur>
            </filter>
            <filter
               id="A"
               width="6"
               height="27.08"
               x="43.77"
               y="10.74"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_910_5532" stdDeviation="1"></feGaussianBlur>
            </filter>
         </defs>
      </svg>
   );
}
