import { Link } from "react-router-dom";
import formatPhone from "../../helpers/formatPhone";
import IconDisclaimer from "../Icons/Disclaimer";
import IconLogo from "../Icons/Logo";
import IconSbp from "../Icons/Sbp";
import classes from "./index.module.scss";

const {
   footer,
   footer__aboutCompany,
   footer__grid,
   footer__cel,
   footer__info,
   footer__links,
   footer__contacts,
   footer__pictures,
   footer__age,
   footer__sbp,
   logo,
   age,
   sbp,
   bold,
} = classes;

const initialState = window.initialState
   ? window.initialState
   : {
        settings: {
           about: {
              inn: 1000000001,
              ogrn: 1000000000001,
              license_cb: "№1010101010, выдана 21.08.2024 года",
              min_loan: 5000,
              max_loan: 100000,
              min_interest: 0,
              max_interest: 1,
              min_duration: 5,
              max_duration: 365,
              min_psk: 0,
              max_psk: 365,
              address: "Адрес: 000000, город Новосибирск, пр-кт Ленина, д. 10, оф. 140",
              phone: 8126288181,
              email: "support@site.ru",
              work_time: "ежедневно: с 10:00 до 22:00 (по Москве).",
              footer_text: [
                 "Проект осуществляет подбор микрозаймов между лицом желающим оформить займ и кредитными учреждениями которые на основании ФЗ «О потребительском кредите (займе)» от 21.12.2013 № 353-ФЗ имеют право осуществлять профессиональную деятельность по предоставлению потребительских займов. Проект не является финансовым учреждением, банком или кредитором, и не несёт ответственности за любые заключенные договоры кредитования или условия.",
                 "Адрес местонахождения и почтовый адрес организации: 000000, город Новосибирск, пр-кт Ленина, д. 10, оф. 140. Телефон службы обеспечения деятельности финансового уполномоченного - +7 (800) 888-888-88.",
              ],
              copyright: [
                 "© 2024 Общество с ограниченной ответственностью «Удобные Займы» (ООО  «Удобные Займы») Проект не является финансовым учреждением, банком или кредитором, и не несёт ответственности за любые заключенные договоры кредитования или условия. ",
              ],
           },
        },
     };

function Footer() {
   let about = initialState.settings.about ? initialState.settings.about : [];

   return (
      <footer className={footer}>
         <div className={footer__aboutCompany}>
            {about.footer_text.map((text, i) => (
               <p key={i}>{text}</p>
            ))}
         </div>
         <div className={footer__grid}>
            <div className={footer__cel}>
               <p className={bold}>Сумма займа</p>
               <p>
                  от {parseFloat(about.min_loan).toLocaleString("ru-RU")} до{" "}
                  {parseFloat(about.max_loan).toLocaleString("ru-RU")} рублей
               </p>
            </div>
            <div className={footer__cel}>
               <p className={bold}>Срок займа</p>
               <p>
                  от {about.min_duration} до {about.max_duration} календарных дней
               </p>
            </div>
            <div className={footer__cel}>
               <p className={bold}>Процентная ставка</p>
               <p>
                  от {about.min_interest}% до {about.max_interest}% в день
               </p>
            </div>
            <div className={footer__cel}>
               <p className={bold}>ПСК (полная стоимость кредита)</p>
               <p>
                  от {about.min_psk}% до {about.max_psk}% годовых
               </p>
            </div>
            <div className={footer__cel}>
               <p className={bold}>ИНН</p>
               <p>{about.inn}</p>
            </div>
            <div className={footer__cel}>
               <p className={bold}>ОРГН</p>
               <p>{about.ogrn}</p>
            </div>
            <div className={footer__cel}>
               <p className={bold}>Оператор персональных данных</p>
               <p>{about.license_cb}</p>
            </div>
         </div>
         <div className={footer__info}>
            <div className={footer__links}>
               <Link to={"/o-nas"}>О компании</Link>
               <Link to={"/dokumenti"}>Документы</Link>
			   <Link to={"/application"}>Оформить заявку</Link>
			   <Link to={"/profile"}>Личный кабинет</Link>
            </div>
            <div className={footer__contacts}>
               <p>{about.copyright[0]}</p>
               <address> {about.address} (В офисе компании займы не выдаются) </address> 
               <p>
                  Телефон для связи: <a href={"tel: +7" + about.phone}>{formatPhone(about.phone)}</a> (Служба поддержки займы не выдаёт)
               </p>
               <p>График работы: {about.work_time}</p>
               <p>
                  Служба поддержки клиентов: <a href={"mailto: " + about.email}>{about.email}</a>
               </p>
            </div>
         </div>
         <div className={footer__pictures}>
            <Link aria-label="Главная" to={"/"} title="Главная">
               <IconLogo className={logo} />
            </Link>
            <div className={footer__age}>
               <IconDisclaimer className={age} />
            </div>
            <div className={footer__sbp}>
               <IconSbp className={sbp} />
            </div>
         </div>
      </footer>
   );
}

export default Footer;
