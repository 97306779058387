import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import DropDownList from "../../../components/DropDownList";
import EmptyData from "../../../components/EmptyData";
import Input from "../../../components/Input";
import Table from "../../../components/Table";
import checkAuth from "../../../helpers/checkAuth";
import api from "../../../helpers/fetch";
import getFIO from "../../../helpers/getFIO";
import FulscreenLoader from "../../Components/FulscreenLoader";
import Head from "../../Components/Head";
import IconFilter from "../../Components/Icons/IconFilter";
import PopUp from "../../Components/PopUp";

import scss from "./index.module.scss";

const { link, wrapper__accept, attantion_small, buttons } = scss;

function Staff() {
   const [wait, setWait] = useState(false);
   const [isActive, setIsActive] = useState(false);
   const [row, setRow] = useState([]);
   const [colums] = useState([
      {
         paramName: "surname",
         name: "ФИО",
      },
      {
         paramName: "phone_number",
         name: "Номер телефона",
      },
      {
         paramName: "role",
         name: "Должность",
      },
      {
         paramName: "status",
         name: "Статус",
      },
      {
         paramName: "action",
         name: "Действия",
      },
   ]);
   const [sort, setSort] = useState(["surname", "asc"]);
   const [search, setSearch] = useState("");

   const statusArray = ["Уволен", "Работает"];
   const roleArray = ["Менеджер", "Редактор", "Администратор"];

   const [name, setName] = useState("");
   const [nameError, setNameError] = useState("");
   const [surname, setSurname] = useState("");
   const [surnameError, setSurnameError] = useState("");
   const [midname, setMidname] = useState("");
   const [phone_number, setPhone_number] = useState("");
   const [phone_numberError, setPhone_numberError] = useState("");
   const [email, setEmail] = useState("");
   const [emailError, setEmailError] = useState("");
   const [role, setRole] = useState(0);
   const [pagination, setPagination] = useState({});
   const [paginationPage, setPaginationPage] = useState(1);

   const toTable = (rows = []) => {
      const tmpArray = [];
      rows.forEach((el, key) => {
         tmpArray.push([
            {
               type: "text",
               value: getFIO(el.name, el.surname, el.midname, "ser-name-mid"),
            },
            {
               type: "tel",
               value: el.phone_number,
            },
            {
               type: "text",
               value: roleArray[el.role - 1],
            },
            {
               type: el.status === 0 ? "error" : "success",
               value: statusArray[el.status],
            },
            {
               type: "component",
               value: (
                  <Link className={link} to={"/manage/staff/" + el.id_user}>
                     Подробнее
                  </Link>
               ),
            },
         ]);
      });
      return tmpArray;
   };

   const getData = async () => {
      const auth = checkAuth();
      let queryParams = getSortQuery("surname");
      const response = await new api(
         `manage/users?${search ? "search=" + search : ""}` +
            queryParams +
            "&page=" +
            (paginationPage !== 0 && paginationPage ? paginationPage : 1),
         auth
      ).send();
      if (response.status === "success") {
         setRow(toTable(response.data.data));
         setPagination(response.data.pagination);
      }
   };

   const getSearch = (q) => {
      setSearch(q);
   };

   const getSortQuery = (nameField) => {
      if (sort.length === 0) {
         setSort([nameField, "asc"]);
         return "";
      } else {
         return "&sort_param=" + sort[0] + "&sort_direction=" + sort[1];
      }
   };

   const onSubmit = async (e) => {
      e.preventDefault();
      setWait(true);
      setNameError("");
      setSurnameError("");
      setPhone_numberError("");
      setEmailError("");
      if (!name) {
         setNameError("Поле должно быть заполнено!");
      }
      if (!surname) {
         setSurnameError("Поле должно быть заполнено!");
      }
      if (!phone_number) {
         setPhone_numberError("Поле должно быть заполнено!");
      }
      if (!email) {
         setEmailError("Поле должно быть заполнено!");
      }
      if (name && surname && phone_number && email) {
         const auth = checkAuth();
         const body = new FormData();
         body.append("role", role + 1);
         body.append("name", name);
         body.append("surname", surname);
         body.append("midname", midname);
         body.append("phone_number", phone_number);
         body.append("email", email);
         const response = await new api(`manage/users`, auth, {
            method: "POST",
            body,
         }).send();
         if (response.status === "success") {
            onCloas();
            getData();
            setWait(false);
         } else {
            setPhone_numberError("Такой пользователь уже существует!");
            setEmailError("Такой пользователь уже существует!");
            setWait(false);
         }
      }
      setWait(false);
   };

   useEffect(() => {
      getData();
   }, []);

   useEffect(() => {
      getData();
      onPaginate(1);
   }, [sort, search]);

   useEffect(() => {
      getData();
   }, [paginationPage]);

   const onCloas = () => {
      setIsActive(!isActive);
      document.body.style.overflowY = null;
      getData();
   };

   const onPaginate = (number) => {
      setPaginationPage(number);
   };

   return !wait ? (
      <>
         <Head
            onLeavePresEnter={getSearch}
            onClickButton={() => setIsActive(!isActive)}
            buttonText={"Новый сотрудник"}
            searchButton
         />
         <Table
            rows={row}
            colums={colums}
            onSort={(param, direction) => setSort([param, direction])}
            sortParam={sort[0]}
            sortDirection={sort[1]}
            baseColumnsWidth={[null, 256, 256, 160, 160]}
            blockOnEmpty={
               <EmptyData
                  title="Нет данных"
                  text="По вашему запросу не найдено ни одного сотрудника."
               />
            }
            pagination={pagination}
            onPaginate={onPaginate}
         />
         {isActive ? (
            <PopUp title={"Новый сотрудник"} onCloase={() => onCloas()}>
               <form action="" onSubmit={onSubmit} className={wrapper__accept}>
                  <DropDownList
                     label={"Должность"}
                     options={roleArray}
                     value={role}
                     onChange={setRole}
                  />
                  <Input
                     label={"Имя*"}
                     placeholder={"Имя"}
                     type={"text"}
                     regex={/^[а-яА-ЯёЁ \-]{0,}$/u}
                     value={name}
                     onChange={setName}
                     error={nameError}
                  />
                  <Input
                     label={"Фамилия*"}
                     placeholder={"Фамилия"}
                     type={"text"}
                     regex={/^[а-яА-ЯёЁ \-]{0,}$/u}
                     value={surname}
                     onChange={setSurname}
                     error={surnameError}
                  />
                  <Input
                     label={"Отчество"}
                     placeholder={"Отчество"}
                     type={"text"}
                     regex={/^[а-яА-ЯёЁ \-]{0,}$/u}
                     value={midname}
                     onChange={setMidname}
                  />
                  <Input
                     label={"Телефон*"}
                     placeholder={"+7 (906) 800 35-35"}
                     mask={"+7 (XXX) XXX-XX-XX"}
                     name="tel"
                     type={"text"}
                     value={phone_number}
                     onChange={setPhone_number}
                     error={phone_numberError}
                  />
                  <Input
                     label={"Электронная почта*"}
                     placeholder={"Электронная почта"}
                     type={"text"}
                     value={email}
                     onChange={setEmail}
                     error={emailError}
                  />
                  <p className={attantion_small}>
                     Внимательно проверяйте данные сотрудника перед сохранением.
                  </p>
                  <div className={buttons}>
                     <Button variant={"gray"} onClick={() => onCloas()}>
                        Отмена
                     </Button>
                     <Button isWaiting={wait} type={"submit"}>
                        Добавить
                     </Button>
                  </div>
               </form>
            </PopUp>
         ) : null}
      </>
   ) : (
      <FulscreenLoader />
   );
}

export default Staff;
