import scss from "./index.module.scss";
import ReactLoading from "react-loading";

const { loader } = scss;

function FulscreenLoader() {
   return (
      <div className={loader}>
         <ReactLoading type={"spin"} color="#2d9614" />
      </div>
   );
}

export default FulscreenLoader;
