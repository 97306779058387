import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import InputRange from "../../../components/InputRange";
import checkAuth from "../../../helpers/checkAuth";
import api from "../../../helpers/fetch";
import formatPhone from "../../../helpers/formatPhone";
import getFIO from "../../../helpers/getFIO";
import Container from "../../Components/Container";
import PopUp from "../../Components/PopUp";

import scss from "./index.module.scss";

const {
   bio,
   block,
   field,
   field__wait,
   passport,
   incomes,
   actions,
   red,
   buttons,
   attantion,
   wrapper,
   wrapper__accept,
   attantion_small,
   passport_row,
   block_column,
   button_action,
} = scss;

function Application() {
   const params = useParams();

   const [applicatiobData, setStae] = useState("");

   const {
      bday,
      bplace,
      childrenCount,
      date_created,
      date_decision,
      date_end,
      date_start,
      duration,
      email,
      familyCount,
      familyState,
      id_application,
      id_client,
      id_employer,
      id_loan,
      id_user,
      income,
      inn,
      isOnReg,
      jobAddress,
      jobPhone,
      jobPlace,
      jobPosition,
      jobType,
      liveWithParents,
      living,
      loans,
      midname,
      name,
      nextIncome,
      number,
      pasportDateGet,
      pasportOrg,
      pasportOrgCode,
      pasport,
      photo1,
      photo2,
      photo3,
      photo4,
      rate,
      registration,
      status,
      surname,
      total_amount,
      phone_number,
   } = applicatiobData;

   const data = {};
   const navigate = useNavigate();

   const [ranges, setRanges] = useState(rate);
   const [amounts, setAmounts] = useState("");
   const [amountError, setAmountError] = useState("");
   const [error, setError] = useState(false);
   const [numbers, setNumbers] = useState("");
   const [numbersError, setNumbersError] = useState("");
   const amountArray = [];
   for (let i = 2000; i <= 100000; i += 1000) amountArray.push(i);
   const [durations, setDurations] = useState(
      data.duration || data.duration === 0 ? data.duration : 7
   );
   const durationArray = [];
   for (let i = 7; i <= 30; i++) durationArray.push(i);
   const [isActiveDismiss, setisActiveDismiss] = useState(false);
   const [errorDenied, setErrorDenied] = useState(false);
   const [wait, setWait] = useState(false);

   const loansArray = [
      "Нет платежей по кредитам",
      "До 10 000 ₽",
      "10 000 ₽ - 20 000 ₽",
      "20 000 ₽ - 35 000 ₽",
      "35 000 ₽ - 50 000 ₽",
      "Более 50 000 ₽",
   ];

   const incomeArray = [
      "До 10 000 ₽",
      "10 000 ₽ - 20 000 ₽",
      "20 000 ₽ - 35 000 ₽",
      "35 000 ₽ - 50 000 ₽",
      "Более 50 000 ₽",
   ];

   const nextIncomeArray = [
      "1го числа",
      "2го числа",
      "3го числа",
      "4го числа",
      "5го числа",
      "6го числа",
      "7го числа",
      "8го числа",
      "9го числа",
      "10го числа",
      "11го числа",
      "12го числа",
      "13го числа",
      "14го числа",
      "15го числа",
      "16го числа",
      "17го числа",
      "18го числа",
      "19го числа",
      "20го числа",
      "21го числа",
      "22го числа",
      "23го числа",
      "24го числа",
      "25го числа",
      "26го числа",
      "27го числа",
      "28го числа",
      "29го числа",
      "30го числа",
      "31го числа",
   ];

   const jobTypeArray = ["Работаю", "Не работаю", "Пенсионер"];

   const onCloaseDismiss = () => {
      document.body.style.overflowY = null;
      setisActiveDismiss(!isActiveDismiss);
   };

   const onOpenDismiss = () => {
      document.body.style.overflowY = "hidden";
      setisActiveDismiss(!isActiveDismiss);
   };

   const [isActiveAccept, setisActiveAccept] = useState(false);

   const onCloaseAccept = () => {
      document.body.style.overflowY = null;
      setisActiveAccept(!isActiveAccept);
      setAmountError("");
      setNumbersError("");
   };

   const onOpenAccept = () => {
      document.body.style.overflowY = "hidden";
      setisActiveAccept(!isActiveAccept);
   };

   const getData = async () => {
      const auth = checkAuth();
      const response = await new api(`manage/applications/${params.id}`, auth).send();
      setStae(response.data[0]);
      setAmounts(response.data[0].total_amount / 100);
      setDurations(response.data[0].duration);
      setRanges(response.data[0].rate);
   };

   const getPasport = () => {
      if (pasport) {
         let pas = pasport.toString();
         return pas.slice(0, 4) + "-" + pas.slice(4, 10);
      } else return 0;
   };

   const getCode = () => {
      if (pasportOrgCode) {
         let pas = pasportOrgCode.toString();
         return pas.slice(0, 3) + "-" + pas.slice(3, 6);
      } else return 0;
   };

   const onSubmitDismiss = async (e) => {
      e.preventDefault();
      setWait(true);
      const auth = checkAuth();
      const response = await new api(`manage/loans/${id_loan}/decline`, auth, {
         method: "PUT",
      }).send();
      if (response.status === "success") {
         onCloaseDismiss();
         setWait(false);
         navigate("/manage/applications");
      } else {
         setErrorDenied(true);
         setWait(false);
      }
      onCloaseDismiss();
      setWait(false);
   };

   const onSubmit = async (e) => {
      e.preventDefault();
      setWait(true);
      setAmountError(
         amounts.toString().trim().length > 0 ? "" : "Поле должно быть заполненым!"
      );
      setAmountError(parseInt(amounts) <= 100000 ? "" : "Слишком большое значение");
      setNumbersError(
         numbers.toString().trim().length > 0 ? "" : "Поле должно быть заполненым!"
      );
      if (
         amounts.toString().trim().length > 0 &&
         numbers.toString().trim().length > 0 &&
         parseInt(amounts) <= 100000
      ) {
         const auth = checkAuth();
         const body = JSON.stringify({
            amount: amounts * 100,
            duration: durations,
            rate: parseFloat(ranges),
            number: numbers,
         });
         const response = await new api(`manage/loans/${id_loan}/accept`, auth, {
            method: "PUT",
            body,
         }).send();
         if (response.status === "success") {
            onCloaseAccept();
            setWait(false);
            navigate("/manage/applications");
         } else {
            setErrorDenied(true);
            setWait(false);
         }
      }
      setWait(false);
   };

   const familyCountArray = ["1", "2", "3", "4", "5", "Более 5"];

   const familyStateArray = [
      "Холост / не замужем",
      "Гражданский брак",
      "Женат / замужем",
      "В разводе",
   ];

   const childrenCountArray = ["Нет", "1", "2", "3", "4", "5", "Более 5"];

   useEffect(() => {
      getData();
   }, []);

   return (
      <>
         <Container>
            <h2>Биографические данные</h2>
            <div className={bio}>
               <div className={block}>
                  <span>Ф.И.О:</span>
                  <span
                     className={name ? field : field__wait}
                     onClick={() =>
                        navigator.clipboard.writeText(
                           getFIO(name, surname, midname, "ser-name-mid")
                        )
                     }
                  >
                     {name ? getFIO(name, surname, midname, "ser-name-mid") : null}
                  </span>
               </div>
               <div className={block}>
                  <span>Номер телефона:</span>
                  <a
                     href={"tel:+" + phone_number}
                     title={"Позвонить по номеру " + phone_number}
                     className={phone_number ? field : field__wait}
                     onClick={() => navigator.clipboard.writeText(phone_number)}
                  >
                     {phone_number ? formatPhone(phone_number) : null}
                  </a>
               </div>
               <div className={block}>
                  <span>E-mail:</span>
                  <a
                     href={"mailto:" + email}
                     title={"Написать на адрес элетронной почты " + email}
                     className={email ? field : field__wait}
                     onClick={() => navigator.clipboard.writeText(email)}
                  >
                     {email ? email : null}
                  </a>
               </div>
            </div>
         </Container>
         <Container>
            <h2>Паспортные данные</h2>
            <div className={passport}>
               <div className={block}>
                  <span>Паспорт:</span>
                  <span
                     className={pasport ? field : field__wait}
                     onClick={() => navigator.clipboard.writeText(pasport)}
                  >
                     {pasport ? getPasport() : null}
                  </span>
               </div>
               <div className={block}>
                  <span>Дата рождения:</span>
                  <span
                     className={bday ? field : field__wait}
                     onClick={() => navigator.clipboard.writeText(bday)}
                  >
                     {bday ? new Date(bday).toLocaleDateString("ru-RU") : null}
                  </span>
               </div>
               <div className={block}>
                  <span>Место рождения:</span>
                  <span
                     className={bplace ? field : field__wait}
                     onClick={() => navigator.clipboard.writeText(bplace)}
                  >
                     {bplace ? bplace : null}
                  </span>
               </div>
               <div className={block}>
                  <span>Код подразделения:</span>
                  <span
                     className={pasportOrgCode ? field : field__wait}
                     onClick={() => navigator.clipboard.writeText(pasportOrgCode)}
                  >
                     {pasportOrgCode ? getCode(pasportOrgCode) : null}
                  </span>
               </div>
               <div className={block}>
                  <span>Дата выдачи:</span>
                  <span
                     className={pasportDateGet ? field : field__wait}
                     onClick={() => navigator.clipboard.writeText(pasportDateGet)}
                  >
                     {pasportDateGet
                        ? new Date(pasportDateGet).toLocaleDateString("ru-RU")
                        : null}
                  </span>
               </div>
               <div className={block}>
                  <span>Кем выдано:</span>
                  <span
                     className={pasportOrg ? field : field__wait}
                     onClick={() => navigator.clipboard.writeText(pasportOrg)}
                  >
                     {pasportOrg ? pasportOrg : null}
                  </span>
               </div>
               {inn ? (
                  <>
                     <hr />
                     <div className={block}>
                        <span>ИНН:</span>
                        <span
                           className={inn ? field : field__wait}
                           onClick={() => navigator.clipboard.writeText(inn)}
                        >
                           {inn ? inn : null}
                        </span>
                     </div>
                     <hr />
                  </>
               ) : null}
               <div className={block}>
                  <span>Адрес регистрации:</span>
                  <span
                     className={registration ? field : field__wait}
                     onClick={() => navigator.clipboard.writeText(registration)}
                  >
                     {registration ? registration : null}
                  </span>
               </div>
               {isOnReg === 1 ? (
                  <div className={block}>
                     <span>Адрес проживания:</span>
                     <span
                        className={living ? field : field__wait}
                        onClick={() => navigator.clipboard.writeText(living)}
                     >
                        {living ? living : null}
                     </span>
                  </div>
               ) : null}
               <hr />
               <div className={passport_row}>
                  <div className={block + " " + block_column}>
                     <span>Фотография разворота:</span>
                     <a
                        href={photo1}
                        target={"_blank"}
                        title={"Открыть в новой вкладке " + photo1}
                        rel="noreferrer"
                        className={photo1 ? field : field__wait}
                     >
                        <img
                           src={
                              photo1
                                 ? photo1
                                 : "https://upload.wikimedia.org/wikipedia/commons/4/46/%D0%A1%D0%B5%D1%80%D1%8B%D0%B9_%D1%86%D0%B2%D0%B5%D1%82-_2014-03-15_18-16.jpg"
                           }
                           alt="Фотография разворота"
                           width={"256px"}
                           height={"128px"}
                        />
                     </a>
                  </div>
                  <div className={block + " " + block_column}>
                     <span>Фотография регистрации:</span>
                     <a
                        href={photo2}
                        target={"_blank"}
                        title={"Открыть в новой вкладке " + photo2}
                        rel="noreferrer"
                        className={photo2 ? field : field__wait}
                     >
                        <img
                           src={
                              photo2
                                 ? photo2
                                 : "https://upload.wikimedia.org/wikipedia/commons/4/46/%D0%A1%D0%B5%D1%80%D1%8B%D0%B9_%D1%86%D0%B2%D0%B5%D1%82-_2014-03-15_18-16.jpg"
                           }
                           alt="Фотография регистрации"
                           width={"256px"}
                           height={"128px"}
                        />
                     </a>
                  </div>
                  <div className={block + " " + block_column}>
                     <span>Фотография с паспортом:</span>
                     <a
                        href={photo3}
                        target={"_blank"}
                        title={"Открыть в новой вкладке " + photo3}
                        rel="noreferrer"
                        className={photo3 ? field : field__wait}
                     >
                        <img
                           src={
                              photo3
                                 ? photo3
                                 : "https://upload.wikimedia.org/wikipedia/commons/4/46/%D0%A1%D0%B5%D1%80%D1%8B%D0%B9_%D1%86%D0%B2%D0%B5%D1%82-_2014-03-15_18-16.jpg"
                           }
                           alt="Фотография с паспортом"
                           width={"256px"}
                           height={"128px"}
                        />
                     </a>
                  </div>
                  <div className={block + " " + block_column}>
                     <span>Фотография СНИЛСа:</span>
                     <a
                        href={photo4}
                        target={"_blank"}
                        title={"Открыть в новой вкладке " + photo4}
                        rel="noreferrer"
                        className={photo4 ? field : field__wait}
                     >
                        <img
                           src={
                              photo4
                                 ? photo4
                                 : "https://upload.wikimedia.org/wikipedia/commons/4/46/%D0%A1%D0%B5%D1%80%D1%8B%D0%B9_%D1%86%D0%B2%D0%B5%D1%82-_2014-03-15_18-16.jpg"
                           }
                           alt="Фотография СНИЛСа"
                           width={"256px"}
                           height={"128px"}
                        />
                     </a>
                  </div>
               </div>
               <hr />
               <div className={block}>
                  <span>Семейное положение:</span>
                  <span
                     className={familyState || familyState === 0 ? field : field__wait}
                     onClick={() => navigator.clipboard.writeText(familyState)}
                  >
                     {familyState || familyState === 0
                        ? familyStateArray[familyState]
                        : null}
                  </span>
               </div>
               <div className={block}>
                  <span>Кол-во членов семьи:</span>
                  <span
                     className={familyCount || familyCount === 0 ? field : field__wait}
                     onClick={() => navigator.clipboard.writeText(familyCount)}
                  >
                     {familyCount || familyCount === 0
                        ? familyCountArray[familyCount]
                        : null}
                  </span>
               </div>
               <div className={block}>
                  <span>Кол-во детей до 18 лет:</span>
                  <span
                     className={
                        childrenCount || childrenCount === 0 ? field : field__wait
                     }
                     onClick={() => navigator.clipboard.writeText(childrenCount)}
                  >
                     {childrenCount || childrenCount === 0
                        ? childrenCountArray[childrenCount]
                        : null}
                  </span>
               </div>
               <div className={block}>
                  <span>Проживаю с родителями:</span>
                  <span
                     className={
                        liveWithParents || liveWithParents === 0 ? field : field__wait
                     }
                     onClick={() =>
                        navigator.clipboard.writeText(
                           liveWithParents || liveWithParents === 0
                              ? liveWithParents === 0
                                 ? "Да"
                                 : "Нет"
                              : null
                        )
                     }
                  >
                     {liveWithParents || liveWithParents === 0
                        ? liveWithParents === 0
                           ? "Да"
                           : "Нет"
                        : null}
                  </span>
               </div>
            </div>
         </Container>
         <Container>
            <h2>Данные о доходе</h2>
            <div className={incomes}>
               <div className={block}>
                  <span>Вид занятости:</span>
                  <span
                     className={jobType !== "" ? field : field__wait}
                     onClick={
                        jobType !== ""
                           ? () => navigator.clipboard.writeText(jobTypeArray[jobType])
                           : () => {}
                     }
                  >
                     {jobType !== "" ? jobTypeArray[jobType] : "Нет данных"}
                  </span>
               </div>
               {jobType === 0 ? (
                  <>
                     <div className={block}>
                        <span>Место работы:</span>
                        <span
                           className={jobPlace ? field : field__wait}
                           onClick={() => navigator.clipboard.writeText(jobPlace)}
                           dangerouslySetInnerHTML={{
                              __html: jobPlace ? jobPlace : null,
                           }}
                        ></span>
                     </div>
                     <div className={block}>
                        <span>Должность:</span>
                        <span
                           className={jobPosition ? field : field__wait}
                           onClick={() => navigator.clipboard.writeText(jobPosition)}
                        >
                           {jobPosition ? jobPosition : null}
                        </span>
                     </div>
                     <div className={block}>
                        <span>Адрес организации:</span>
                        <span
                           className={jobAddress ? field : field__wait}
                           onClick={() => navigator.clipboard.writeText(jobAddress)}
                        >
                           {jobAddress ? jobAddress : null}
                        </span>
                     </div>
                     <div className={block}>
                        <span>Номер телефона:</span>
                        <a
                           href={"tel:+" + jobPhone}
                           title={"Позвонить по номеру " + jobPhone}
                           className={jobPhone ? field : field__wait}
                        >
                           {jobPhone ? formatPhone(jobPhone) : null}
                        </a>
                     </div>
                     <div className={block}>
                        <span>Ближайшая выплата:</span>
                        <span
                           className={
                              nextIncome || nextIncome === 0 ? field : field__wait
                           }
                           onClick={() =>
                              navigator.clipboard.writeText(
                                 nextIncome || nextIncome === 0
                                    ? nextIncomeArray[nextIncome]
                                    : ""
                              )
                           }
                        >
                           {nextIncome || nextIncome === 0
                              ? nextIncomeArray[nextIncome]
                              : null}
                        </span>
                     </div>
                  </>
               ) : null}
               <div className={block}>
                  <span>Ежемесячный доходе:</span>
                  <span
                     className={income || income === 0 ? field : field__wait}
                     onClick={
                        income || income === 0
                           ? () => navigator.clipboard.writeText(incomeArray[income])
                           : () => {}
                     }
                  >
                     {income || income === 0 ? incomeArray[income] : null}
                  </span>
               </div>
               <div className={block}>
                  <span>Кредитные обязательства:</span>
                  <span
                     className={loans || loans === 0 ? field : field__wait}
                     onClick={() =>
                        navigator.clipboard.writeText(
                           loans || loans === 0 ? loansArray[loans] : ""
                        )
                     }
                  >
                     {loans || loans === 0 ? loansArray[loans] : null}
                  </span>
               </div>
               <hr />
               <div className={block}>
                  <span>Желаемая сумма:</span>
                  <span
                     className={total_amount ? field : field__wait}
                     onClick={() => navigator.clipboard.writeText(total_amount / 100)}
                  >
                     {total_amount
                        ? (total_amount / 100).toLocaleString("ru-RU") + " ₽"
                        : null}
                  </span>
               </div>
               <div className={block}>
                  <span>Желаемый срок:</span>
                  <span
                     className={duration ? field : field__wait}
                     onClick={() => navigator.clipboard.writeText(duration)}
                  >
                     {duration ? duration : null}
                  </span>
               </div>
            </div>
         </Container>
         <div className={actions}>
            <Button
               isWaiting={!name ? true : false}
               variant={"gray"}
               onClick={() => onOpenDismiss()}
            >
               Отклонить заявку
            </Button>
            <Button isWaiting={!name ? true : false} onClick={() => onOpenAccept()}>
               Перейти к оформлению
            </Button>
         </div>
         {isActiveDismiss ? (
            <PopUp title={"Подтвердите условия займа"} onCloase={onCloaseDismiss}>
               <form action="" onSubmit={onSubmitDismiss} className={wrapper}>
                  {!errorDenied ? (
                     <p className={attantion}>
                        В займе будет отказано. Если вы подтверждаете отказ в займе, то
                        действие будет необратимо. <br />
                        <br /> Вы подтверждаете выбор?
                     </p>
                  ) : (
                     <>
                        <p className={attantion}>Что-то пошло не по плану!</p>
                        <p className={attantion}>
                           Пробуйте позже или обратитесь в службу поддержки.
                        </p>
                     </>
                  )}
                  <div className={buttons}>
                     <Button variant={"gray"} onClick={() => onCloaseDismiss()}>
                        Отмена
                     </Button>
                     {!errorDenied ? (
                        <Button isWaiting={wait} className={red} type={"submit"}>
                           Отказать в займе
                        </Button>
                     ) : (
                        <Button onClick={() => window.location.reload(false)}>
                           Обновить страницу
                        </Button>
                     )}
                  </div>
               </form>
            </PopUp>
         ) : null}
         {isActiveAccept ? (
            <PopUp title={"Подтвердите условия займа"} onCloase={onCloaseAccept}>
               <form action="" onSubmit={onSubmit} className={wrapper__accept}>
                  {!error ? (
                     <>
                        <Input
                           label={"Номер договора"}
                           placeholder={"123456"}
                           className={field}
                           type={"text"}
                           regex={/^[0-9\-]{1,}$/}
                           value={numbers}
                           onChange={setNumbers}
                           error={numbersError}
                        />
                        <Input
                           label={"Предлагаемая сумма"}
                           placeholder={"10000"}
                           className={field}
                           type={"number"}
                           value={amounts}
                           onChange={setAmounts}
                           error={amountError}
                        />
                        <InputRange
                           label={"Предлагаемый срок"}
                           length={30}
                           showValue={durations + " дней"}
                           step={1}
                           min={7}
                           value={durations}
                           onChange={setDurations}
                        />
                        <InputRange
                           label={"Предлагаемый процент"}
                           length={1.0}
                           showValue={ranges + " %"}
                           step={0.1}
                           min={0.0}
                           value={ranges}
                           onChange={setRanges}
                        />
                        <p className={attantion_small}>
                           Если хотите предложить другие условия, то измените условия
                           займа сейчас. Либо, оставьте все как есть.
                        </p>
                     </>
                  ) : (
                     <>
                        <p className={attantion_small}>Что-то пошло не по плану!</p>
                        <p className={attantion_small}>
                           Пробуйте немного позже или обратитесь в службу поддержки.
                        </p>
                     </>
                  )}
                  <div className={buttons}>
                     <Button variant={"gray"} onClick={() => onCloaseAccept()}>
                        Отмена
                     </Button>
                     {!error ? (
                        <Button isWaiting={wait} type={"submit"}>
                           Одобрить заявку
                        </Button>
                     ) : (
                        <Button onClick={() => window.location.reload(false)}>
                           Обновить страницу
                        </Button>
                     )}
                  </div>
               </form>
            </PopUp>
         ) : null}
      </>
   );
}

export default Application;
