function formatPhone (num) {
    if (num) {
        if (num >= 1000000000 && num <= 9999999999) {
            num = num.toString();
            const tmp = "+7 (XXX) XXX-XX-XX";
            let formatedPhone = "";
            let i = 0;
    
            tmp.split("").forEach(char => {
                if (char === "X") {
                    formatedPhone += num[i];
                    i++;
                }
                else {
                    formatedPhone += char;
                }
            });
    
            return formatedPhone;
        }
    }

    return "";
}

export default formatPhone;