function IconMailInbox(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
         <path
            fill="#010101"
            d="M21 14.614a8.216 8.216 0 01-5.25 1.886 8.349 8.349 0 01-1.512-.138c-.486.57-1.2.888-2.238.888-1.345 0-2.148-.534-2.61-1.461a3.82 3.82 0 01-.342-1.077A3.147 3.147 0 019 14.25a.75.75 0 00-.75-.75H4.5V8.25A2.25 2.25 0 016.75 6h1.06a8.119 8.119 0 01.59-1.5H6.75A3.75 3.75 0 003 8.25v10.5a3.75 3.75 0 003.75 3.75h10.5A3.75 3.75 0 0021 18.75v-4.136zM15.75 15a6.75 6.75 0 100-13.5 6.75 6.75 0 000 13.5zm-3.531-6.219a.751.751 0 011.062-1.062L15 9.439V5.25a.75.75 0 111.5 0v4.19l1.719-1.721a.75.75 0 111.062 1.062l-3 3a.749.749 0 01-.526.219h-.01a.747.747 0 01-.521-.216l-.005-.005-3-3v.002z"
         ></path>
      </svg>
   );
}

export default IconMailInbox;
