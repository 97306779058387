import scss from "./index.module.scss";

const { container, container__title, container__content, container__subtext, container_light, container_red } = scss;

function Container({ title, children, subtext, className, light, red }) {
   return red ? (
      <article className={container + " " + container_red}>
         {title ? <h2 className={container__title}>{title}</h2> : null}
         <div className={className ? container__content + " " + className : container__content}>{children}</div>
         {subtext ? <span className={container__subtext}>{subtext}</span> : null}
      </article>
   ) : (
      <article className={!light ? container : container + " " + container_light}>
         {title ? <h2 className={container__title}>{title}</h2> : null}
         <div className={className ? container__content + " " + className : container__content}>{children}</div>
         {subtext ? <span className={container__subtext}>{subtext}</span> : null}
      </article>
   );
}

export default Container;
