import { useEffect } from "react";
import { useState } from "react";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import IconButton from "../IconButton";
import IconDismiss from "../Icons/IconDismiss";
import IconEdit from "../Icons/IconEdit";
import IconSearch from "../Icons/IconSearch";
import scss from "./index.module.scss";

const { head, head__action, head__container } = scss;

function Head({ onLeavePresEnter, searchButton, onClickButton, onClickFilter, buttonIcon, buttonText }) {
   const [search, setSearch] = useState("");

   const onSubmit = (e) => {
      e.preventDefault();
      onLeavePresEnter(search);
   };

   const onClear = () => {
      setSearch("");
      onLeavePresEnter("");
   };

   return (
      <div className={head}>
         <form action="" onSubmit={onSubmit} className={head__container}>
            <Input placeholder={"Поиск"} type={"text"} value={search} onChange={setSearch} />
            {searchButton ? (
               <>
                  <IconButton type="button" onClick={onClear}>
                     <IconDismiss />
                  </IconButton>
                  <IconButton type="submit">
                     <IconSearch />
                  </IconButton>
               </>
            ) : null}
            {buttonIcon && onClickFilter ? (
               <IconButton type="button" onClick={onClickFilter}>
                  {buttonIcon}
               </IconButton>
            ) : null}
         </form>
         {buttonText && onClickButton ? (
            <Button className={head__action} type="button" onClick={onClickButton}>
               {buttonText}
            </Button>
         ) : null}
      </div>
   );
}

export default Head;
