import { useState } from "react";
import { Link } from "react-router-dom";
import Button from "../Button";
import InputRange from "../InputRange";
import Picture from "../Picture";

import css from "./styles.module.scss";

const {
   calculator,
   calculator__image,
   calculator__imageFade,
   calculator__content,
   calculator__header,
   calculator__slogan,
   calculator__form,
   calculator__formBlock,
   calculator__formLabel,
   calculator__formValue,
   calculator__formInput,
   calculator__footer,
   calculator__footerInfo,
   calculator__footerInfoRow,
   calculator__footerInfoText,
   calculator__footerInfoValue,
   calculator__submitButton,
   calculator__documentsLink,
} = css;

const initialState = window.initialState
   ? window.initialState
   : {
        settings: {
           calculator: {
              min_amount: 5000,
              max_amount: 100000,
              min_interest: 0,
              max_interest: 1,
              min_duration: 7,
              max_duration: 365,
           },
        },
     };

const Calculator = () => {
   const amount = [];
   const days = [];

   for (
      let i = initialState.settings.calculator.min_amount;
      i <= initialState.settings.calculator.max_amount;
      i += 1000
   ) {
      amount.push(i);
   }
   for (
      let i = initialState.settings.calculator.min_duration;
      i <= initialState.settings.calculator.max_duration;
      i++
   ) {
      days.push(i);
   }

   const [selectedAmount, selectAmount] = useState(3);
   const [selectedDay, selectDay] = useState(3);

   const lastDate = new Date(new Date().getTime() + days[selectedDay] * 24 * 60 * 60 * 1000);

   return (
      <section className={calculator}>
         <Picture fileName="grass" alt="" className={calculator__image} />
         <div className={calculator__imageFade} />

         <div className={calculator__content} style={{ zIndex: 1, position: "relative" }}>
            <h2 className={calculator__header}>Получи онлайн‑заём</h2>
            <p className={calculator__slogan}>Выдача 24/7 . Минимум документов.</p>

            <div className={calculator__form}>
               <div className={calculator__formBlock}>
                  <p className={calculator__formLabel}>Какая сумма вам требуется?</p>
                  <p className={calculator__formValue}>
                     ₽ {(amount[selectedAmount] ? amount[selectedAmount] : 0).toLocaleString()}
                  </p>
                  <InputRange
                     aria-label="Сумма займа"
                     className={calculator__formInput}
                     variant="white"
                     value={selectedAmount}
                     length={amount.length - 1}
                     onChange={(e) => selectAmount(e)}
                     step={1}
                  />
               </div>
               <div className={calculator__formBlock}>
                  <p className={calculator__formLabel}>На какой срок?</p>
                  <p className={calculator__formValue}>{days[selectedDay]} дней</p>
                  <InputRange
                     aria-label="Срок займа"
                     className={calculator__formInput}
                     variant="white"
                     value={selectedDay}
                     length={days.length - 1}
                     onChange={(e) => selectDay(e)}
                     step={1}
                  />
               </div>
            </div>

            <div className={calculator__footer}>
               <div className={calculator__footerInfo}>
                  <div className={calculator__footerInfoRow}>
                     <p className={calculator__footerInfoText}>Ставка в день</p>
                     <p className={calculator__footerInfoValue}>0.00%</p>
                  </div>
                  <div className={calculator__footerInfoRow}>
                     <p className={calculator__footerInfoText}>Вернуть до</p>
                     <p className={calculator__footerInfoValue}>
                        {("0" + lastDate.getDate()).slice(-2)}.{("0" + (lastDate.getMonth() + 1)).slice(-2)}
                     </p>
                  </div>
               </div>

               <Button
                  href={"/profile"}
                  variant={"white"}
                  className={calculator__submitButton}
               >
                  Оформить заявку
               </Button>
            </div>

            <Link className={calculator__documentsLink} to={"/dokumenti"}>
               Условия получения займа
            </Link>
         </div>
      </section>
   );
};

export default Calculator;
