import { useEffect } from "react";
import { useState } from "react";
import css from "./styles.module.scss";

const { input, input_error, input__label, input__field, input__errorText } = css;

const Input = ({
   className,
   label,
   name,
   showErrorOnBlur = false,
   type = "text",
   placeholder,
   value,
   onChange = () => {},
   disabled,
   mask,
   regex,
   error,
}) => {
   let initType = type;

   const [hasBlured, setBlured] = useState(!showErrorOnBlur);

   let classNameTmp = input;
   classNameTmp += error && hasBlured ? " " + input_error : "";
   classNameTmp += className ? " " + className : "";

   if (type === "date") {
      type = "text";
      mask = "XX.XX.XXXX";
      regex = /^[0-9]$/;

      const tmpDate = new Date(value);

      if (!isNaN(tmpDate.getDate())) {
         value = tmpDate.toLocaleDateString("ru-RU").replaceAll(".", "");
      }
   }
   if (type === "number") {
      type = "text";
      regex = /^[0-9]$/;
   }

   useEffect(() => {
      if (initType !== "date" && value !== val && initType !== "textarea") {
         setVal(value);
      }

      if (initType === "textarea") {
         if (valueToTextArea(value).trim() !== valueToTextArea(val).trim()) {
            setVal(value);
         }
      }
   }, [value]);

   const [val, setVal] = useState(value);
   const [maskedVal, setMaskedVal] = useState(value);

   const typing = (e) => {
      if (mask) {
         const maskChars = mask.split("");
         const valChars = e.target.value.split("");
         const clearVal = [];

         valChars.forEach((char, i) => {
            if (maskChars[i]) {
               if (char !== maskChars[i] && char !== "_") {
                  if (regex) {
                     if (valChars[i].match(regex)) {
                        clearVal.push(valChars[i]);
                     }
                  } else {
                     clearVal.push(valChars[i]);
                  }
               }
            }
         });
         setVal(clearVal.toString().replaceAll(",", ""));
      } else {
         if (regex) {
            const valChars = e.target.value.split("");
            const clearVal = [];

            valChars.forEach((char) => {
               if (char.match(regex)) clearVal.push(char);
            });

            setVal(clearVal.toString().replaceAll(",", ""));
         } else {
            setVal(e.target.value);
         }
      }
   };

   const onChangeWrapper = (result = "") => {
      if (initType === "textarea") {
         const tmp = Array.isArray(result) ? result : (result !== null ? result.toString().split(/\r?\n/) : null);

         if (Array.isArray(tmp)) {
            const rTmp = [];
            tmp.forEach (el => {
               if (el) rTmp.push(el);
            });

            return rTmp;
         }
         else {
            return tmp;
         }
      }
      else {
         return result;
      }
   }

   const check = () => {
      let newMaskedVal = "";

      if (mask) {
         const maskChars = mask.split("");
         let i = 0;
         maskChars.forEach((char, j) => {
            if (val) {
               if (i < val.length) {
                  if (char !== "X") {
                     newMaskedVal += char;
                  } else {
                     if (val[i]) {
                        newMaskedVal += val[i];
                        i++;
                     } else {
                        newMaskedVal += "_";
                     }
                  }
               }
            }
         });
      } else {
         newMaskedVal = val;
      }

      if (initType === "date") {
         if (newMaskedVal.length === 10) {
            const day = newMaskedVal.substring(0, 2);
            const month = newMaskedVal.substring(3, 5);
            const year = newMaskedVal.substring(6);

            const date = new Date(year + "-" + month + "-" + day);

            if (isNaN(date.getDate())) {
               onChange(null);
               newMaskedVal = "";
            } else {
               onChange(date.getTime());
               newMaskedVal = date.toLocaleDateString("ru-RU");
            }
         } else {
            onChange(null);
         }
      } else {
         onChange(onChangeWrapper(val));
      }

      setMaskedVal(newMaskedVal);
   };

   useEffect(() => {
      check();
   }, [val]);

   useEffect(() => {
      check();
   }, []);

   const valueToTextArea = (v = []) => {

      let text = "";

      if (Array.isArray(v)) {
         v.forEach(row => {
            text += row + "\r\n";
         });
         
         return text;
      }
      else {
         return v;
      }
   }

   return (
      <label className={classNameTmp}>
         {label ? <p className={input__label}>{label}</p> : ""}
         {
            initType === "textarea" ? (
               <textarea
                  name={name}
                  type={type}
                  placeholder={placeholder}
                  className={input__field}
                  value={mask || regex ? valueToTextArea(maskedVal) : valueToTextArea(val) }
                  onInput={typing}
                  disabled={disabled}
                  onBlur={(e) => {
                     setBlured(true);
                  }}
               />
            ) : (
               <input
                  name={name}
                  type={type}
                  placeholder={placeholder}
                  className={input__field}
                  value={mask || regex ? maskedVal : val}
                  onInput={typing}
                  disabled={disabled}
                  onBlur={(e) => {
                     setBlured(true);
                  }}
               />
            )
         }
         {error && hasBlured ? <p className={input__errorText}>{error}</p> : ""}
      </label>
   );
};

export default Input;
