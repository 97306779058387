function IconDismiss(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" {...props}>
         <path
            fill="#010101"
            d="M4.177 4.365l.087-.1a.9.9 0 011.171-.088l.101.087 5.964 5.963 5.964-5.964a.9.9 0 011.171-.086l.101.087a.9.9 0 01.088 1.171l-.088.101-5.963 5.964 5.964 5.964a.9.9 0 01.087 1.171l-.088.101a.9.9 0 01-1.171.088l-.1-.088-5.965-5.963-5.964 5.964a.9.9 0 01-1.171.087l-.1-.088a.9.9 0 01-.088-1.171l.087-.1 5.963-5.965-5.964-5.964a.9.9 0 01-.086-1.171l.087-.1-.087.1z"
         ></path>
      </svg>
   );
}

export default IconDismiss;
