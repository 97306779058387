function WhatsApp(props) {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="24"
         height="24"
         fill="none"
         viewBox="0 0 24 24"
         {...props}
      >
         <path
            fill="#02181D"
            d="M19.05 4.91A9.82 9.82 0 0012.04 2a9.93 9.93 0 00-8.59 14.86L2.05 22l5.25-1.38A9.91 9.91 0 0019.05 4.91zm-7.01 15.24c-1.48 0-2.93-.4-4.2-1.15l-.3-.18-3.12.82.83-3.04-.2-.31a8.25 8.25 0 0112.8-10.2 8.18 8.18 0 012.41 5.83 8.22 8.22 0 01-8.22 8.23zm4.52-6.16c-.25-.12-1.47-.72-1.69-.81-.23-.08-.39-.12-.56.12-.17.25-.64.81-.78.97-.14.17-.29.19-.54.06-.25-.12-1.05-.39-1.99-1.23a7.57 7.57 0 01-1.38-1.72c-.14-.25-.02-.38.11-.51.11-.11.25-.29.37-.43s.17-.25.25-.41c.08-.17.04-.31-.02-.43l-.76-1.84c-.2-.48-.41-.42-.56-.43h-.48a.9.9 0 00-.66.31c-.22.25-.86.85-.86 2.07 0 1.22.89 2.4 1.01 2.56.12.17 1.75 2.67 4.23 3.74.59.26 1.05.41 1.41.52.59.19 1.13.16 1.56.1.48-.07 1.47-.6 1.67-1.18.21-.58.21-1.07.14-1.18-.07-.11-.22-.16-.47-.28z"
         ></path>
      </svg>
   );
}

export default WhatsApp;
