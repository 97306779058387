import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import checkAuth from "../../../helpers/checkAuth";
import api from "../../../helpers/fetch";
import logOut from "../../../helpers/logout";
import FormBuilder from "../../Components/FormBuilder";
import "./index.module.scss"; // Подключение стилей без создания переменной

function SettingsPage() {
   const [form, setForm] = useState([]);
   const [requestState, setRequestState] = useState(false);

   const params = useParams();

   const paramsArray = {
      "calculator": "calculator",
      "about-organization": "about",
      "payment-information": "payment_information",
      "banks-sbp": "banks_sbp"
   };

   const getSettings = async () => {
      const auth = checkAuth();

      if (!auth) logOut();

      const resp = await new api("manage/settings/" + paramsArray[params["slug"]], auth).send();

      if (resp.status === "success") {
         setForm(resp.data[0].data);
      }
   }

   useEffect(() => {
      getSettings();
   }, [params["slug"]]); // Следим за изменением параметра slug

   const onSubmit = async (formSubmit) => {
      const body = JSON.stringify({
         form: JSON.stringify(formSubmit)
      });

      const auth = checkAuth();
      
      if (!auth) logOut();
      
      setRequestState(true);

      try {
         const resp = await new api("manage/settings/" + paramsArray[params["slug"]], auth, {
            method: "PUT",
            body
         }).send();

         if (resp.status === "success") {
            setForm(formSubmit);
         } else {
            throw new Error('Failed to update settings');
         }
      } catch (error) {
         console.error(error);
         setForm(form); // Вернуть форму в предыдущее состояние в случае ошибки
      } finally {
         setRequestState(false); // Всегда сбрасываем состояние запроса
      }
   }

   return <FormBuilder form={form} onSubmit={onSubmit} disabled={requestState} />;
}

export default SettingsPage;
