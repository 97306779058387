import React from "react";

function IconBurger(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      fill="none"
      viewBox="0 0 64 64"
      {...props}
    >
      <path
        stroke="#2D9614"
        strokeLinecap="round"
        strokeWidth="3"
        d="M11 19.5h42M11 32h42M11 44.5h42"
      ></path>
    </svg>
  );
}

export default IconBurger;