import Button from "../Button";
import IconAttach from "../Icons/Attach";
import ToolTipWrapper, { createInputToolTip } from "../ToolTipWrapper";

import css from "./styles.module.scss";

const {
    contentButton
} = css;

const ContentButton = ({
    data,

    blockIndex = -1,
    editable = false,
    onChange = () => {}
}) => {

    const defaultText = data.text + "";

    return (
        <div className={contentButton}>
            <ToolTipWrapper
                tooltipContent={
                    createInputToolTip(
                        <IconAttach />,
                        "Ссылка",
                        "text",
                        data.href,
                        val => onChange(blockIndex, "href", val)
                    )
                }
                showOnHover={editable}
            >
                <Button
                    href={editable ? null : data.href}

                    contentEditable={editable} suppressContentEditableWarning={true}
                    value={data.text}
                    onChange={e => onChange(blockIndex, "text", e.target.textContent)}
                >{defaultText}</Button>
            </ToolTipWrapper>
        </div>
    );
}

export default ContentButton;