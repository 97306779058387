import { useState } from "react";
import { Link } from "react-router-dom";
import IconCharacterNumber from "../Icons/CharacterNumber";
import IconGreyScaleCard from "../Icons/GreyScaleCard";
import IconWalletCreditCard from "../Icons/IconWalletCreditCard";
import IconScan from "../Icons/Scan";

import scss from "./index.module.scss";

const { payments, payments__item, payments__container, payments__content, icon } = scss;

function PaymentList() {
   const [payment] = useState([
      {
         name: "По QR в приложении банка",
         time: "От 1-го до 3-х дней",
         link: "qr-code",
         isActive: true,
      },
      {
         name: "По реквизитам",
         time: "От 1-го до 3-х дней",
         link: "requisites",
         isActive: true,
      },
      {
         name: "По реквизитам банковской карты",
         time: "Мгновенное погашение",
         link: "bank-card",
         isActive: true,
      },
      {
         name: "Картой онлайн",
         time: "Скоро",
         link: "",
         isActive: false,
      },
   ]);

   const getIcon = (payment) => {
      switch (payment.name) {
         case "По QR в приложении банка": {
            return <IconScan className={icon} />;
         }
         case "По реквизитам": {
            return <IconCharacterNumber className={icon} />;
         }
         case "Картой онлайн": {
            return <IconGreyScaleCard className={icon} />;
         }
         case "По реквизитам банковской карты": {
            return <IconWalletCreditCard className={icon} />;
         }
         default:
            break;
      }
   };

   return (
      <div className={payments}>
         {payment.map((pay, key) =>
            pay.isActive ? (
               <Link
                  className={payments__item + " " + payments__container}
                  to={pay.link}
                  key={key}
               >
                  {getIcon(pay)}
                  <div className={payments__content}>
                     <h3>{pay.name}</h3>
                     <span>{pay.time}</span>
                  </div>
               </Link>
            ) : (
               <div
                  className={payments__item + " " + payments__container}
                  to={pay.link}
                  key={key}
               >
                  {getIcon(pay)}
                  <div className={payments__content}>
                     <h3>{pay.name}</h3>
                     <span>{pay.time}</span>
                  </div>
               </div>
            )
         )}
      </div>
   );
}

export default PaymentList;
