function Document(props) {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="24"
         height="24"
         fill="none"
         viewBox="0 0 24 24"
         {...props}
      >
         <path
            fill="#010101"
            d="M12 1.5v5.25A2.25 2.25 0 0014.25 9h5.25v11.25a2.25 2.25 0 01-2.25 2.25H6.75a2.25 2.25 0 01-2.25-2.25V3.75A2.25 2.25 0 016.75 1.5H12zm1.5.375V6.75a.75.75 0 00.75.75h4.875L13.5 1.875z"
         ></path>
      </svg>
   );
}

export default Document;
