import { useState } from "react";
import IconArrowRight from "../../manage/Components/Icons/IconArrowRight";
import classes from "./styles.module.scss";

const {
    tooltip,
    tooltip_hide,
    tooltip__wrapper,

    tooltipInput,
    tooltipInput__input,
    tooltipInput__button
} = classes;

const createInputToolTip = (
    icon = <></>,
    placeholder = "",
    type = "text",
    value = "",
    onSubmit = () => {}
) => {

    const ToolTip = () => {
        const [val, setVal] = useState(value);

        const onFormSubmit = (e) => {
            e.preventDefault();
            onSubmit(val);
        }

        return (
            <form action="/" onSubmit={onFormSubmit} className={tooltipInput}>
                {icon}

                <input 
                    className={tooltipInput__input}
                    type={type}
                    placeholder={placeholder}
                    title={placeholder}

                    value={val}
                    onChange={e => setVal(e.target.value)}
                />

                <button className={tooltipInput__button} type="submit" title="Применить">
                    <IconArrowRight />
                </button>
            </form>
        );
    };

    return <ToolTip />
}

const ToolTipWrapper = ({
    children = <></>,
    tooltipContent,
    showOnHover = false
}) => {

    return (
        <div className={tooltip + (!showOnHover ? " " + tooltip_hide : "")}>
            {children}

            {
                showOnHover ? (
                    <div className={tooltip__wrapper}>
                        {tooltipContent}
                    </div>
                ) : ""
            }
        </div>
    );
}

export { createInputToolTip };

export default ToolTipWrapper;