import React from "react";

export default function IconChart(props) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="130" height="130" fill="none" viewBox="0 0 130 130" {...props}>
         <g filter="url(#filter0_iii_917_4268)">
            <path
               fill="url(#paint0_linear_917_4268)"
               d="M106.247 8.316H23.753c-8.526 0-15.437 6.911-15.437 15.437v82.494c0 8.525 6.911 15.437 15.437 15.437h82.494c8.525 0 15.437-6.912 15.437-15.437V23.753c0-8.526-6.912-15.437-15.437-15.437z"
            ></path>
         </g>
         <path
            fill="url(#paint1_linear_917_4268)"
            d="M106.247 8.316H23.753c-8.526 0-15.437 6.911-15.437 15.437v82.494c0 8.525 6.911 15.437 15.437 15.437h82.494c8.525 0 15.437-6.912 15.437-15.437V23.753c0-8.526-6.912-15.437-15.437-15.437z"
         ></path>
         <path
            fill="url(#paint2_radial_917_4268)"
            d="M106.247 8.316H23.753c-8.526 0-15.437 6.911-15.437 15.437v82.494c0 8.525 6.911 15.437 15.437 15.437h82.494c8.525 0 15.437-6.912 15.437-15.437V23.753c0-8.526-6.912-15.437-15.437-15.437z"
         ></path>
         <path
            fill="url(#paint3_radial_917_4268)"
            d="M106.247 8.316H23.753c-8.526 0-15.437 6.911-15.437 15.437v82.494c0 8.525 6.911 15.437 15.437 15.437h82.494c8.525 0 15.437-6.912 15.437-15.437V23.753c0-8.526-6.912-15.437-15.437-15.437z"
         ></path>
         <path
            fill="url(#paint4_linear_917_4268)"
            fillRule="evenodd"
            d="M46.812 8.316H42.75V42.4H8.316v4.063H42.75v36.44H8.316v4.063H42.75v34.718h4.062V86.962h36.563v34.722h4.062V86.962h34.247v-4.063H87.437V46.468h34.247v-4.063H87.437V8.313h-4.062v34.084H46.812V8.312v.004zm36.563 38.147v36.44H46.812v-36.44h36.563z"
            clipRule="evenodd"
         ></path>
         <path
            fill="url(#paint5_linear_917_4268)"
            fillRule="evenodd"
            d="M46.812 8.316H42.75V42.4H8.316v4.063H42.75v36.44H8.316v4.063H42.75v34.718h4.062V86.962h36.563v34.722h4.062V86.962h34.247v-4.063H87.437V46.468h34.247v-4.063H87.437V8.313h-4.062v34.084H46.812V8.312v.004zm36.563 38.147v36.44H46.812v-36.44h36.563z"
            clipRule="evenodd"
         ></path>
         <path
            fill="url(#paint6_linear_917_4268)"
            fillRule="evenodd"
            d="M46.812 8.316H42.75V42.4H8.316v4.063H42.75v36.44H8.316v4.063H42.75v34.718h4.062V86.962h36.563v34.722h4.062V86.962h34.247v-4.063H87.437V46.468h34.247v-4.063H87.437V8.313h-4.062v34.084H46.812V8.312v.004zm36.563 38.147v36.44H46.812v-36.44h36.563z"
            clipRule="evenodd"
         ></path>
         <path
            fill="url(#paint7_linear_917_4268)"
            fillRule="evenodd"
            d="M46.812 8.316H42.75V42.4H8.316v4.063H42.75v36.44H8.316v4.063H42.75v34.718h4.062V86.962h36.563v34.722h4.062V86.962h34.247v-4.063H87.437V46.468h34.247v-4.063H87.437V8.313h-4.062v34.084H46.812V8.312v.004zm36.563 38.147v36.44H46.812v-36.44h36.563z"
            clipRule="evenodd"
         ></path>
         <g filter="url(#filter1_f_917_4268)">
            <path
               fill="#988FB6"
               d="M117.65 109.614c.406-1.328.626-2.738.626-4.2v-5.862L91.52 72.796a9.441 9.441 0 00-13.207-.142l-9.181 8.072a1.889 1.889 0 01-2.641-.028L11.538 25.797a14.42 14.42 0 00-.57 4.022v6.09l50.185 50.135a9.445 9.445 0 0013.203.138l9.181-8.072a1.89 1.89 0 012.64.028l31.477 31.476h-.004z"
            ></path>
            <path
               fill="url(#paint8_radial_917_4268)"
               d="M117.65 109.614c.406-1.328.626-2.738.626-4.2v-5.862L91.52 72.796a9.441 9.441 0 00-13.207-.142l-9.181 8.072a1.889 1.889 0 01-2.641-.028L11.538 25.797a14.42 14.42 0 00-.57 4.022v6.09l50.185 50.135a9.445 9.445 0 0013.203.138l9.181-8.072a1.89 1.89 0 012.64.028l31.477 31.476h-.004z"
            ></path>
         </g>
         <g filter="url(#filter2_i_917_4268)">
            <path
               fill="url(#paint9_linear_917_4268)"
               d="M121.014 110.764c.434-1.43.67-2.945.67-4.517v-6.305L92.909 71.167a10.156 10.156 0 00-14.206-.154l-7.829 7.5a2.032 2.032 0 01-2.84-.033L8.926 19.43a15.441 15.441 0 00-.61 4.323v6.548L62.29 84.228a10.156 10.156 0 0014.203.15l7.828-7.495a2.031 2.031 0 012.844.032l33.849 33.849z"
            ></path>
         </g>
         <path
            fill="url(#paint10_linear_917_4268)"
            d="M121.014 110.764c.434-1.43.67-2.945.67-4.517v-6.305L92.909 71.167a10.156 10.156 0 00-14.206-.154l-7.829 7.5a2.032 2.032 0 01-2.84-.033L8.926 19.43a15.441 15.441 0 00-.61 4.323v6.548L62.29 84.228a10.156 10.156 0 0014.203.15l7.828-7.495a2.031 2.031 0 012.844.032l33.849 33.849z"
         ></path>
         <g filter="url(#filter3_f_917_4268)">
            <path
               stroke="url(#paint11_linear_917_4268)"
               strokeWidth="2.031"
               d="M121.684 102.213L90.127 71.598a6.094 6.094 0 00-8.459-.029l-7.893 7.569a6.095 6.095 0 01-8.515-.082L10.656 24.688"
            ></path>
         </g>
         <defs>
            <filter
               id="filter0_iii_917_4268"
               width="123.524"
               height="121.493"
               x="4.253"
               y="4.253"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="-4.063" dy="4.063"></feOffset>
               <feGaussianBlur stdDeviation="4.063"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.878431 0 0 0 0 0.87451 0 0 0 0 0.886275 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_917_4268"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="6.094" dy="-4.063"></feOffset>
               <feGaussianBlur stdDeviation="6.094"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.698039 0 0 0 0 0.67451 0 0 0 0 0.721569 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="effect1_innerShadow_917_4268" result="effect2_innerShadow_917_4268"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dx="1.016"></feOffset>
               <feGaussianBlur stdDeviation="1.016"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.729412 0 0 0 0 0.729412 0 0 0 0 0.729412 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="effect2_innerShadow_917_4268" result="effect3_innerShadow_917_4268"></feBlend>
            </filter>
            <filter
               id="filter1_f_917_4268"
               width="115.432"
               height="91.942"
               x="6.906"
               y="21.734"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_917_4268" stdDeviation="2.031"></feGaussianBlur>
            </filter>
            <filter
               id="filter2_i_917_4268"
               width="113.368"
               height="93.364"
               x="8.316"
               y="17.4"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feColorMatrix
                  in="SourceAlpha"
                  result="hardAlpha"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               ></feColorMatrix>
               <feOffset dy="-2.031"></feOffset>
               <feGaussianBlur stdDeviation="4.063"></feGaussianBlur>
               <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
               <feColorMatrix values="0 0 0 0 0.215686 0 0 0 0 0.388235 0 0 0 0 0.733333 0 0 0 1 0"></feColorMatrix>
               <feBlend in2="shape" result="effect1_innerShadow_917_4268"></feBlend>
            </filter>
            <filter
               id="filter3_f_917_4268"
               width="117.327"
               height="83.848"
               x="7.502"
               y="21.531"
               colorInterpolationFilters="sRGB"
               filterUnits="userSpaceOnUse"
            >
               <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
               <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
               <feGaussianBlur result="effect1_foregroundBlur_917_4268" stdDeviation="1.219"></feGaussianBlur>
            </filter>
            <linearGradient
               id="paint0_linear_917_4268"
               x1="110.195"
               x2="48.242"
               y1="15.742"
               y2="94.709"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#D8D1E0"></stop>
               <stop offset="1" stopColor="#D5C9E3"></stop>
            </linearGradient>
            <linearGradient
               id="paint1_linear_917_4268"
               x1="87.242"
               x2="87.242"
               y1="122.752"
               y2="113.417"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#A88AC4"></stop>
               <stop offset="1" stopColor="#CDBCE0" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint2_radial_917_4268"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-22.70365 .84838 -3.30762 -88.51622 121.684 66.605)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#F2EDF5"></stop>
               <stop offset="1" stopColor="#DED3E7" stopOpacity="0"></stop>
            </radialGradient>
            <radialGradient
               id="paint3_radial_917_4268"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="matrix(-14.991 13.29373 -13.75996 -15.51675 120.193 11.732)"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#F7F4F7"></stop>
               <stop offset="1" stopColor="#E2DCE5" stopOpacity="0"></stop>
            </radialGradient>
            <linearGradient
               id="paint4_linear_917_4268"
               x1="65"
               x2="65"
               y1="17.709"
               y2="121.684"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#C2BEC6"></stop>
               <stop offset="1" stopColor="#BAACC6"></stop>
            </linearGradient>
            <linearGradient
               id="paint5_linear_917_4268"
               x1="88.233"
               x2="88.233"
               y1="124.589"
               y2="110.447"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#9E83B7"></stop>
               <stop offset="1" stopColor="#9E83B7" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint6_linear_917_4268"
               x1="4.379"
               x2="25.963"
               y1="70.521"
               y2="70.521"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#96909C"></stop>
               <stop offset="1" stopColor="#96909C" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint7_linear_917_4268"
               x1="124.987"
               x2="109.497"
               y1="73.316"
               y2="73.316"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#D6D1DA"></stop>
               <stop offset="1" stopColor="#D6D1DA" stopOpacity="0"></stop>
            </linearGradient>
            <radialGradient
               id="paint8_radial_917_4268"
               cx="0"
               cy="0"
               r="1"
               gradientTransform="rotate(108.072 14.297 70.762) scale(12.2627 15.2214)"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.494" stopColor="#6B5F94"></stop>
               <stop offset="1" stopColor="#6B5F94" stopOpacity="0"></stop>
            </radialGradient>
            <linearGradient
               id="paint9_linear_917_4268"
               x1="21.588"
               x2="113.433"
               y1="14.601"
               y2="94.136"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#53C2FF"></stop>
               <stop offset="1" stopColor="#3B9EDE"></stop>
            </linearGradient>
            <linearGradient
               id="paint10_linear_917_4268"
               x1="18.822"
               x2="10.737"
               y1="15.182"
               y2="30.473"
               gradientUnits="userSpaceOnUse"
            >
               <stop offset="0.42" stopColor="#4EC0FC"></stop>
               <stop offset="1" stopColor="#4EC0FC" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
               id="paint11_linear_917_4268"
               x1="15.807"
               x2="116.87"
               y1="22.23"
               y2="96.119"
               gradientUnits="userSpaceOnUse"
            >
               <stop stopColor="#55C1FF"></stop>
               <stop offset="1" stopColor="#549AE0"></stop>
            </linearGradient>
         </defs>
      </svg>
   );
}
