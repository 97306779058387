import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Confirm from "../../components/PaymentsComponents/Confirm";
import CrediCard from "../../components/PaymentsComponents/CreditCard";
import Information from "../../components/PaymentsComponents/Information";
import Instruction from "../../components/PaymentsComponents/Instruction";
import QR from "../../components/PaymentsComponents/qr";
import api from "../../helpers/fetch";
import scss from "./index.module.scss";

const { container, confirm, instruction, information, aside } = scss;

function PaymentPageByBankCard() {
   const params = useParams();
   const navigate = useNavigate();

   const [state] = useState({
      instruction: {
         title: "Как оплатить?*",
         list: [
            "Скопируйте номер карты Slon Finance",
            "Запустите приложение банка на Вашем телефоне",
            "Откройте раздел “Платежи” или “Переводы”",
            "Выберите “Перевести по номеру карты”",
            "Вставьте скопированный номер карты и укажите сумму платежа",
            "Проверьте заполненные данные",
            "Нажмите “Оплатить”",
         ],
         subtext:
            "*В приложении Вашего банка процесс оплаты переводом на банковскую карту может незначительно отличаться.",
      },
      confirm: {
         title: "Уже произвели платёж?",
         content: {
            text: "Помогите ускорить его обработку - подтвердите выполнение платежа.",
            buttonText: "Я оплатил(а)",
         },
         subtext:
            "Я подтверждаю, что произвёл(а) платёж и в случае необходимости смогу предоставить документальное подтверждение перевода из банка.",
      },
      information: {
         title: "О платеже",
      },
   });

   const [token] = useState(localStorage.getItem("token"));

   const [info, setInfo] = useState({
      // purpose: "",
      name: "",
      surName: "",
      midName: "",
      number: "",
      amount: "",
      card: "",
   });

   const [success, setSuccess] = useState("");

   const getInfo = async () => {
      if (!params.token) {
         if (token) {
            const response = await (
               await fetch(
                  global.api +
                     "payments?type=qr" +
                     (params?.payment ? "&" + params?.payment : ""),
                  {
                     method: "GET",
                     headers: {
                        authorization: token,
                     },
                  }
               )
            ).json();
            setRes(response);
         }
      } else {
         const request = new api(`payments?token=${params?.token}&type=qr`, false);
         const response = await request.send();
         setRes(response);
      }
   };

   const setRes = (response) => {
      setSuccess(response.status);
      switch (response.status) {
         case "success": {
            if (!response?.redirect) {
               setInfo({
                  // purpose: response?.data?.purpose,
                  name: response?.data?.name,
                  surName: response?.data?.surname,
                  midName: response?.data?.midname,
                  number: response?.data?.number,
                  amount: response?.data?.amount,
                  card: response?.data?.card,
               });
            } else {
               navigate(response?.redirect);
            }
            break;
         }

         default: {
            setInfo({
               purpose: "",
               name: "",
               surName: "",
               midName: "",
               number: "",
               amount: "",
               card: "",
            });
            break;
         }
      }
   };

   useEffect(() => {
      getInfo();
   }, []);

   return (
      <div className={container}>
         <div className={information}>
            <Information title={state.information.title} data={info} wait={success} />
         </div>
         <div className={confirm}>
            <Confirm
               params={params}
               title={state.confirm.title}
               subtext={state.confirm.subtext}
               content={state.confirm.content}
               wait={success}
               number={info.number}
               amount={info.amount}
            />
         </div>
         <div className={instruction}>
            <Instruction
               title={state.instruction.title}
               instructions={state.instruction.list}
               subtext={state.instruction.subtext}
            />
         </div>
         <div className={aside}>
            <CrediCard numberCard={info.card} />
         </div>
      </div>
   );
}

export default PaymentPageByBankCard;
