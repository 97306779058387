import scss from "./index.module.scss";

const { info, info_flex, info__item, info__item_small, info__title, info__subtitle } = scss;

function InfoCards({ data, small, grid }) {
   return (
      <ul className={grid ? info : info + " " + info_flex}>
         {data.map(({ title, subtitle, symbol }, key) => (
            <li className={!small ? info__item : info__item + " " + info__item_small} key={key}>
               <h3 className={info__title}>{title}</h3>
               <p className={info__subtitle}>{subtitle.toLocaleString("ru-RU") + " " + symbol}</p>
            </li>
         ))}
      </ul>
   );
}

export default InfoCards;
